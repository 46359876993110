import type ServerError from '@shared/services/api-service/server-error';
import { computed, type ComputedRef, ref, type Ref } from 'vue';
import { cloneDeep } from 'lodash';
import { uniqueKey } from '@shared/helpers';

export interface AsyncInfo<T> {
  call: (...args: Array<any>) => Promise<T | void>;
  results: Ref<T | null>;
  error: Ref<ServerError | null>;
  loading: ComputedRef<boolean>;
  remaining: ComputedRef<number>;
}

export type AsyncOptions<T> = Partial<{
  delay: number;
  errorsCleanTimeout: number;
  initState: Partial<{ error: ServerError | null; results: any | null }>;
  successCallback: (data: T) => Promise<unknown> | unknown;
  failedCallback: (error?: any) => Promise<unknown> | unknown;
  resultsRef: Ref<T>;
  throwError: boolean;
  resetOnCall: boolean;
}>;

const delayCall = <T>(asyncFunction: (...args: Array<any>) => Promise<T>, args: Array<any>, delay: number): Promise<T> => {
  return new Promise((resolve) => setTimeout(async () => resolve(await asyncFunction(...args)), delay));
};

export const useAsync = <T>(asyncFunction?: ((...args: Array<any>) => Promise<T>) | undefined, options?: AsyncOptions<T>): AsyncInfo<T | null> => {
  const { errorsCleanTimeout = 10000, delay, successCallback, failedCallback, resultsRef, throwError = false, resetOnCall = true } = options || {};

  const tasks = ref<Array<string>>([]);
  const results = ref<any | null>(options?.initState?.results || null);
  const error = ref<ServerError | null>(options?.initState?.error || null);
  const errorTimeout = ref<ReturnType<typeof setTimeout> | undefined>(undefined);

  const call = async (...args: Array<any>): Promise<T | void> => {
    const loadingKey = uniqueKey();

    tasks.value.push(loadingKey);
    error.value = null;

    if (resetOnCall) {
      results.value = null;
    }

    if (errorTimeout.value) {
      clearTimeout(errorTimeout.value);
    }

    try {
      if (asyncFunction) {
        results.value = await (delay ? delayCall(asyncFunction, args, delay) : asyncFunction(...args));
        await successCallback?.(results.value);

        if (resultsRef) {
          resultsRef.value = cloneDeep(results.value);
        }

        return results.value;
      }
    } catch (e: any) {
      failedCallback?.(e);
      results.value = null;
      error.value = e;
      errorTimeout.value = errorsCleanTimeout ? setTimeout(() => (error.value = null), errorsCleanTimeout) : undefined;

      if (throwError) {
        throw e;
      }
    } finally {
      tasks.value = tasks.value.filter((key) => key !== loadingKey);
    }
  };

  return {
    call,
    results,
    error,
    loading: computed(() => !!tasks.value.length),
    remaining: computed(() => tasks.value.length),
  };
};
