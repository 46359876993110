import type ServerError from '@shared/services/api-service/server-error';
import { computed, type ComputedRef, onUnmounted, ref } from 'vue';

const errorsState = ref<ServerError | null>(null);

export const useErrorsState = (error?: ServerError): ComputedRef<ServerError | null> => {
  if (error) {
    errorsState.value = error;
  }

  onUnmounted(() => (errorsState.value = null));

  return computed(() => errorsState.value);
};
