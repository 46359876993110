<template>
  <div :class="['tab-switcher', { 'tab-switcher--with-content': isContentExists, 'tab-switcher--flat': flat }]">
    <div
      v-if="!hideTabs"
      :class="[
        'tab-switcher-header',
        { 'tab-switcher-header--active': $slots['header'] || title, 'sticky-top': sticky, 'border-bottom-regular pb-2': divider && isTabsShown },
      ]"
    >
      <div v-if="title" class="text-title-3 text-center text-ellipsis">{{ $t(title) }}</div>

      <slot name="header" :index="vIndex" :tab="tabName" />

      <div v-if="isTabsShown && !isTabsHideOnSplash" flat class="tab-switcher-navigation">
        <div
          v-if="!hideBackButton"
          :class="['tab-switcher-navigation__back', { 'tab-switcher-navigation__back--disabled': !isPrevPageEnabled }]"
          @click="onPrevPage"
        >
          <vz-icon name="svg:arrow-left" size="1rem" :aria-label="$t('GENERAL.BACK')" :color="isPrevPageEnabled ? 'currentColor' : 'mono-400'" />
        </div>

        <div v-if="$slots['prepend']" class="tab-switcher-navigation__actions">
          <slot name="prepend" :index="vIndex" :tab="tabName" />
        </div>

        <div ref="tabsRef" :class="['tab-switcher-navigation__panel', { 'tab-switcher-navigation__panel--fit': fit, 'text-center': center }]">
          <div
            v-for="({ label, iconName, color }, renderTabIndex) in renderTabs"
            :key="renderTabIndex"
            :class="[
              'tab-switcher-navigation__panel-tab',
              {
                'tab-switcher-navigation__panel-tab--active': vIndex === renderTabIndex,
                'tab-switcher-navigation__panel-tab--mark': vIndex === renderTabIndex,
                'text-uppercase': uppercase,
              },
            ]"
            :style="{ minWidth: minTabWidth, color: `var(--color-${color})` }"
            @click="onSelect(renderTabIndex, true)"
          >
            <div class="d-flex align-center">
              <vz-icon v-if="iconName" :name="iconName" size="1.25rem" />

              <span class="fill-width text-ellipsis text-center">{{ label ? $t(label.toString()) : null }}</span>
            </div>
          </div>
        </div>

        <div v-if="$slots['append']" class="tab-switcher-navigation__actions">
          <slot name="append" :index="vIndex" />
        </div>

        <div
          v-if="!hideNextButton"
          :class="['tab-switcher-navigation__next', { 'tab-switcher-navigation__next--disabled': !isNextPageEnabled }]"
          @click="onNextPage"
        >
          <vz-icon name="svg:arrow-right" size="1rem" :aria-label="$t('GENERAL.NEXT')" :color="isNextPageEnabled ? 'currentColor' : 'mono-400'" />
        </div>
      </div>
    </div>

    <div v-if="isContentExists" ref="contentRef" :class="contentClass" :style="tabStyle">
      <slot v-if="$slots['splash']" name="splash" />

      <template v-else>
        <template v-if="$slots[tabName || vIndex + 1]">
          <slot :name="tabName || vIndex + 1" v-bind="$attrs" />
        </template>

        <slot v-else :index="vIndex" :tab="tabName" v-bind="$attrs" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SizeUnit } from '@shared/types';
import type { SwitchTab } from '@/shared/components/content-switcher/tab-switcher.type';
import { computed, nextTick, type PropType, ref, useSlots, watch } from 'vue';
import { routeTo } from '@shared/composables';
import { useFormValidator } from '@shared/components/fields/helpers';
import { isEqual } from 'lodash';

const props = defineProps({
  divider: { type: Boolean, default: false },
  alwaysShow: { type: Boolean, default: false },
  tabIndex: { type: Number as PropType<number>, default: 0 },
  center: { type: Boolean, default: false },
  showSeparator: { type: Boolean, default: false },
  hideSingleTab: { type: Boolean, default: false },
  fit: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  sticky: { type: Boolean, default: false },
  minTabWidth: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  title: { type: String as PropType<string | undefined>, default: undefined },
  hideTabs: { type: Boolean, default: false },
  uppercase: { type: Boolean, default: true },
  autofocus: { type: Boolean, default: true },
  tabs: { type: [Number, Array] as PropType<Array<string | number | SwitchTab> | number>, required: true },
  hideNextButton: { type: Boolean, default: false },
  hideBackButton: { type: Boolean, default: false },
  hideTabsOnSplash: { type: Boolean, default: false },
  enableEmptyState: { type: Boolean, default: true },
  hiddenIndexList: { type: Array as PropType<Array<number>>, default: () => [] },
  validationCallback: { type: Function as PropType<(() => void | boolean) | undefined>, default: undefined },
});

const emit = defineEmits(['update:tab-index']);

const slots = useSlots();

const tabsRef = ref<HTMLDivElement | undefined>(undefined);
const contentRef = ref<HTMLDivElement | undefined>(undefined);

const renderTabs = computed((): Array<SwitchTab> => {
  if (Array.isArray(props.tabs)) {
    return props.tabs
      .filter((value) => typeof value !== 'object' || !value.hidden)
      .map((value, index) =>
        typeof value === 'object'
          ? { ...value, name: value.name || (index + 1).toString() }
          : { label: value.toString(), name: (index + 1).toString() }
      );
  }

  return Array.from(Array(+props.tabs).keys()).map((num) => ({ label: (num + 1).toString(), name: (num + 1).toString() }));
});

const activeTabIndex = ref<number>(props.tabIndex);

const vIndex = computed({
  get: () => activeTabIndex.value,
  set: (value: number) => {
    activeTabIndex.value = value;
    emit('update:tab-index', value);

    setCenter();
  },
});

const validate = (isSilent?: boolean): boolean => {
  props.validationCallback?.();

  if (renderTabs.value?.[vIndex.value]?.validate) {
    const isValid = useFormValidator(contentRef, isSilent);

    return isValid();
  }

  return true;
};

const setCenter = () => {
  nextTick(() => {
    tabsRef.value?.querySelector('.tab-switcher-navigation__panel-tab--active')?.scrollIntoView();
  });
};

const isContentExists = computed(
  (): boolean => !!slots['content'] || !!slots['default'] || !!slots[`${tabName.value || vIndex.value + 1}`] || !!slots['splash']
);

const contentClass = computed(() => {
  return ['tab-switcher-content', `tab-switcher-content-${tabName.value}`, { 'tab-switcher-content--splash': slots['splash'] }];
});

const isTabsShown = computed((): boolean => {
  if (props.alwaysShow) {
    return true;
  }

  if (props.hideSingleTab && renderTabs.value.length <= 1) {
    return false;
  }

  return !!renderTabs.value.length && !props.hiddenIndexList.includes(vIndex.value);
});

const isTabsHideOnSplash = computed((): boolean => !!slots['splash'] && props.hideTabsOnSplash);

const tabName = computed(() => renderTabs.value?.[vIndex.value]?.name);
const tabStyle = computed(() => renderTabs.value?.[vIndex.value]?.style);
const totalTabs = computed((): number => renderTabs.value.length);

const isPrevPageEnabled = computed((): boolean => vIndex.value > 0 || !props.autofocus);

const onPrevPage = (): void => {
  if (props.autofocus) {
    onSelect(Math.max(0, vIndex.value - 1));
  }
};

const isNextPageEnabled = computed((): boolean => totalTabs.value - 1 > vIndex.value);

const onNextPage = (): void => {
  onSelect(Math.min(totalTabs.value - 1, vIndex.value + 1));
};

const onSelect = (index: number, isClicked: boolean = false): void => {
  if (!validate() || index < 0) {
    return;
  }

  if (isClicked) {
    renderTabs.value[index]?.onClick?.(index);

    if (renderTabs.value[index].route) {
      routeTo(renderTabs.value[index].route!);
    }
  }

  vIndex.value = index;
};

watch(
  () => renderTabs.value,
  (newValue, oldValue) => {
    nextTick(() => {
      const findNewIndex = newValue.findIndex((tab) => isEqual(tab, oldValue?.[vIndex.value]));

      if (findNewIndex !== -1) {
        onSelect(findNewIndex);
        contentRef.value?.removeAttribute('validate');
      }
    });
  },
  { deep: true }
);

watch(
  () => props.tabIndex,
  (value) => {
    if (value !== undefined && value !== vIndex.value) {
      onSelect(props.tabIndex);
    }
  },
  { immediate: true }
);

defineExpose({ validate, next: onNextPage, previous: onPrevPage, select: onSelect, index: vIndex, name: tabName });
</script>

<style lang="scss" scoped>
$tab-navigation-bar: 2rem;

.tab-switcher {
  display: flex;
  flex-direction: column;

  &--with-content {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
  }

  &-header {
    &--active {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 0.125rem;
    }
  }

  &-navigation {
    display: flex;
    max-height: fit-content;

    &:first-child:not(:has(.tab-switcher-navigation__panel-tab--active)) {
      border-bottom: 1px solid var(--color-primary-300);
    }

    @include min-sm-layout {
      padding: 0.5rem;
    }

    @include sm-layout {
      padding: 0.25rem;
    }

    &__back,
    &__next {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;

      @include rtl(transform, scale(-1));

      &:not(&--disabled) {
        cursor: pointer;
      }
    }

    &__actions {
      display: flex;
      flex-wrap: nowrap;
    }

    &__panel {
      text-wrap: nowrap;
      flex-grow: 1;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &:not(.tab-switcher--flat &) {
        display: flex;
        gap: 2px;

        > * {
          max-width: fit-content !important;
        }
      }

      &--fit {
        display: flex;
        align-items: center;

        > * {
          flex: 1 1 0;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &-tab {
        position: relative;
        cursor: pointer;
        user-select: none;
        display: inline-block;
        align-items: center;
        margin: 0 0.125rem;
        padding: 0 0.5rem;
        overflow: hidden;
        flex: 1 1 0;

        &:not(.tab-switcher--flat &) {
          @include before {
            opacity: 0.05;
            background-color: currentColor;
            box-sizing: content-box;
            transition: 0.3s;
            border-radius: var(--border-radius-regular);
          }
        }

        * {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre;
          max-width: 100%;
        }

        @include after {
          top: initial;
          background-color: currentColor;
          border-radius: 2px;
          opacity: 0;
          height: 2px;
          width: 0;
          left: 50%;
          transform: translateX(-50%);
          transition:
            height 0.3s,
            width 0.3s,
            opacity 0.3s,
            transform 0.3s,
            left 0.3s;
        }

        &--mark {
          cursor: initial;
          font-weight: var(--font-weight-semibold);
        }

        &--active {
          &:before {
            opacity: 0.125;
          }

          @include after {
            top: initial;
            background-color: currentColor;
            border-radius: 2px;
            width: 100%;
            left: 0;
            transform: translateX(0);

            &:not(.tab-switcher--flat &) {
              opacity: 0.05;
              height: 2px;
            }

            .tab-switcher--flat & {
              opacity: 1;
              height: 3px;
            }
          }
        }

        &:hover:not(&--active) {
          &:before {
            opacity: 0.075;
          }
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        position: relative;

        > * {
          height: 100%;
          margin: 0 0.125rem;
        }
      }
    }
  }

  &-content {
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    &--overflow-hidden {
      overflow-y: hidden !important;
    }

    &--splash {
      > *:not(:first-child) {
        display: none;
      }
    }
  }
}
</style>
