export const stringToColor = (text: string) => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff; // Allow full range (0-255)
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
};

export const stringToHueColor = (text: string, hue: number, saturation: number = 70, lightness: number = 50) => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Keeps variation while staying within range
  const adjustedLightness = ((hash % 30) + lightness) % 100;

  return `hsl(${hue}, ${saturation}%, ${adjustedLightness}%)`;
};

export const stringToBlueColor = (text: string) => stringToHueColor(text, 220); // Hue 220 blue

export const stringToGreenColor = (text: string) => stringToHueColor(text, 140); // Hue 140 green

export const stringToRedColor = (text: string) => stringToHueColor(text, 0); // Hue 0 red

export const stringToYellowColor = (text: string) => stringToHueColor(text, 50); // Hue 50 yellow
