export const openMaps = ([lat, lng]: [number, number], provider?: 'google' | 'apple' | 'waze' | 'osm') => {
  const appleMapsURL = `https://maps.apple.com/?q=${lat},${lng}`;
  const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  const wazeURL = `https://www.waze.com/ul?ll=${lat},${lng}&navigate=yes`;
  const osmURL = `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lng}&zoom=15`;

  let selectedURL: string;

  switch (provider) {
    case 'apple':
      selectedURL = appleMapsURL;
      break;
    case 'waze':
      selectedURL = wazeURL;
      break;
    case 'osm':
      selectedURL = osmURL;
      break;
    default: {
      const isApple = /iPhone|iPad|iPod/.test(navigator.userAgent);

      selectedURL = isApple ? appleMapsURL : googleMapsURL;

      break;
    }
  }

  window.open(selectedURL, '_blank');
};
