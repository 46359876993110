import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-uppercase"
}

import type { SizeUnit } from '@shared/types';
import { computed, type PropType } from 'vue';
import type { ColorsMap } from '@shared/services/css-service/types';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-card-badge',
  props: {
  top: { type: String as PropType<SizeUnit>, default: '1.5rem' },
  label: { type: String as PropType<string | undefined>, default: undefined },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
},
  setup(__props) {

const props = __props;

const getColor = computed((): string | undefined => (props.color ? getThemeColor(props.color) : 'currentColor'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "card-badge",
    style: _normalizeStyle({ color: getColor.value, top: `calc(${__props.top} + 1px)` })
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}
}

})