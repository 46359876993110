<template>
  <promotion v-if="isPromotionScreen" />

  <div v-else-if="!isMobile" class="lobby fill-height d-flex flex-column">
    <div class="d-flex align-center justify-space-between pa-4 flex-grow-1 border-bottom-regular bg-primary-100">
      <div class="d-flex gap-2">
        <vz-avatar :hid="activeUser?._id" :first-name="activeUser?.firstName" :last-name="activeUser?.lastName" :size="64" />

        <div>
          <div class="text-title-1">{{ $t('GENERAL.WELCOME') }}</div>

          <div class="text-title-2">
            <span>{{ activeUser?.firstName }} {{ activeUser?.lastName }}</span>
            <span v-if="activeUser?.nickName" class="ms-1">({{ activeUser?.nickName }})</span>
          </div>

          <div class="text-subtitle-1">{{ activeUser?.company }}</div>
        </div>
      </div>

      <div class="lobby__now font-weight-600">
        {{ dayjs().format(GlobalVariables.FULL_DATE_AND_TIME) }}
      </div>
    </div>

    <div class="overflow-y-auto">
      <div>
        <lobby-finance-charts />
      </div>

      <div class="d-flex flex-column">
        <div class="row-flex-4">
          <div class="d-flex flex-column fill-height vh-65">
            <p class="text-title-1">{{ $t('MODULE.UPCOMING_EVENTS') }}</p>

            <vz-card class="flex-grow-1 overflow-hidden">
              <upcoming-events class="fill-height" disabled :callback="getUpcomingEvents" />
            </vz-card>
          </div>

          <div class="d-flex flex-column fill-height vh-65">
            <p class="text-title-1">{{ $t('MODULE.CALENDAR') }}</p>

            <vz-card class="flex-grow-1">
              <calendar-lobby />
            </vz-card>
          </div>

          <div class="d-flex flex-column fill-height vh-65">
            <p class="text-title-1">{{ $t('MODULE.NOTIFICATIONS') }}</p>

            <vz-card class="flex-grow-1 overflow-hidden">
              <notifications class="fill-height" disabled :callback="getNotifications" />
            </vz-card>
          </div>

          <div v-if="authCheck(Modules.FREELANCE.JOB_OFFER)" class="d-flex flex-column fill-height col-flex-12">
            <p class="text-title-1">{{ $t('MODULE.JOB_OFFER') }}</p>

            <vz-card>
              <job-offer minimal />
            </vz-card>
          </div>

          <div v-if="authCheck(Modules.MANAGER.JOB_MANAGER_POST)" class="d-flex flex-column fill-height col-flex-12">
            <p class="text-title-1">{{ $t('MODULE.JOB_MANAGER_POST') }}</p>

            <vz-card>
              <post-manager minimal />
            </vz-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <upcoming-events v-else class="fill-height" :callback="getUpcomingEvents" />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import UpcomingEvents from '@/views/calendar/views/upcoming-events.view.vue';
import { authCheck } from '@/store/auth/helpers';
import Modules from '@/router/constants/modules';
import { useLobbyStore } from '@/views/lobby/store';
import { GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import dayjs from 'dayjs';
import { GlobalVariables } from '@shared/constants';
import { isMobile } from '@shared/helpers';
import LobbyFinanceCharts from '@/views/lobby/components/lobby-finance-charts.vue';

const Promotion = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/promotion/promotion.view.vue'));
const Notifications = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/notifications/components/notifications-tabs.vue'));
const CalendarLobby = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/calendar-lobby.vue'));
const JobOffer = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/job/views/offer/components/job-offer-search.vue'));
const PostManager = defineAsyncComponent(
  () => import(/* webpackChunkName: "lobby" */ '@/views/job/views/manager/modules/post/post-manager.view.vue')
);

const { activeUser } = useAuthUser();
const now = ref<string>(dayjs().format(GlobalVariables.FULL_DATE_AND_TIME));

const { [GET_UPCOMING_EVENTS]: getUpcomingEvents, [GET_NOTIFICATIONS]: getNotifications } = useLobbyStore();

const { isLoggedIn } = useAuthUser();
const isPromotionScreen = computed(() => !isLoggedIn.value);

onMounted(() => {
  setInterval(() => {
    now.value = dayjs().format(GlobalVariables.FULL_DATE_AND_TIME);
  }, 1000);
});
</script>

<style scoped lang="scss">
.lobby {
}
</style>
