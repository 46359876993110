import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';
import backofficeLogRoutes from '@/views/backoffice/system-log';
import backofficeUsersRoutes from '@/views/backoffice/user-management';
import Backoffice from '@/views/backoffice/backoffice.view.vue';

const backofficeRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.BACKOFFICE_ADMIN],
  name: Modules.BACKOFFICE_ADMIN,
  component: Backoffice,
  redirect: { path: Modules.PATHS[Modules.BACKOFFICE_LOG] },
  children: [backofficeLogRoutes, backofficeUsersRoutes],
};

export default backofficeRoutes;
