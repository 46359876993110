import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-errors"]

import type { ErrorResponse, ErrorResponseMessage } from '@shared/services/api-service/models';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { useValidator } from '@shared/components/fields/helpers';
import { computed, type PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-error-message',
  props: {
  name: { type: String as PropType<string | undefined>, required: true },
  value: { type: [String, Number, Boolean, Object, Array] as PropType<any>, required: true },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  errors: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const { validateMessage: internalError, isTouched } = useValidator(
  computed(() => props.value),
  computed(() => props.rules),
  props.name
);

const externalErrors = computed((): Array<string> | null => {
  if (!props.errors) {
    return null;
  }

  if (typeof props.errors === 'string') {
    return [props.errors];
  }

  const { errorMessage } = props.errors as ErrorResponse;

  if (!errorMessage) {
    return null;
  }

  return errorMessage.map((error: ErrorResponseMessage) => error.message);
});

const errorMessage = computed(() => {
  if (externalErrors.value?.length) {
    return externalErrors.value[0];
  }

  return internalError.value;
});

const hasErrors = computed(() => !!errorMessage.value);

__expose({ errorMessage, hasErrors });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "vz-error-message mt-2",
    role: "alert",
    "data-errors": _unref(internalError)
  }, [
    _renderSlot(_ctx.$slots, "default", {
      errorMessage: errorMessage.value,
      isTouched: _unref(isTouched)
    }, () => [
      (errorMessage.value)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-error-message--internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(errorMessage.value)), 3))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}
}

})