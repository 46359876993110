import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled", "aria-label"]
const _hoisted_2 = { class: "d-flex align-center justify-center" }

import type { IconName, IconType } from '@/shared/components/icon/icon.type';
import type { SizeUnit } from '@shared/types';
import type { ColorsMap } from '@/shared/services/css-service/types/colors';
import { computed, type PropType } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-button',
  props: {
  tooltip: { type: String, default: '' },
  hideTextOnMobile: { type: Boolean, default: false },
  callToAction: { type: Boolean, default: false },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: 'currentColor' },
  backgroundColor: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  type: { type: String as PropType<'flat' | 'rounded' | 'solid'>, default: 'solid' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  minimizable: { type: Boolean, default: false },
  ariaLabel: { type: String, default: '' },
  text: { type: String, default: '' },
  iconColor: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  iconName: { type: String as PropType<IconName | undefined>, default: undefined },
  iconSize: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  iconType: { type: String as PropType<IconType>, default: 'solid' },
  uppercase: { type: Boolean, default: true },
  width: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  showTooltip: { type: Boolean, default: true },
},
  emits: ['click'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const t = useTranslator();

const tooltip = computed(() => {
  return !props.disabled && props.showTooltip && !props.text ? t(props.tooltip || props.ariaLabel) : null;
});

const style = computed(() => ({
  ...(props.backgroundColor ? { backgroundColor: getThemeColor(props.backgroundColor) } : {}),
  ...(props.color ? { color: getThemeColor(props.color) } : {}),
  ...(props.width ? { minWidth: props.width } : {}),
}));

const onClick = (ev: Event): void => {
  if (!props.disabled && !props.loading) {
    emit('click', ev);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_vz_spinner = _resolveComponent("vz-spinner")!
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
      'vz-button',
      `vz-button--${__props.type}`,
      { [`vz-button--${__props.type}--background`]: __props.backgroundColor, 'vz-button--action': __props.callToAction, skeleton: __props.loading },
    ]),
    role: "button",
    style: _normalizeStyle(style.value),
    disabled: __props.disabled || __props.loading,
    "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: __props.ariaLabel || __props.text || '' }),
    onClick: onClick
  }, [
    _createElementVNode("div", _hoisted_2, [
      (__props.loading)
        ? (_openBlock(), _createBlock(_component_vz_spinner, {
            key: 0,
            class: "vz-button__loader",
            "aria-label": __props.ariaLabel || __props.text
          }, null, 8, ["aria-label"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['vz-button__content', { 'vz-button__content--uppercase': __props.uppercase, 'vz-button__content--loading': __props.loading }])
      }, [
        (__props.iconName)
          ? (_openBlock(), _createBlock(_component_vz_icon, {
              key: 0,
              clickable: !__props.disabled,
              name: __props.iconName,
              color: __props.iconColor,
              size: __props.iconSize,
              type: __props.iconType
            }, null, 8, ["clickable", "name", "color", "size", "type"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (__props.text)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([
              'vz-button__content-text text-ellipsis',
              { 'vz-button__content-text--minimizable': __props.minimizable, 'hide-on-mobile': __props.hideTextOnMobile },
            ])
              }, _toDisplayString(_ctx.$t(__props.text)), 3))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ])
  ], 14, _hoisted_1)), [
    [_directive_tooltip, tooltip.value]
  ])
}
}

})