<template>
  <vz-modal non-resizable hide-actions fit-content :open-event="openEvent" :close-event="closeEvent" @open="onOpenModal">
    <div class="d-flex flex-column align-center justify-center text-center mb-6">
      <p v-if="title" class="text-title-2 mt-8 mb-4 px-8">{{ $t(title) }}</p>
      <p class="text-body-1 px-8 text-pre-wrap">{{ $t(description) }}</p>

      <vz-icon v-if="modal.icon" class="mt-4" :size="modal.icon.size || '5rem'" :name="modal.icon.name" :color="modal.icon.color" />
    </div>

    <div class="d-flex align-center justify-center gap-4 flex-ch-1 px-4 mb-4">
      <vz-button
        v-for="({ label, request, color }, index) in modal.actions || []"
        :key="index"
        v-focus="!index"
        :color="color"
        :text="label"
        :disabled="request?.loading"
        :loading="request?.loading"
        @click="request?.call()"
      />
    </div>
  </vz-modal>
</template>

<script setup lang="ts">
import type { VzPopupConfig } from '@shared/components/popup/popup.types';
import { computed, type PropType, ref } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import { CLOSE_POPUP, OPEN_POPUP } from '@shared/components/popup/popup.constants';

defineProps({
  openEvent: { type: String as PropType<string>, default: OPEN_POPUP },
  closeEvent: { type: String as PropType<string>, default: CLOSE_POPUP },
});

const t = useTranslator();

const modal = ref<VzPopupConfig>({} as VzPopupConfig);

const title = computed(() => {
  if (Array.isArray(modal.value.title)) {
    const [key, params] = modal.value.title;

    return t(key, params || {});
  }

  return modal.value.title;
});

const description = computed(() => {
  if (Array.isArray(modal.value.description)) {
    const [key, params] = modal.value.description;

    return t(key, params || {});
  }

  return modal.value.description;
});

const onOpenModal = (arg: VzPopupConfig) => (modal.value = (arg || {}) as any);
</script>
