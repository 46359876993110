import type { RouteRecordNormalized } from 'vue-router';
import type { RouteItem } from '@/router/models/route-item';
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type ComputedRef, ref, type Ref } from 'vue';
import { AUTH_MODULES, IS_LOGGED_IN } from '@/store/auth/auth.constants';
import { findAllChildrenRoutes, getChildrenRouteNames } from '@/router/helpers';
import { kebabCaseToScreamingSnakeCase } from '@/shared/helpers';
import router from '@/router';
import { useAuthStore } from '@/store/auth';
import { storeToRefs } from 'pinia';

export const useMenuItems = (
  options: Partial<{ routeName: ComputedRef<string>; items: Ref<Array<RouteItem | string>>; authModules?: ComputedRef<Array<string> | null> }> = {}
): ComputedRef<Array<RouteItem>> => {
  const authStore = useAuthStore();
  const { [AUTH_MODULES]: authModulesGetter, [IS_LOGGED_IN]: isLoggedIn } = storeToRefs(authStore);

  const buildMenuItems = (
    routes: Array<RouteRecordNormalized>,
    items: Array<RouteItem | string>,
    visited: Set<string> = new Set()
  ): Array<RouteItem> => {
    return items
      .map((item) => {
        const routeName = typeof item === 'object' ? item.name : item;
        if (visited.has(routeName)) return null;
        visited.add(routeName);

        const route = routes.find(({ name }) => name === routeName);
        const childrenRoutes = route ? findAllChildrenRoutes(routeName) : [];

        const menuItem: RouteItem =
          typeof item === 'object'
            ? {
                label: (route?.meta?.title as string) || `MODULE.${kebabCaseToScreamingSnakeCase(item.name)}`,
                icon: route?.meta?.icon as IconName,
                ...item,
                subitems: buildMenuItems(
                  childrenRoutes,
                  childrenRoutes.map((child) => child.name as string),
                  new Set(visited)
                ),
              }
            : {
                name: item,
                label: (route?.meta?.title as string) || `MODULE.${kebabCaseToScreamingSnakeCase(item)}`,
                icon: route?.meta?.icon as IconName,
                subitems: buildMenuItems(
                  childrenRoutes,
                  childrenRoutes.map((child) => child.name as string),
                  new Set(visited)
                ),
              };

        return menuItem;
      })
      .filter((item): item is RouteItem => {
        if (!item) return false;

        const { name } = item;
        if (!name || typeof name !== 'string') {
          return false;
        }

        const routeMeta = routes.find((r) => r.name === name)?.meta;
        if (routeMeta?.isSignInRequired && !isLoggedIn.value) {
          return false;
        }

        if (routeMeta?.isUnprotected) {
          return true;
        }

        const children = routes.find((r) => r.name === name)?.children?.map((c) => c.name as string) || [];

        return (
          (options.authModules?.value || authModulesGetter.value)?.includes(name) ||
          children.some((child) => (options.authModules?.value || authModulesGetter.value)?.includes(child))
        );
      });
  };

  return computed((): Array<RouteItem> => {
    const { routeName, items = ref(getChildrenRouteNames(routeName?.value)) } = options;
    const routeChildren = routeName?.value ? findAllChildrenRoutes(routeName.value) : router.getRoutes();

    return buildMenuItems(routeChildren, items.value);
  });
};
