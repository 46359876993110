<template>
  <vz-error-alert v-if="errors" :errors="errors" />

  <form ref="formRef" class="vz-form" role="form" autocomplete="off" v-bind="$attrs" @submit.prevent>
    <div :class="fieldClass">
      <slot name="default" :validate="validate" />
    </div>
  </form>

  <div v-if="$slots['actions'] || actions?.length" class="vz-form__controls">
    <div class="flex-grow-1">
      <slot name="extra-actions" />
    </div>

    <slot v-if="!hideActions" name="actions">
      <vz-button
        v-for="({ callback, text, ...rest }, index) in actions"
        :key="index"
        :tabindex="0"
        :disabled="loading || disabled"
        :loading="isLoading[text]"
        v-bind="{ text, ...rest }"
        :background-color="text.toLowerCase().includes('next') || text.toLowerCase().includes('submit') ? 'var(--color-primary-900)' : ''"
        :color="text.toLowerCase().includes('next') || text.toLowerCase().includes('submit') ? 'var(--color-background-regular)' : ''"
        @click="onAction({ callback, text, ...rest })"
      />
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { FormActionButton } from '@shared/components/fields/vz-form/models';
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { Class } from '@shared/types';
import { computed, type PropType, ref } from 'vue';
import { useFormValidator } from '@shared/components/fields/helpers';

const props = defineProps({
  hideActions: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  actions: { type: Array as PropType<Array<FormActionButton>>, default: () => [] },
  class: { type: [Object, Array, String] as PropType<Class>, default: () => [] },
});

const emit = defineEmits(['submit']);
const fieldClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));

const formRef = ref<Element | undefined>(undefined);
const isLoading = ref<Record<string, boolean>>({});
const errors = ref<ErrorResponse | null>(null);

const validate = (isSilent?: boolean): boolean => {
  const isValid = useFormValidator(formRef, isSilent);

  return isValid();
};

const onAction = async (action: FormActionButton): Promise<void> => {
  if (action.validate && !validate()) {
    return;
  }

  isLoading.value[action.text] = true;

  try {
    await action.callback?.();

    emit('submit');
  } catch (e: any) {
    errors.value = e;
  } finally {
    isLoading.value[action.text] = false;
  }
};

defineExpose({ validate });
</script>

<style lang="scss">
.vz-form {
  &__controls {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0.5rem 1rem 1rem 1rem;
    margin: 0 0.25rem;
    z-index: 1;

    > * {
      min-width: 6rem;
      margin-inline-start: 0.5rem;
    }
  }
}
</style>
