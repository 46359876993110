import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { IconName, IconType } from '@shared/components/icon/icon.type';
import type { ColorsMap } from '@shared/services/css-service/types';
import type { SizeUnit } from '@shared/types';
import { type PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-tooltip',
  props: {
  tooltip: { type: String, required: true },
  icon: { type: String as PropType<IconName>, default: 'svg:info' },
  size: { type: String as PropType<SizeUnit>, default: '1.25rem' },
  type: { type: [String, Array] as PropType<IconType | [IconType, IconType]>, default: 'solid' },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: 'mono-500' },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_vz_icon, {
    name: __props.icon,
    size: __props.size,
    type: __props.type,
    color: __props.color
  }, null, 8, ["name", "size", "type", "color"])), [
    [_directive_tooltip, _ctx.$t(__props.tooltip)]
  ])
}
}

})