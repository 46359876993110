import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "vz-collapse__actions d-flex gap-1"
}

import type { Class, SizeUnit } from '@shared/types';
import { computed, nextTick, onMounted, onUnmounted, onUpdated, type PropType, ref, watch } from 'vue';
import { useFormValidator } from '@shared/components/fields/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-collapse',
  props: {
  active: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  value: { type: Boolean, default: false },
  hideIcon: { type: Boolean, default: false },
  hideHeaderOnExpand: { type: Boolean, default: false },
  actionOnExpandOnly: { type: Boolean, default: false },
  class: { type: [Object, Array, String] as PropType<Class>, default: () => [] },
  y: { type: String as PropType<SizeUnit>, default: '0.75rem' },
  x: { type: String as PropType<SizeUnit>, default: '0.75rem' },
},
  emits: ['update:value', 'click:header'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const contentClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));

const isInitial = ref(true);
const vCollapsed = ref(!props.value && !props.disabled);
const containerRef = ref<Element | undefined>(undefined);
const dynamicRef = ref<Element | undefined>(undefined);
const collapsedRef = ref<Element | undefined>(undefined);
const contentHeight = ref(0);
const collapsedHeight = ref(0);
const isCollapsed = computed(() => vCollapsed.value && !props.disabled);

const validate = (isSilent: boolean = true) => useFormValidator(dynamicRef, isSilent)();

const onCollapse = async (ev?: Event): Promise<void> => {
  ev?.preventDefault();

  const isValid = validate(false);

  if (!isValid && !isCollapsed.value) {
    return;
  }

  vCollapsed.value = !vCollapsed.value;
  emit('update:value', vCollapsed.value);
};

const onUpdate = () => {
  if (!containerRef.value || !dynamicRef.value) {
    return;
  }

  collapsedHeight.value = isCollapsed.value ? collapsedRef.value?.scrollHeight || 0 : 0;
  contentHeight.value = isCollapsed.value ? 0 : dynamicRef.value.scrollHeight;
};

onUpdated(onUpdate);

onMounted(() => {
  if (!validate()) {
    vCollapsed.value = false;
  }

  nextTick(() => {
    isInitial.value = false;
  });

  if (dynamicRef.value) {
    const observer = new MutationObserver(() => {
      for (const time of [100, 200, 300, 400, 500]) {
        setTimeout(onUpdate, time);
      }
    });

    observer.observe(dynamicRef.value, { childList: true, subtree: true });

    onUnmounted(() => {
      observer.disconnect();
    });
  }
});

watch(() => isCollapsed.value, onUpdate, { immediate: true });

watch(
  () => props.value,
  (value) => {
    if (value === vCollapsed.value) {
      onCollapse();
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (!__props.active)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        ref_key: "containerRef",
        ref: containerRef,
        class: _normalizeClass(['vz-collapse', { 'vz-collapse--initial': isInitial.value, 'vz-collapse--collapsed': isCollapsed.value }]),
        style: _normalizeStyle({ '--y-position': __props.y, '--x-position': __props.x })
      }, [
        (!__props.hideIcon && _ctx.$slots['default'])
          ? (_openBlock(), _createBlock(_component_vz_button, {
              key: 0,
              class: "vz-collapse__activator-button",
              type: "flat",
              "icon-size": "1rem",
              color: __props.disabled ? 'mono-400' : 'primary-900',
              "icon-name": isCollapsed.value ? 'svg:arrow-down' : 'svg:arrow-up',
              onClick: _withModifiers(onCollapse, ["stop"])
            }, null, 8, ["color", "icon-name"]))
          : _createCommentVNode("", true),
        (isCollapsed.value || !__props.hideHeaderOnExpand)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "vz-collapse__header",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:header')))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass({ 'd-flex flex-wrap': _ctx.$slots['actions'] })
              }, [
                (_ctx.$slots['collapse-header'] && isCollapsed.value)
                  ? _renderSlot(_ctx.$slots, "collapse-header", {
                      key: 0,
                      isCollapsed: isCollapsed.value,
                      toggle: onCollapse,
                      disabled: !__props.active || __props.disabled
                    })
                  : _renderSlot(_ctx.$slots, "header", {
                      key: 1,
                      isCollapsed: isCollapsed.value,
                      toggle: onCollapse,
                      disabled: !__props.active || __props.disabled
                    }),
                (_ctx.$slots['actions'] && (!__props.actionOnExpandOnly || !isCollapsed.value))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _renderSlot(_ctx.$slots, "actions")
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots['default'])
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              ref_key: "dynamicRef",
              ref: dynamicRef,
              class: _normalizeClass(['vz-collapse__container', ...contentClass.value]),
              style: _normalizeStyle({ height: `${contentHeight.value}px` })
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 6))
          : _createCommentVNode("", true)
      ], 6))
}
}

})