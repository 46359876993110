<template>
  <template v-if="!readonly">
    <file-drop class="vz-attachment" :folder="folderDrillDownDeepLevel" :max-files="maxFiles" @update="onFiles">
      <slot>
        <div class="vz-attachment__container">
          <div
            v-for="(file, index) in files"
            :key="index"
            :class="['vz-attachment__item', 'vz-attachment__item--removable']"
            :style="itemContainerSize"
          >
            <vz-icon :name="`svg:${file.type}` as IconName" fallback="svg:file" type="regular" size="4rem" />
            <p class="vz-subtitle-3 text-ellipsis">{{ file.name }}</p>

            <vz-icon name="svg:trash" type="regular" size="1.125rem" @click="onRemove" />
          </div>

          <div v-if="!hideUploadButton && (value || []).length < maxFiles" class="vz-attachment__item" :style="itemContainerSize">
            <vz-icon name="svg:plus" type="regular" size="1.25rem" @click="onInput" />
          </div>
        </div>

        <p v-if="!hideNote" :class="{ 'c-red-600': markWarning }">{{ t('WARNING.UPLOAD', { type: typeLabel, size: maxFileSize / 1024 / 1024 }) }}</p>
      </slot>
    </file-drop>
  </template>

  <div v-else :class="['vz-attachment', { 'vz-attachment--readonly': readonly }]">
    <div v-for="(file, index) in value" :key="index" :class="['vz-attachment__preview']" :style="itemContainerSize">
      <vz-icon :name="`svg:${file.type}` as IconName" fallback="svg:file" type="regular" size="3rem" @click="() => onPreview(file)" />

      <p class="vz-subtitle-3 text-ellipsis">{{ file.name }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@shared/components/icon/icon.type';
import type { BaseFile } from '@shared/types';
import type { GetAttachmentRes } from '@/store/client/models';
import type { FileDropEvent } from '@shared/elements/file-drop';
import { computed, type PropType, ref, watch } from 'vue';
import FileService from '@shared/services/file.service';
import { useAsync } from '@shared/composables';
import { useTranslator } from '@/plugins/i18n/helpers';

const props = defineProps({
  path: { type: String, default: '' },
  hideNote: { type: Boolean, default: false },
  value: { type: Array as PropType<Array<File | BaseFile> | undefined>, required: true },
  provider: { type: Function as PropType<({ id }: { id: string }) => Promise<GetAttachmentRes>>, default: () => {} },
  readonly: { type: Boolean, default: false },
  autoUpdate: { type: Boolean, default: false },
  maxFiles: { type: Number, default: 0 },
  maxFileSize: { type: Number, default: 0 },
  acceptType: { type: [String, Array] as PropType<string | Array<string>>, default: '' },
  hideUploadButton: { type: Boolean, default: false },
  folderDrillDownDeepLevel: { type: [Number, String] as PropType<`${string}` | number>, default: 0 },
});

const emit = defineEmits(['update:value']);
const t = useTranslator();

const getAttachmentRequest = useAsync<GetAttachmentRes>(props.provider as ({ id }: { id: string }) => Promise<GetAttachmentRes>);

const files = ref<Array<File>>([]);
const markWarning = ref<boolean>(false);

const typeLabel = computed(() => {
  return (Array.isArray(props.acceptType) ? props.acceptType : props.acceptType!.split(','))
    .map((type) => type.split('/')[1])
    .join(', ')
    .toUpperCase();
});

const itemContainerSize = computed(() => {
  if (props.readonly) {
    if (!props.value?.length) {
      return;
    }

    const count = Math.min(props.value.length, 3);

    return { width: `calc(${100 / count}% - 0.5rem)` };
  }

  if (!files.value?.length) {
    return { width: `100%` };
  }

  const count = Math.min(files.value.length + (!props.hideUploadButton && (!props.maxFiles || files.value.length < props.maxFiles) ? 1 : 0), 3);

  return { width: `calc(${100 / count}% - 0.5rem)` };
});

const insertFiles = (input: Array<File> | FileList) => {
  const type = (Array.isArray(props.acceptType) ? props.acceptType : props.acceptType?.split(','))?.filter((type) => !!type);

  if (
    Array.from(input).some((file) => {
      const isValidSize = !props.maxFileSize || file.size <= props.maxFileSize;
      const isValidType = !type?.length || type.includes(file.type);

      return isValidSize && isValidType;
    })
  ) {
    markWarning.value = true;

    setTimeout(() => {
      markWarning.value = false;
    }, 3000);
  }

  const insert = Array.from(input).filter((file) => {
    const isValidSize = !props.maxFileSize || file.size <= props.maxFileSize;
    const isValidType = !type?.length || type.includes(file.type);

    return isValidSize && isValidType;
  });

  const payload = (props.maxFiles ? [...(files.value || []), ...insert].slice(0, props.maxFiles) : [...(files.value || []), ...insert]).map(
    (file) => {
      const fileName = [props.path, file.name].join('/').replace('//', '/');

      return new File([file], fileName, { type: file.type });
    }
  );

  emit('update:value', payload);
};

const onFiles = ({ detail }: CustomEvent<FileDropEvent>): void => {
  insertFiles(detail.files);
};

const onRemove = (index: number) => {
  const payload = [...(files.value || [])];
  payload.splice(index, 1);

  emit('update:value', payload);
};

const onInput = async (): Promise<void> => {
  const files = await FileService.uploadFile({
    multiple: true,
    max: props.maxFiles ? props.maxFiles - (props.value || []).length : undefined,
    accept: Array.isArray(props.acceptType) ? props.acceptType : props.acceptType?.split(','),
    // TODO: Temporary disabled folder select on input
    // folder: !!props.folderDrillDownDeepLevel,
  });

  if (files) {
    insertFiles(files);
  }
};

const onPreview = async (file: File | BaseFile): Promise<void> => {
  if (!(file instanceof File)) {
    const asyncFile = await getAttachmentRequest.call(file.attachmentId);

    if (!asyncFile) {
      return;
    }

    if (navigator.share) {
      navigator.share({ files: [FileService.base64ToFile(asyncFile.data, asyncFile)], title: asyncFile.name });
    } else {
      FileService.downloadLink(asyncFile.data, asyncFile.name);
    }
  }
};

watch(
  () => [props.value, props.readonly],
  async () => {
    if (props.readonly) {
      return;
    }

    const res = await Promise.all(
      (props.value || []).map(async (file): Promise<File | null> => {
        if (file instanceof File) {
          return file;
        }

        const asyncFile = await getAttachmentRequest.call(file.attachmentId);

        return asyncFile ? FileService.base64ToFile(asyncFile.data, asyncFile) : null;
      })
    );

    files.value = res.filter((file): file is File => file !== null);
  },
  { immediate: true, deep: true }
);

defineExpose({ insert: onInput });
</script>

<style scoped lang="scss">
.vz-attachment {
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  font-size: var(--font-size-16);

  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 4px;
    justify-content: space-around;
  }

  &--readonly {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 4px;
    justify-content: space-around;
  }

  &:not(&--readonly) {
    border: 1px dashed var(--color-primary-300);
  }

  &:not(&--readonly) [drag-over] {
    border: 1px dashed var(--color-primary-900);
  }

  &__preview {
    padding: 0.25rem;
    max-width: 5rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: var(--border-regular);
    border-radius: var(--border-radius-light);

    > p {
      direction: ltr;
      padding: 0 0.25rem;
      width: 100%;
    }
  }

  &__item {
    position: relative;
    margin: 0.25rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: var(--border-regular);
    border-radius: var(--border-radius-light);

    &:not(&--preview) {
      width: calc(100% / 3 - 0.5rem);
      max-width: 6rem;
    }

    &--removable {
      > :last-child {
        position: absolute;
        top: 0.125rem;
        @include inline-start(0.125rem);
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        @include inline-start(0);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 2.5rem 2.5rem 0;
        border-color: #ffd700 transparent #ffd700 transparent;
        transform: scaleY(-1);
        @include rtl(transform, scale(-1));
      }
    }
  }
}
</style>
