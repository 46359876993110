<template>
  <div v-if="value || $slots['default'] || !hideEmpty" :class="['d-flex gap-1', { 'flex-column': !flat }]">
    <div v-if="label || $slots['label']" class="c-mono-600">
      <slot name="label">
        <span v-if="label">{{ $t(label) }}</span>
      </slot>
    </div>

    <div class="d-flex gap-1">
      <vz-icon v-if="iconName" :name="iconName" :size="iconSize" />

      <div v-if="!value && !$slots['default']">-</div>

      <a
        v-else-if="link && value"
        v-tooltip="!hideTooltip"
        :class="['text-body-1 text-auto text-ellipsis clickable-link', { ltr }]"
        :href="link"
        @click.stop
      >
        <span v-if="value">{{ valueDisplay }}</span>

        <slot />
      </a>

      <div v-else :class="['text-body-1 d-flex gap-1 flex-wrap text-auto fill-width', { 'text-ellipsis': textEllipsis, ltr }]">
        <span
          v-if="value && !refer"
          v-tooltip="textEllipsis && !hideTooltip"
          :class="{ 'text-ellipsis': textEllipsis, 'text-pre': textPre, ltr: isNumber }"
        >
          {{ $t(valueDisplay) }}
        </span>

        <vz-button
          v-else-if="value && refer"
          class="text-ellipsis pa-0 font-weight-400"
          type="flat"
          :text="valueDisplay"
          @click.stop="routeTo(refer)"
        />

        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RouteLocation } from 'vue-router';
import type { BasePhone } from '@shared/models';
import type { IconName } from '@shared/components/icon/icon.type';
import type { SizeUnit } from '@shared/types';
import { computed, type PropType } from 'vue';
import { routeTo, useCurrencySymbol } from '@shared/composables';
import { numberWithCommas } from '@shared/helpers';
import { phonePrettier } from '@shared/helpers/phone-prettier';

const props = defineProps({
  hideEmpty: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  ltr: { type: Boolean as PropType<boolean | undefined>, default: undefined },
  textEllipsis: { type: Boolean, default: false },
  textPre: { type: Boolean, default: false },
  hideTooltip: { type: Boolean, default: false },
  value: {
    type: [String, Array, Object] as PropType<BasePhone | string | number | boolean | Array<string | number | boolean> | null | undefined>,
    default: undefined,
  },
  iconName: { type: String as PropType<IconName>, default: undefined },
  iconSize: { type: String as PropType<SizeUnit>, default: '1.25rem' },
  label: { type: String as PropType<string | undefined>, default: undefined },
  type: { type: String as PropType<'phone' | 'email' | 'currency'>, default: undefined },
  refer: { type: Object as PropType<Partial<Pick<RouteLocation, 'name' | 'path' | 'params' | 'query'>> | undefined>, default: undefined },
});

const isNumber = computed(() => typeof props.value === 'number');

const link = computed(() => {
  if (!props.value) {
    return null;
  }

  switch (props.type) {
    case 'phone':
      return `tel:${props.value}`;
    case 'email':
      return `mailto:${props.value}`;
    default:
      return null;
  }
});

const valueDisplay = computed((): string => {
  if (props.value === undefined || props.value === null) {
    return '-';
  }

  const valueToString = (value: string | number | boolean | BasePhone) => {
    switch (props.type) {
      case 'currency':
        return useCurrencySymbol(computed(() => value as number)).value;
      case 'phone':
        return phonePrettier(props.value as BasePhone);
    }

    switch (typeof value) {
      case 'boolean':
        return value ? 'GENERAL.YES' : 'GENERAL.NO';
      case 'number':
        return numberWithCommas(value);
      default:
        return value.toString();
    }
  };

  return Array.isArray(props.value) ? props.value.map(valueToString).join(', ') : valueToString(props.value);
});

const ltr = computed((): boolean => {
  if (props.ltr !== undefined) {
    return props.ltr;
  }

  return props.type === 'phone' || props.type === 'email';
});
</script>
