import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row-flex-4" }
const _hoisted_2 = { class: "col-flex-6" }
const _hoisted_3 = { class: "text-title-1 text-center" }
const _hoisted_4 = {
  key: 1,
  class: "flex-grow-1 d-flex justify-center align-center"
}
const _hoisted_5 = { class: "col-flex-3" }
const _hoisted_6 = { class: "text-title-1 text-center" }
const _hoisted_7 = {
  key: 1,
  class: "flex-grow-1 d-flex justify-center align-center"
}
const _hoisted_8 = { class: "col-flex-3" }
const _hoisted_9 = { class: "text-title-1 text-center" }
const _hoisted_10 = {
  key: 1,
  class: "flex-grow-1 d-flex justify-center align-center"
}

import type { Transaction } from '@/views/job/types';
import { computed } from 'vue';
import BaseChart from '@shared/components/charts/base-chart.vue';
import { useLobbyStore } from '@/views/lobby/store';
import { GET_MY_FINANCE_SUMMARY } from '@/views/job/views/manager/modules/finance/store/job-finance.constants';
import { TransactionEnum } from '@/constants';
import { useTranslator } from '@/plugins/i18n/helpers';
import { stringToBlueColor } from '@shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'lobby-finance-charts',
  setup(__props) {

const t = useTranslator();

const { [GET_MY_FINANCE_SUMMARY]: getMyFinanceSummary } = useLobbyStore();
const data = computed(() => getMyFinanceSummary.results?.data || []);
const loading = computed(() => getMyFinanceSummary.loading);

const incomeGrowthChart = computed(() => {
  let cumulativeIncome = 0;
  const dateMap: Record<string, number> = {};

  const incomeTransactions = data.value
    .filter((item) => item.type === TransactionEnum.INCOME)
    .reduce((acc, item) => acc.concat(item.transactions), [] as Array<Transaction>);

  const sortedTransactions = [...incomeTransactions].sort((a, b) => a.paidAt - b.paidAt);

  for (const transaction of sortedTransactions) {
    cumulativeIncome += transaction.amount;
    const dateStr = new Date(transaction.paidAt).toISOString().split('T')[0];

    dateMap[dateStr] = cumulativeIncome;
  }

  return {
    labels: Object.keys(dateMap),
    datasets: [
      {
        data: Object.values(dateMap),
        borderColor: stringToBlueColor(t('FINANCE.INCOME_GROWTH_OVER_TIME')),
        fill: true,
        tension: 0.3,
      },
    ],
  };
});

const incomeByCategoryChart = computed(() => {
  const categoryMap: Record<string, { value: number; callback?: () => void }> = {};

  for (const item of data.value) {
    if (item.type === TransactionEnum.INCOME) {
      categoryMap[item.category] = {
        value: (categoryMap[item.category]?.value || 0) + item.amount,
        // TODO: Implement redirect to finance page filtered by income and category
        // callback: () => console.log(item),
      };
    }
  }

  const labels = Object.keys(categoryMap).map((key) => t(`FINANCE.CATEGORY.${key}`));
  const backgroundColor = labels.map(stringToBlueColor);

  return {
    labels,
    datasets: [
      {
        data: Object.values(categoryMap).map(({ value }) => value),
        callback: Object.values(categoryMap).map(({ callback }) => callback),
        backgroundColor,
      },
    ],
  };
});

const expenseByCategoryChart = computed(() => {
  const categoryMap: Record<string, { value: number; callback?: () => void }> = {};

  for (const item of data.value) {
    if (item.type === TransactionEnum.EXPENSE) {
      categoryMap[item.category] = {
        value: (categoryMap[item.category]?.value || 0) + item.amount,
        // TODO: Implement redirect to finance page filtered by expense and category
        // callback: () => console.log(item.category),
      };
    }
  }

  const labels = Object.keys(categoryMap).map((key) => t(`FINANCE.CATEGORY.${key}`));
  const backgroundColor = labels.map(stringToBlueColor);

  return {
    labels,
    datasets: [
      {
        data: Object.values(categoryMap).map(({ value }) => value),
        callback: Object.values(categoryMap).map(({ callback }) => callback),
        backgroundColor,
      },
    ],
  };
});

return (_ctx: any,_cache: any) => {
  const _component_vz_spinner = _resolveComponent("vz-spinner")!
  const _component_vz_card = _resolveComponent("vz-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vz_card, { class: "fill-height d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('FINANCE.INCOME_GROWTH_OVER_TIME')), 1),
          (!loading.value)
            ? (_openBlock(), _createBlock(BaseChart, {
                key: 0,
                class: "flex-grow-1",
                "hide-legend": "",
                "chart-id": "incomeGrowthChart",
                type: "line",
                "chart-data": incomeGrowthChart.value
              }, null, 8, ["chart-data"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_vz_spinner, { size: "96px" })
              ]))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_vz_card, { class: "fill-height d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('FINANCE.INCOME_BY_CATEGORY')), 1),
          (!loading.value)
            ? (_openBlock(), _createBlock(BaseChart, {
                key: 0,
                "chart-id": "incomeCategoryChart",
                type: "pie",
                "chart-data": incomeByCategoryChart.value
              }, null, 8, ["chart-data"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_vz_spinner, { size: "96px" })
              ]))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_vz_card, { class: "fill-height d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('FINANCE.EXPENSE_BY_CATEGORY')), 1),
          (!loading.value)
            ? (_openBlock(), _createBlock(BaseChart, {
                key: 0,
                "chart-id": "expenseCategoryChart",
                type: "pie",
                "chart-data": expenseByCategoryChart.value
              }, null, 8, ["chart-data"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_vz_spinner, { size: "96px" })
              ]))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})