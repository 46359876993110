import type { RouteRecordNormalized } from 'vue-router';
import router from '@/router';

/**
 * Recursively finds all nested children of a route, avoiding circular references.
 */
export const findAllChildrenRoutes = (
  routeName?: string,
  routes: Array<RouteRecordNormalized> = router.getRoutes()
): Array<RouteRecordNormalized> => {
  const findNestedChildren = (routes: Array<RouteRecordNormalized>, visited = new Set<string>()): Array<RouteRecordNormalized> => {
    return routes.map((route) => {
      const routeId = route.name as string;

      // Prevent circular recursion
      if (visited.has(routeId)) {
        return route;
      }

      visited.add(routeId);

      const children = route.children as RouteRecordNormalized[];
      if (children && children.length > 0) {
        return {
          ...route,
          children: findNestedChildren(children, visited),
        };
      }

      return route;
    });
  };

  if (!routeName) {
    return findNestedChildren(routes);
  }

  const route = routes.find((r: RouteRecordNormalized) => r.name === routeName);

  return route ? findNestedChildren((route.children as RouteRecordNormalized[]) || []) : [];
};

/**
 * Builds a tree of child route names from a given route, safely.
 */
export const getChildrenRouteNames = (
  routeName?: string,
  routes: Array<RouteRecordNormalized> = router.getRoutes()
): Array<{ name: string; subitems: Array<any> }> => {
  const buildNestedStructure = (routes: Array<RouteRecordNormalized>): Array<{ name: string; subitems: Array<any> }> => {
    return routes.map((route) => {
      const subitems = route.children && route.children.length > 0 ? buildNestedStructure(route.children as RouteRecordNormalized[]) : [];

      return {
        name: route.name as string,
        subitems,
      };
    });
  };

  const allRoutes = findAllChildrenRoutes(routeName, routes);
  return buildNestedStructure(allRoutes);
};
