import type { GetCalendarEventsReq, GetCalendarEventsRes, GetUpcomingEventsReq, GetUpcomingEventsRes } from '@/views/calendar/types';
import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import CalendarService from '@/views/calendar/service/calendar.service';
import { GET_CALENDAR_EVENTS, GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { getAssignedUserId } from '@/store/auth/helpers';

const calendarService = container.resolve(CalendarService);

export const useCalendarStore = defineStore(StoreNamespace.CALENDAR_MODULE, () => {
  const getUpcomingEvents = async (payload?: GetUpcomingEventsReq): Promise<GetUpcomingEventsRes> => {
    const userId = getAssignedUserId();

    return await calendarService[GET_UPCOMING_EVENTS]({ ...payload, userId });
  };

  const getCalendarEvents = async (payload?: GetCalendarEventsReq): Promise<GetCalendarEventsRes> => {
    const userId = getAssignedUserId();
    const { year = new Date().getFullYear(), month = new Date().getMonth() + 1 } = payload || {};
    const getCalendarEventsPayload = { year: +year, month: +month };

    return await calendarService[GET_CALENDAR_EVENTS]({ ...getCalendarEventsPayload, userId });
  };

  return {
    [GET_UPCOMING_EVENTS]: getUpcomingEvents,
    [GET_CALENDAR_EVENTS]: getCalendarEvents,
  };
});
