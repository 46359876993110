import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = { class: "text-ellipsis mb-1" }
const _hoisted_3 = { class: "vz-bubble-select__container" }
const _hoisted_4 = { key: 1 }

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { BaseOption, BaseOptions } from '@/shared/models';
import type { ItemFunctionArg } from '@/shared/components/fields/vz-select/models';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { SizeUnit } from '@shared/types';
import { computed, type PropType } from 'vue';
import { useValidator } from '@/shared/components/fields/helpers';
import { useGetItemText, useGetItemValue } from '@/shared/components/fields/vz-select/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-bubble-select',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: {
    type: [String, Array] as PropType<BaseOptions<string> | Array<string> | BaseOption<string> | string | null | undefined>,
    required: true,
  },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  items: { type: Array as PropType<Array<any> | undefined | null>, default: undefined },
  itemText: { type: [Function, String] as PropType<((item: ItemFunctionArg) => any) | string>, default: 'title' },
  itemValue: { type: [Function, String] as PropType<((item: ItemFunctionArg) => any) | string>, default: 'value' },
  nullable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  fontSize: { type: String as PropType<SizeUnit>, default: '1rem' },
},
  emits: ['update:model-value', 'search'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const t = useTranslator();

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const getTitle = useGetItemText(props.itemText);
const getValue = useGetItemValue(props.itemValue);

const vModal = computed({
  get: (): Array<any> => (!props.modelValue ? [] : Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]),
  set: (value) => emit('update:model-value', value),
});

const externalError = computed(() => {
  if (!props.errorMessage || typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const onClick = (item: Record<string, any>): void => {
  const selection = vModal.value.includes(getValue(item))
    ? vModal.value.filter((currentItem) => currentItem !== getValue(item))
    : [...vModal.value, getValue(item)];

  emit('update:model-value', selection?.length || !props.nullable ? selection : null);
};

const isItemSelected = (item: Record<string, any>): boolean => (props.modelValue as Array<any>)?.includes(getValue(item));

return (_ctx: any,_cache: any) => {
  const _component_vz_badge = _resolveComponent("vz-badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vz-bubble-select", { 'vz-bubble-select--loading': __props.loading, 'vz-bubble-select--disabled': __props.disabled }]),
    "data-errors": _unref(validateMessage)
  }, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items || [], (item, index) => {
        return _renderSlot(_ctx.$slots, "badge", {
          key: index,
          item: item,
          selected: isItemSelected(item)
        }, () => [
          _createVNode(_component_vz_badge, {
            class: "px-2 py-1",
            clickable: "",
            outlined: isItemSelected(item),
            "font-size": __props.fontSize,
            color: isItemSelected(item) ? 'primary-900' : 'mono-600',
            text: _unref(getTitle)(item),
            onClick: ($event: any) => (onClick(item))
          }, null, 8, ["outlined", "font-size", "color", "text", "onClick"])
        ])
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(['vz-bubble-select__error', { 'vz-bubble-select__error--hidden': __props.hideDetails }]),
      role: "alert"
    }, [
      (_unref(validateMessage))
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-bubble-select__error-internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
        : (externalError.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t(externalError.value)), 1))
          : _createCommentVNode("", true)
    ], 2), [
      [_vShow, _unref(validateMessage) || externalError.value]
    ])
  ], 10, _hoisted_1))
}
}

})