<template>
  <vz-select
    v-if="modules?.length || (hideOnInactive && !vAssignee)"
    v-model="vAssignee"
    :class="['manager-assignee-select', { 'manager-assignee-select--active': vAssignee }]"
    hide-details
    placeholder="AUTH.SELECT_MANAGE_ACCOUNT"
    :items="modules"
    :disabled="isDisabled"
  >
    <template #item="{ item }">
      <employee-strip class="pa-0" avatar-size="24" :item="item" :hid="item?.userId" />
    </template>

    <template #selection="{ item }">
      <employee-strip class="pa-0" avatar-size="24" :item="item" :hid="item?.userId" />
    </template>
  </vz-select>
</template>

<script setup lang="ts">
import type { UserAssignee } from '@/views/job/types/job-manager-assignee.type';
import { ASSIGNED, ASSIGNEE_LIST, SET_ASSIGNEE } from '@/store/auth/auth.constants';
import { computed, ref, watch } from 'vue';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';
import { useRoute } from 'vue-router';
import { cloneDeep } from 'lodash';
import { routeTo } from '@shared/composables';
import { useAuthStore } from '@/store/auth';
import { storeToRefs } from 'pinia';

defineProps({
  hideOnInactive: { type: Boolean, default: false },
});

const authStore = useAuthStore();
const { [SET_ASSIGNEE]: setAssignee } = authStore;
const { [ASSIGNED]: assigned, [ASSIGNEE_LIST]: getAssigneeList } = storeToRefs(authStore);

const route = useRoute();

const moduleKey = computed(() => route.name as string);

const saveAssignee = ref<UserAssignee | undefined>(undefined);

const vAssignee = computed({
  get: (): UserAssignee => assigned.value as UserAssignee,
  set: (value: UserAssignee) => {
    if (!value) {
      saveAssignee.value = undefined;
    }

    setAssignee(value);
  },
});

const modules = computed((): Array<UserAssignee> => getAssigneeList.value as Array<UserAssignee>);
const isDisabled = computed(() => (getAssigneeList.value as Array<UserAssignee>)?.every(({ modules }) => !modules[moduleKey.value]?.length));

watch(
  () => route.name,
  () => {
    if (vAssignee.value && !vAssignee.value.modules[moduleKey.value]?.length) {
      saveAssignee.value = cloneDeep(vAssignee.value);

      setAssignee();
    } else if (saveAssignee.value && saveAssignee.value.modules[moduleKey.value]?.length) {
      setAssignee(saveAssignee.value);
    }
  },
  { immediate: true }
);

watch(
  () => vAssignee.value?.userId,
  (newValue, oldValue) => {
    if (route.name && newValue !== oldValue) {
      routeTo({ name: route.name }, { isOverride: true });
    }
  },
  { immediate: true }
);
</script>

<style lang="scss">
.manager-assignee-select {
  margin: 0.5rem;

  @include max-sm-layout {
    margin: 0.25rem;
  }
}
</style>
