<template>
  <vz-overlay ref="sheetRef" fit-content :size="VzOverlayEnum.SMALL" :title="!sheetType ? 'GENERAL.SHARE' : `SHARE.${sheetType}`" @close="onClose">
    <template #activator="{ open }">
      <vz-icon name="svg:plus" size="1.25rem" @click="open" />
    </template>

    <div v-if="!sheetType" class="d-flex flex-column gap-4 pa-8">
      <div class="d-flex gap-4 align-center clickable" @click="sheetType = ShareType.LOCATION">
        <vz-icon clickable name="svg:location" size="1.5rem" />

        <p class="text-title-1">{{ $t('SHARE.LOCATION') }}</p>
      </div>

      <div class="d-flex gap-4 align-center clickable" @click="sheetType = ShareType.ATTACHMENT">
        <vz-icon clickable name="svg:attachment" type="regular" size="1.5rem" />

        <p class="text-title-1">{{ $t('SHARE.ATTACHMENT') }}</p>
      </div>
    </div>

    <message-share-location
      v-if="sheetType === ShareType.LOCATION"
      :coordinates="payload?.coordinates"
      :readonly="false"
      @cancel="sheetType = null"
      @update:coordinates="onUpdate"
    />

    <message-share-attachment v-if="sheetType === ShareType.ATTACHMENT" @update:attachments="onUpdate" @cancel="sheetType = null" />
  </vz-overlay>
</template>

<script setup lang="ts">
import type { OverlayRef } from '@shared/components';
import type { SendMessagePayload } from '@shared/components/messenger/messenger.type';
import { type PropType, ref } from 'vue';
import MessageShareLocation from '@shared/components/messenger/components/message-share-sheet/message-share-location.vue';
import MessageShareAttachment from '@shared/components/messenger/components/message-share-sheet/message-share-attachment.vue';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';

enum ShareType {
  LOCATION = 'LOCATION',
  ATTACHMENT = 'ATTACHMENT',
}

const props = defineProps({
  payload: { type: Object as PropType<SendMessagePayload | undefined>, required: true },
});

const emit = defineEmits(['update:payload']);

const sheetRef = ref<OverlayRef>(undefined);
const sheetType = ref<ShareType | null>(null);

const onClose = () => setTimeout(() => (sheetType.value = null), 500);

const onUpdate = (update: Record<string, any>): void => {
  emit('update:payload', { ...props.payload, ...update });

  sheetRef.value?.close();
};
</script>
