<template>
  <div
    class="vz-bubble-select"
    :class="{ 'vz-bubble-select--loading': loading, 'vz-bubble-select--disabled': disabled }"
    :data-errors="validateMessage"
  >
    <label class="text-ellipsis mb-1">{{ $t(label) }}</label>

    <div class="vz-bubble-select__container">
      <slot v-for="(item, index) in items || []" :key="index" name="badge" :item="item" :selected="isItemSelected(item)">
        <vz-badge
          class="px-2 py-1"
          clickable
          :outlined="isItemSelected(item)"
          :font-size="fontSize"
          :color="isItemSelected(item) ? 'primary-900' : 'mono-600'"
          :text="getTitle(item)"
          @click="onClick(item)"
        />
      </slot>
    </div>

    <div
      v-show="validateMessage || externalError"
      :class="['vz-bubble-select__error', { 'vz-bubble-select__error--hidden': hideDetails }]"
      role="alert"
    >
      <p v-if="validateMessage" :class="{ 'vz-bubble-select__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError">{{ $t(externalError) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { BaseOption, BaseOptions } from '@/shared/models';
import type { ItemFunctionArg } from '@/shared/components/fields/vz-select/models';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { SizeUnit } from '@shared/types';
import { computed, type PropType } from 'vue';
import { useValidator } from '@/shared/components/fields/helpers';
import { useGetItemText, useGetItemValue } from '@/shared/components/fields/vz-select/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';

const props = defineProps({
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: {
    type: [String, Array] as PropType<BaseOptions<string> | Array<string> | BaseOption<string> | string | null | undefined>,
    required: true,
  },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  items: { type: Array as PropType<Array<any> | undefined | null>, default: undefined },
  itemText: { type: [Function, String] as PropType<((item: ItemFunctionArg) => any) | string>, default: 'title' },
  itemValue: { type: [Function, String] as PropType<((item: ItemFunctionArg) => any) | string>, default: 'value' },
  nullable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  fontSize: { type: String as PropType<SizeUnit>, default: '1rem' },
});
const emit = defineEmits(['update:model-value', 'search']);

const t = useTranslator();

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const getTitle = useGetItemText(props.itemText);
const getValue = useGetItemValue(props.itemValue);

const vModal = computed({
  get: (): Array<any> => (!props.modelValue ? [] : Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]),
  set: (value) => emit('update:model-value', value),
});

const externalError = computed(() => {
  if (!props.errorMessage || typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const onClick = (item: Record<string, any>): void => {
  const selection = vModal.value.includes(getValue(item))
    ? vModal.value.filter((currentItem) => currentItem !== getValue(item))
    : [...vModal.value, getValue(item)];

  emit('update:model-value', selection?.length || !props.nullable ? selection : null);
};

const isItemSelected = (item: Record<string, any>): boolean => (props.modelValue as Array<any>)?.includes(getValue(item));
</script>

<style lang="scss">
.vz-bubble-select {
  position: relative;
  display: flex;
  flex-direction: column;

  &--loading {
    .vz-bubble-select__container {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0.125rem;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-image: linear-gradient(100deg, var(--color-primary-300) 2%, var(--color-primary-900) 44%, var(--color-primary-300) 98%);
        background-repeat: no-repeat;
        background-size: 35% 100%;
        background-position: 0 0;
        animation: skeletonOverlay 2s linear infinite;
      }

      @keyframes skeletonOverlay {
        0% {
          background-position: -100% 0;
        }
        100% {
          background-position: 200% 0;
        }
      }
    }
  }

  &--disabled {
    .vz-bubble-select__container {
      color: var(--color-disabled);
      background-color: var(--color-background-disabled);
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>
