import type { BaseResponse } from '@shared/models';
import type {
  GetUserRes,
  SearchUserConfigurationRes,
  UpdateUserConfigurationReq,
} from '@/views/backoffice/user-management/store/user-management.types';
import type ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';
import {
  GET_USER_CONFIGURATION,
  SEARCH_USERS_CONFIGURATION,
  UPDATE_USER_CONFIGURATION,
} from '@/views/backoffice/user-management/store/user-management.constants';

@injectable()
export default class UserManagementService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = `/system/user-management`;

  public [SEARCH_USERS_CONFIGURATION](payload: any): Promise<SearchUserConfigurationRes> {
    return this.apiService.post<SearchUserConfigurationRes>(`${this.baseUrl}/${SEARCH_USERS_CONFIGURATION}`, payload, { onceAtSameTimeFlag: true });
  }

  public [GET_USER_CONFIGURATION](id: string): Promise<GetUserRes> {
    return this.apiService.get<GetUserRes>(`${this.baseUrl}/${GET_USER_CONFIGURATION}/${id}`);
  }

  public [UPDATE_USER_CONFIGURATION](id: string, payload: UpdateUserConfigurationReq): Promise<BaseResponse> {
    return this.apiService.put<BaseResponse>(`${this.baseUrl}/${id}`, payload);
  }
}
