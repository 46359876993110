<template>
  <vz-data-table
    class="system-log-table__container"
    auto-fetch
    :headers="tableHeaders"
    :items="items"
    :page="currentPage"
    :sort="pagination.sort"
    :total-items="totalItems"
    :loading="searchRequest.loading.value"
    :errors="searchRequest.error.value || {}"
    @clear="onClear"
    @search="onSearch"
    @select:item="onSelect"
    @update:page="onPageChange"
    @update:sort="onSortChange"
  >
    <template #search-panel="{ errors }">
      <vz-input v-model="filter.value" clearable label="GENERAL.SEARCH" :rules="{ maxLength: [LENGTH.TITLE] }" :error-message="errors?.value" />
    </template>

    <template #user="{ item }">
      <employee-strip class="pa-0" horizontal show-company :item="item" avatar-size="48" />
    </template>
  </vz-data-table>
</template>

<script setup lang="ts">
import type { TableHeader } from '@/shared/components/tables/models';
import type { BasePagination } from '@shared/models';
import type { SearchUserConfigurationRes } from '@/views/backoffice/user-management/store/user-management.types';
import { computed } from 'vue';
import { useAsync } from '@/shared/composables';
import { storeToRefs } from 'pinia';
import { LENGTH } from '@shared/constants';
import { useUserManagementStore } from '@/views/backoffice/user-management/store';
import {
  GET_USER_CONFIGURATION,
  SEARCH_USERS_CONFIGURATION,
  UPDATE_USER_CONFIGURATION,
} from '@/views/backoffice/user-management/store/user-management.constants';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';

const tableHeaders: Array<TableHeader> = [
  { title: 'GENERAL.USER', value: 'user', sortable: ['firstName', 'lastName'], style: { width: '200px' } },
  { title: 'GENERAL.EMAIL', value: 'email', sortable: true, style: { maxWidth: '260px', width: '200px' } },
  { title: 'GENERAL.PHONE', value: 'phone', sortable: true, style: { minWidth: '120px', width: '120px' } },
];

const userManagementStore = useUserManagementStore();

const { data, pagination, filter } = storeToRefs(userManagementStore);
const {
  resetUsersConfiguration,
  [SEARCH_USERS_CONFIGURATION]: searchUsersConfiguration,
  [GET_USER_CONFIGURATION]: getUserConfiguration,
  [UPDATE_USER_CONFIGURATION]: updateUserConfiguration,
} = userManagementStore;

const searchRequest = useAsync<SearchUserConfigurationRes>(
  searchUsersConfiguration as (page?: BasePagination) => Promise<SearchUserConfigurationRes>,
  {
    errorsCleanTimeout: 30 * 1000,
  }
);

const getRequest = useAsync<void>(getUserConfiguration as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
});

const updateRequest = useAsync<void>(updateUserConfiguration as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
  successCallback: searchRequest.call,
});

const currentPage = computed(() => pagination.value?.index || 0);
const totalItems = computed(() => pagination.value?.total || 0);
const items = computed(() => data.value);

const onSearch = (page?: BasePagination): void => {
  searchRequest.call(page);
};

const onClear = (): void => {
  resetUsersConfiguration();
  onSearch();
};

const onPageChange = (index: number): void => {
  onSearch({ index });
};

const onSortChange = (sort: BasePagination['sort']): void => {
  onSearch({ sort });
};

const onSelect = ({ _id }: { _id: string }): void => {
  getRequest.call(_id);
};
</script>

<style lang="scss" scoped></style>
