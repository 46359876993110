import { openPopup } from '@shared/components/popup/helpers/open-popup';
import { emitter } from '@/main';

type Params = Partial<{ title: string; description: string } & Record<string, string | number>> | string;

export const openDeletePopup = async (callback: () => unknown | Promise<unknown>, params?: Params, isRemove: boolean = false): Promise<void> => {
  const {
    title = isRemove ? 'WARNING.REMOVE' : 'WARNING.DELETE',
    description = isRemove ? 'WARNING.ARE_YOU_SURE_WANT_TO_REMOVE' : 'WARNING.ARE_YOU_SURE_WANT_TO_DELETE',
    ...rest
  } = typeof params === 'string' ? { name: params } : params || {};

  const arg = {
    ...rest,
    name: rest.name || rest.type,
    type: rest.type || rest.name,
  };

  await openPopup({
    title: [title, arg],
    description: [description, arg],
    actions: [
      {
        label: 'GENERAL.CANCEL',
        callback: () => emitter.emit('CLOSE_GLOBAL_MODAL'),
      },
      {
        label: isRemove ? 'GENERAL.REMOVE' : 'GENERAL.DELETE',
        color: 'red-900',
        callback: async () => {
          try {
            return await callback();
          } catch (error) {
            console.error(error);
          }
        },
      },
    ],
    icon: { name: isRemove ? 'svg:warning' : 'svg:trash', color: 'red-600' },
  });
};
