import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vz-popover" }

import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type PropType, ref, onMounted, onBeforeUnmount, type StyleValue } from 'vue';
import { isRtl } from '@/plugins/i18n/helpers';
import { getLastZIndex } from '@shared/helpers';
import VzButton from '@shared/components/buttons/vz-button.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-popover',
  props: {
  hover: { type: Boolean, default: false },
  hold: { type: [Boolean, Number] as PropType<number | boolean>, default: false },
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
  closeTimeout: { type: Number, default: 0 },
  iconName: { type: String as PropType<IconName>, default: 'svg:hdots' },
},
  emits: ['click', 'dblclick'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const isActive = ref<boolean>(false);
const zIndex = ref<number>(getLastZIndex() + 100);
const popoverRef = ref<HTMLDivElement>();
const popoverContent = ref<HTMLDivElement>();
const pressTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const closeTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const lastTap = ref(0);
const debounceId = ref<number | undefined>(undefined);

const popoverStyle = computed((): StyleValue => {
  if (!popoverRef.value || !popoverContent.value) {
    return {};
  }

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const { left: mainLeft, top: mainTop, height: mainHeight, width: mainWidth } = popoverRef.value.getBoundingClientRect();
  const { width: contentWidth, height: contentHeight } = popoverContent.value.getBoundingClientRect();

  const isLtr = !isRtl();
  let targetLeft = isLtr ? mainLeft + +props.offsetX : mainLeft + (mainWidth - contentWidth) - +props.offsetX;
  let targetTop = mainTop + mainHeight + 4 + +props.offsetY;

  if (targetLeft + contentWidth > windowWidth) {
    targetLeft = windowWidth - contentWidth - 8;
  }
  if (targetLeft < 0) {
    targetLeft = 8;
  }
  if (targetTop + contentHeight > windowHeight) {
    targetTop = mainTop - contentHeight - 8;
  }
  if (targetTop < 0) {
    targetTop = 8;
  }

  return {
    top: `${targetTop}px`,
    left: `${targetLeft}px`,
    zIndex: zIndex.value,
    maxHeight: `${windowHeight - targetTop - 16}px`,
    position: 'fixed',
  };
});

const onHover = (isHovered: boolean) => {
  if (props.hover) {
    clearTimeout(debounceId.value);
    debounceId.value = setTimeout(() => {
      isActive.value = isHovered;
    }, 500);
  }
};

const close = () => {
  isActive.value = false;
  window.removeEventListener('click', onClose);
  window.removeEventListener('touchstart', onClose);
};

const onClose = (ev: Event) => {
  const target = ev?.target as Node | null | undefined;

  if (isActive.value && target instanceof Node && (popoverRef.value?.contains(target) || popoverContent.value?.contains(target))) {
    return;
  }

  close();
};

const onOpen = (ev?: Event): void => {
  if (props.disabled) {
    emit('click');
    return;
  }

  if (isActive.value) {
    close();

    return;
  }

  ev?.preventDefault();
  isActive.value = true;
  zIndex.value = getLastZIndex() + 100;

  window.addEventListener('click', onClose);
  window.addEventListener('touchstart', onClose);

  if (props.closeTimeout) {
    clearTimeout(closeTimer.value);
    closeTimer.value = setTimeout(close, props.closeTimeout);
  }
};

const onPressStart = (ev: Event) => {
  emit('click');

  const currentTime = new Date().getTime();
  const tapLength = currentTime - lastTap.value;

  if (tapLength < 300 && tapLength > 0) {
    emit('dblclick');
  }

  lastTap.value = currentTime;

  if (props.hold) {
    ev.preventDefault();
  }

  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }

  pressTimer.value = setTimeout(onOpen, typeof props.hold === 'number' ? props.hold : 500);
};

const onPressEnd = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

const onPressCancel = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

const onEscape = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    close();
  }
};

onMounted(() => {
  window.addEventListener('resize', onClose);
  window.addEventListener('keydown', onEscape);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onClose);
  window.removeEventListener('keydown', onEscape);
});

__expose({ close: onClose, open: onOpen });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "popoverRef",
      ref: popoverRef,
      class: _normalizeClass(['vz-popover__activator', { 'vz-popover__activator--disabled': __props.disabled }]),
      role: "button",
      onMousedown: onPressStart,
      onMouseup: onPressEnd,
      onMouseleave: onPressCancel,
      onTouchstart: onPressStart,
      onTouchend: onPressEnd,
      onTouchcancel: onPressCancel,
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (onHover(true))),
      onMouseout: _cache[1] || (_cache[1] = ($event: any) => (onHover(false))),
      onDblclick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('dblclick'))),
      onClick: onOpen
    }, [
      _renderSlot(_ctx.$slots, "activator", { open: onOpen }, () => [
        _createVNode(VzButton, {
          class: "py-0",
          type: "flat",
          "icon-size": "1.5rem",
          "aria-label": "GENERAL.ACTIONS",
          "icon-name": __props.iconName,
          color: isActive.value ? 'primary-700' : 'primary-900'
        }, null, 8, ["icon-name", "color"])
      ])
    ], 34),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (isActive.value && !__props.disabled && (_ctx.$slots['top'] || _ctx.$slots['default'] || _ctx.$slots['bottom']))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref_key: "popoverContent",
            ref: popoverContent,
            class: "vz-popover__content",
            style: _normalizeStyle(popoverStyle.value),
            onMouseover: _cache[3] || (_cache[3] = ($event: any) => (onHover(true))),
            onMouseout: _cache[4] || (_cache[4] = ($event: any) => (onHover(false)))
          }, [
            _renderSlot(_ctx.$slots, "default", { close: onClose })
          ], 36))
        : _createCommentVNode("", true)
    ]))
  ]))
}
}

})