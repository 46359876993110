<template>
  <div class="message-share-location">
    <location-select
      v-if="!readonly && !coordinateRequest.loading.value"
      v-model="address"
      class="mx-4"
      clearable
      label="GENERAL.ADDRESS"
      :rules="{ required: [true] }"
      @update:model-value="() => (localCoordinate = address?.coordinates)"
    />

    <vz-spinner v-if="coordinateRequest.loading.value" class="mt-4" size="96px" />

    <template v-else>
      <iframe
        v-if="localCoordinate"
        class="mx-4"
        :src="`https://maps.google.com/maps?q=${localCoordinate[0]},${localCoordinate[1]}&hl=es&z=14&amp;output=embed`"
      />

      <vz-close-button v-if="clearable" class="message-share-location__close-button" @click="$emit('clear')" />

      <div v-if="!readonly" class="d-flex gap-4 justify-center flex-ch-1 mt-4 mx-4">
        <vz-button text="GENERAL.CANCEL" @click="$emit('cancel')" />

        <vz-button text="GENERAL.CONFIRM" @click="$emit('update:coordinates', { coordinates: localCoordinate })" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { BaseAddress } from '@shared/models';
import type { Coordinates } from '@shared/types';
import { onMounted, type PropType, ref } from 'vue';
import { useAsync } from '@shared/composables';
import { navigatorGeoLocation } from '@shared/helpers';
import LocationSelect from '@/components/fields/location-select.vue';

const props = defineProps({
  coordinates: { type: Object as PropType<Coordinates | undefined>, default: undefined },
  clearable: { type: Boolean, default: false },
  readonly: { type: Boolean, default: true },
});

defineEmits(['cancel', 'clear', 'update:coordinates']);

const localCoordinate = ref<Coordinates | undefined>(props.coordinates);
const coordinateRequest = useAsync<Coordinates | undefined>(navigatorGeoLocation, { resultsRef: localCoordinate });
const address = ref<BaseAddress | null>(null);

onMounted(() => {
  if (!props.readonly) {
    coordinateRequest.call();
  }
});
</script>

<style scoped lang="scss">
.message-share-location {
  position: relative;
  border-radius: var(--border-radius-medium);
  color: var(--color-primary-900);
  overflow: hidden;
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  iframe {
    border: initial;
    border-radius: var(--border-radius-medium);
    aspect-ratio: 2/1;
    width: calc(100% - 2rem);
    height: 100%;
  }

  &__close-button {
    position: absolute;
    top: 0.25rem;
    @include inline-end(0.125rem);
  }
}
</style>
