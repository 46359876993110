<template>
  <div
    v-if="employee"
    :class="['employee-strip', { clickable: clickable || openOnClick, active: active, skeleton: loading || getEmployeeRequest.loading.value }]"
    :style="{ fontSize }"
    @click="onClick"
  >
    <vz-avatar
      :hid="hid || employee._id"
      :first-name="employee.firstName"
      :last-name="employee.lastName"
      :size="avatarSize"
      :verified="employee.isVerified"
    />

    <div :class="['d-flex', { 'flex-column': horizontal, 'gap-2': !horizontal }]">
      <div class="employee-strip__full-name">
        <p
          v-if="employee.firstName && employee.lastName"
          :class="['text-ellipsis', { 'non-select clickable clickable-link': onNameClickCallback }]"
          @click="onNameClickCallback"
        >
          {{ [employee.firstName, employee.lastName].join(' ') }}
        </p>

        <p v-if="employee.email" class="text-ellipsis">{{ employee.email }}</p>
      </div>

      <p v-if="showCompany" :style="{ fontSize: `calc(${fontSize} - 4px)` }">{{ employee.company }}</p>
      <p v-if="subtitle" :style="{ fontSize: `calc(${fontSize} - 2px)` }">{{ subtitle }}</p>

      <slot />
    </div>
  </div>

  <div v-else-if="group" class="employee-strip" :class="{ clickable: clickable, active: active, skeleton: loading }" @click="$emit('select', group)">
    <vz-avatar v-for="(gHid, index) in groupHid" :key="index" :hid="gHid" :size="avatarSize" />

    <div class="employee-strip__group">
      <p class="d-flex gap-1">
        <span class="text-ellipsis">{{ group.title }}</span>
        <span v-if="groupMore">(+{{ groupMore }})</span>
      </p>

      <p v-if="group?.description" class="text-ellipsis" :style="{ fontSize: `calc(${fontSize} - 2px)` }">{{ group?.description }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProfileBaseDetails, ProfileExtendDetails, SearchEmployeeItem } from 'src/views/employee/types';
import type { GetConversationRes } from '@/views/job/types';
import type { SizeUnit } from '@shared/types';
import { computed, type PropType, watch } from 'vue';
import { GET_EMPLOYEE } from '@/views/employee/store/employee.constants';
import { routeTo, useAsync } from '@shared/composables';
import { useEmployeeStore } from '@/views/employee/store';

type Employee = ProfileBaseDetails | ProfileExtendDetails | SearchEmployeeItem | (SearchEmployeeItem & { conversation?: GetConversationRes });

const props = defineProps({
  fontSize: { type: String as PropType<SizeUnit | `var(--font-size-${number})`>, default: 'var(--font-size-18)' },
  horizontal: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  item: { type: [Object, String] as PropType<Employee | undefined>, default: undefined },
  active: { type: Boolean, default: false },
  avatarSize: { type: [String, Number], default: 32 },
  showCompany: { type: Boolean, default: false },
  hid: { type: String as PropType<string | undefined>, default: undefined },
  openOnClick: { type: Boolean, default: false },
  subtitle: { type: String as PropType<string | undefined>, default: undefined },
  onNameClickCallback: { type: Function as PropType<() => void>, default: undefined },
});

const emit = defineEmits(['select']);

const { [GET_EMPLOYEE]: getEmployeeAction } = useEmployeeStore();
const getEmployeeRequest = useAsync<ProfileBaseDetails>(getEmployeeAction as (userId: string) => Promise<ProfileBaseDetails>);

const employee = computed(() => {
  if (getEmployeeRequest.results.value) {
    return getEmployeeRequest.results.value;
  }

  return (props.item as SearchEmployeeItem)?.participants ? undefined : props.item;
});

const group = computed(() => ((props.item as SearchEmployeeItem)?.participants ? (props.item as SearchEmployeeItem) : undefined));
const groupHid = computed(() => (props.item as SearchEmployeeItem)?.participants?.slice(0, 3));
const groupMore = computed(() => ((props.item as SearchEmployeeItem)?.participants?.length || 0) - (groupHid.value?.length || 0));

const onClick = () => {
  if (props.openOnClick) {
    routeTo({ query: { userId: employee.value?._id || null } });

    return;
  }

  emit('select', employee.value);
};

watch(
  () => props.hid,
  (hid) => {
    if (hid) {
      getEmployeeRequest.call(hid);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.employee-strip {
  transition:
    color 0.3s,
    background-color 0.3s;
  padding: 0.5rem 0;
  display: flex;
  align-items: start;
  gap: 0.25rem;
  z-index: 0;

  .vz-avatar:not(:first-child) {
    margin-inline-start: -1.5rem;
  }

  &__group {
    flex-direction: column;
  }

  &__group,
  &__full-name {
    display: flex;
    font-weight: var(--font-weight-semibold);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    gap: 0.25rem;

    > * {
      text-transform: capitalize;
    }
  }

  &.active {
    background-color: var(--color-primary-100);
    border-radius: var(--border-radius-regular);
  }
}
</style>
