import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import UserManagementService from '@/views/backoffice/user-management/service/user-management.service';
import type { BasePagination } from '@shared/models';
import { ref } from 'vue';
import {
  GET_USER_CONFIGURATION,
  SEARCH_USERS_CONFIGURATION,
  UPDATE_USER_CONFIGURATION,
} from '@/views/backoffice/user-management/store/user-management.constants';
import type {
  GetUserRes,
  SearchUserConfigurationRes,
  SearchUsersConfigurationReq,
  UpdateUserConfigurationReq,
} from '@/views/backoffice/user-management/store/user-management.types';

const userManagementService = container.resolve(UserManagementService);

export const useUserManagementStore = defineStore(StoreNamespace.BACKOFFICE_USERS_MODULE, () => {
  const DEFAULT_FILTER = {};
  const DEFAULT_PAGINATION: BasePagination = {};

  const userDetails = ref<GetUserRes | null>(null);
  const usersData = ref<SearchUserConfigurationRes['data']>([]);
  const usersFilter = ref<Omit<SearchUsersConfigurationReq, 'page'>>({ ...DEFAULT_FILTER });
  const usersPagination = ref<BasePagination>({ ...DEFAULT_PAGINATION });

  const resetUsersConfiguration = (): void => {
    usersFilter.value = { ...DEFAULT_FILTER };
    usersPagination.value = { ...DEFAULT_PAGINATION };
    usersData.value = [];
  };

  const searchUsersConfiguration = async (page?: BasePagination): Promise<SearchUserConfigurationRes> => {
    usersData.value = [];

    const res = await userManagementService[SEARCH_USERS_CONFIGURATION]({
      ...usersFilter.value,
      page: { ...usersPagination.value, ...(page || {}) },
    });

    usersData.value = res.data;
    usersPagination.value = res.page;

    const { index = 0, total = 0 } = res.page;
    if (total > 0 && index >= total) {
      await searchUsersConfiguration({ ...res.page, index: total - 1 });
    }

    return res;
  };

  const getUserConfiguration = async (userId: string): Promise<void> => {
    userDetails.value = await userManagementService[GET_USER_CONFIGURATION](userId);
  };

  const updateUserConfiguration = async (userId: string = userDetails.value!.userId, payload: UpdateUserConfigurationReq): Promise<void> => {
    await userManagementService[UPDATE_USER_CONFIGURATION](userId, payload);
  };

  return {
    details: userDetails,
    data: usersData,
    filter: usersFilter,
    pagination: usersPagination,
    resetUsersConfiguration,
    [SEARCH_USERS_CONFIGURATION]: searchUsersConfiguration,
    [GET_USER_CONFIGURATION]: getUserConfiguration,
    [UPDATE_USER_CONFIGURATION]: updateUserConfiguration,
  };
});
