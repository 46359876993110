<template>
  <img
    ref="imgRef"
    :class="{ skeleton: (isLoading || asyncLoading) && !nonSkeleton, default: imgSrc === defaultSrc }"
    :alt="alt || hidAlt"
    :src="imgSrc"
    :style="{ filter }"
    v-bind="$attrs"
    @error="onError"
    @load="onLoaded"
    @loadstart="isLoading = true"
  />
</template>

<script setup lang="ts">
import type { BaseImageResponse } from '@shared/models';
import type { ColorsMap } from '@shared/services/css-service/types';
import { type PropType, ref, watch } from 'vue';
import { useSwipe } from '@shared/composables/use-swipe';
import { isRtl } from '@/plugins/i18n/helpers';
import { ColorFilter } from '@shared/services/css-service/color-filter.service';

const props = defineProps({
  colorOverlay: { type: Boolean, default: false },
  nonSkeleton: { type: Boolean, default: false },
  alt: { type: String, default: '' },
  src: { type: String, default: '' },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  srcProvider: { type: Object as PropType<Promise<BaseImageResponse> | undefined>, default: undefined },
  defaultSrc: { type: String, default: () => require('@/assets/images/loader/image.svg') },
});
const emit = defineEmits(['error', 'load', 'back', 'next']);

const isLoading = ref<boolean>(true);
const asyncLoading = ref<boolean>(false);
const isFailed = ref<boolean>(false);
const hidAlt = ref<string | undefined>(undefined);
const imgSrc = ref<string | undefined>(props.src || props.defaultSrc);
const filter = ref();

const onError = (event: Event) => {
  if (!isLoading.value) {
    return;
  }

  (event.target as HTMLImageElement).onerror = null;
  isFailed.value = true;
  isLoading.value = false;
  imgSrc.value = props.defaultSrc;
  emit('error');
};

const onLoaded = async (): Promise<void> => {
  isLoading.value = false;
  emit('load');
};

const imgRef = ref<HTMLElement | undefined>(undefined);

useSwipe(imgRef, { isRtl: ref(isRtl()), onBack: () => emit('back'), onNext: () => emit('next') });

watch(
  () => props.srcProvider,
  async (provider) => {
    if (!provider) {
      return;
    }

    try {
      const preview = props.src;
      asyncLoading.value = true;
      const { image, alt } = await provider;

      if (preview !== props.src) {
        return;
      }

      imgSrc.value = image || props.src || props.defaultSrc;
      hidAlt.value = alt;
    } catch (e) {
      imgSrc.value = props.src || props.defaultSrc;
    } finally {
      asyncLoading.value = false;
    }
  },
  { immediate: true }
);

watch(imgRef, (el) => {
  if (!el || !props.colorOverlay) {
    return;
  }

  const parseRGB = (input: string): [number, number, number] | string => {
    const regex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
    const match = input.match(regex);

    if (match) {
      return match.slice(1, 4).map(Number) as [number, number, number]; // Convert extracted strings to numbers
    }

    return input; // Return as is if format does not match
  };

  filter.value = new ColorFilter(parseRGB(getComputedStyle(el).color)).filter;
});
</script>

<style scoped>
.default {
  mix-blend-mode: multiply;
}
</style>
