import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-2" }

import VzPageWrapper from '@shared/components/page-wrapper/vz-page-wrapper.vue';
import { computed } from 'vue';
import { ASSIGNED } from '@/store/auth/auth.constants';
import ManagerAssigneeSelect from '@/components/fields/manager-assignee-select.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'job.view',
  setup(__props) {

const { [ASSIGNED]: assignedModulesState } = storeToRefs(useAuthStore());

const authModules = computed(() =>
  assignedModulesState.value
    ? Object.entries(assignedModulesState.value.modules)
        .filter(([_, value]) => value.length)
        .map(([key]) => key)
    : null
);

return (_ctx: any,_cache: any) => {
  const _component_vz_avatar = _resolveComponent("vz-avatar")!
  const _component_vz_button = _resolveComponent("vz-button")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(VzPageWrapper, {
    modules: authModules.value,
    "show-side-bar": !!_unref(assignedModulesState)?.userId
  }, {
    header: _withCtx(({ isShown }) => [
      (_unref(assignedModulesState)?.userId && !isShown)
        ? (_openBlock(), _createBlock(_component_vz_avatar, {
            key: 0,
            class: "ms-4",
            size: "32",
            hid: _unref(assignedModulesState)?.userId
          }, null, 8, ["hid"]))
        : (_openBlock(), _createBlock(_component_vz_button, {
            key: 1,
            class: "ms-4 pa-1",
            color: "primary-900",
            type: "rounded",
            "icon-size": "1.125rem",
            "icon-name": !isShown ? 'svg:menu' : 'svg:arrow-up'
          }, null, 8, ["icon-name"]))
    ]),
    menu: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(ManagerAssigneeSelect)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component), _mergeProps({
            key: route.path
          }, route.meta.args), null, 16))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modules", "show-side-bar"]))
}
}

})