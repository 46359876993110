<template>
  <div class="row-flex-4">
    <div class="col-flex-6">
      <vz-card class="fill-height d-flex flex-column">
        <div class="text-title-1 text-center">{{ $t('FINANCE.INCOME_GROWTH_OVER_TIME') }}</div>

        <base-chart v-if="!loading" class="flex-grow-1" hide-legend chart-id="incomeGrowthChart" type="line" :chart-data="incomeGrowthChart" />

        <div v-else class="flex-grow-1 d-flex justify-center align-center">
          <vz-spinner size="96px" />
        </div>
      </vz-card>
    </div>

    <div class="col-flex-3">
      <vz-card class="fill-height d-flex flex-column">
        <div class="text-title-1 text-center">{{ $t('FINANCE.INCOME_BY_CATEGORY') }}</div>

        <base-chart v-if="!loading" chart-id="incomeCategoryChart" type="pie" :chart-data="incomeByCategoryChart" />

        <div v-else class="flex-grow-1 d-flex justify-center align-center">
          <vz-spinner size="96px" />
        </div>
      </vz-card>
    </div>

    <div class="col-flex-3">
      <vz-card class="fill-height d-flex flex-column">
        <div class="text-title-1 text-center">{{ $t('FINANCE.EXPENSE_BY_CATEGORY') }}</div>

        <base-chart v-if="!loading" chart-id="expenseCategoryChart" type="pie" :chart-data="expenseByCategoryChart" />

        <div v-else class="flex-grow-1 d-flex justify-center align-center">
          <vz-spinner size="96px" />
        </div>
      </vz-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Transaction } from '@/views/job/types';
import { computed } from 'vue';
import BaseChart from '@shared/components/charts/base-chart.vue';
import { useLobbyStore } from '@/views/lobby/store';
import { GET_MY_FINANCE_SUMMARY } from '@/views/job/views/manager/modules/finance/store/job-finance.constants';
import { TransactionEnum } from '@/constants';
import { useTranslator } from '@/plugins/i18n/helpers';
import { stringToBlueColor } from '@shared/helpers';

const t = useTranslator();

const { [GET_MY_FINANCE_SUMMARY]: getMyFinanceSummary } = useLobbyStore();
const data = computed(() => getMyFinanceSummary.results?.data || []);
const loading = computed(() => getMyFinanceSummary.loading);

const incomeGrowthChart = computed(() => {
  let cumulativeIncome = 0;
  const dateMap: Record<string, number> = {};

  const incomeTransactions = data.value
    .filter((item) => item.type === TransactionEnum.INCOME)
    .reduce((acc, item) => acc.concat(item.transactions), [] as Array<Transaction>);

  const sortedTransactions = [...incomeTransactions].sort((a, b) => a.paidAt - b.paidAt);

  for (const transaction of sortedTransactions) {
    cumulativeIncome += transaction.amount;
    const dateStr = new Date(transaction.paidAt).toISOString().split('T')[0];

    dateMap[dateStr] = cumulativeIncome;
  }

  return {
    labels: Object.keys(dateMap),
    datasets: [
      {
        data: Object.values(dateMap),
        borderColor: stringToBlueColor(t('FINANCE.INCOME_GROWTH_OVER_TIME')),
        fill: true,
        tension: 0.3,
      },
    ],
  };
});

const incomeByCategoryChart = computed(() => {
  const categoryMap: Record<string, { value: number; callback?: () => void }> = {};

  for (const item of data.value) {
    if (item.type === TransactionEnum.INCOME) {
      categoryMap[item.category] = {
        value: (categoryMap[item.category]?.value || 0) + item.amount,
        // TODO: Implement redirect to finance page filtered by income and category
        // callback: () => console.log(item),
      };
    }
  }

  const labels = Object.keys(categoryMap).map((key) => t(`FINANCE.CATEGORY.${key}`));
  const backgroundColor = labels.map(stringToBlueColor);

  return {
    labels,
    datasets: [
      {
        data: Object.values(categoryMap).map(({ value }) => value),
        callback: Object.values(categoryMap).map(({ callback }) => callback),
        backgroundColor,
      },
    ],
  };
});

const expenseByCategoryChart = computed(() => {
  const categoryMap: Record<string, { value: number; callback?: () => void }> = {};

  for (const item of data.value) {
    if (item.type === TransactionEnum.EXPENSE) {
      categoryMap[item.category] = {
        value: (categoryMap[item.category]?.value || 0) + item.amount,
        // TODO: Implement redirect to finance page filtered by expense and category
        // callback: () => console.log(item.category),
      };
    }
  }

  const labels = Object.keys(categoryMap).map((key) => t(`FINANCE.CATEGORY.${key}`));
  const backgroundColor = labels.map(stringToBlueColor);

  return {
    labels,
    datasets: [
      {
        data: Object.values(categoryMap).map(({ value }) => value),
        callback: Object.values(categoryMap).map(({ callback }) => callback),
        backgroundColor,
      },
    ],
  };
});
</script>
