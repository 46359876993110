import type ServerError from '@shared/services/api-service/server-error';

export const useAsyncMiddleware = async (
  asyncFunction: Promise<unknown> | undefined,
  successCallback?: () => void,
  throwError: boolean = true
): Promise<ServerError | null> => {
  if (!asyncFunction) {
    successCallback?.();

    return null;
  }

  try {
    await asyncFunction;

    successCallback?.();
  } catch (e: any) {
    return throwError ? e : null;
  }

  return null;
};
