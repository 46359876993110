import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column gap-4 pa-8"
}
const _hoisted_2 = { class: "text-title-1" }
const _hoisted_3 = { class: "text-title-1" }

import type { OverlayRef } from '@shared/components';
import type { SendMessagePayload } from '@shared/components/messenger/messenger.type';
import { type PropType, ref } from 'vue';
import MessageShareLocation from '@shared/components/messenger/components/message-share-sheet/message-share-location.vue';
import MessageShareAttachment from '@shared/components/messenger/components/message-share-sheet/message-share-attachment.vue';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';

enum ShareType {
  LOCATION = 'LOCATION',
  ATTACHMENT = 'ATTACHMENT',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'message-share-sheet',
  props: {
  payload: { type: Object as PropType<SendMessagePayload | undefined>, required: true },
},
  emits: ['update:payload'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const sheetRef = ref<OverlayRef>(undefined);
const sheetType = ref<ShareType | null>(null);

const onClose = () => setTimeout(() => (sheetType.value = null), 500);

const onUpdate = (update: Record<string, any>): void => {
  emit('update:payload', { ...props.payload, ...update });

  sheetRef.value?.close();
};

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_overlay = _resolveComponent("vz-overlay")!

  return (_openBlock(), _createBlock(_component_vz_overlay, {
    ref_key: "sheetRef",
    ref: sheetRef,
    "fit-content": "",
    size: _unref(VzOverlayEnum).SMALL,
    title: !sheetType.value ? 'GENERAL.SHARE' : `SHARE.${sheetType.value}`,
    onClose: onClose
  }, {
    activator: _withCtx(({ open }) => [
      _createVNode(_component_vz_icon, {
        name: "svg:plus",
        size: "1.25rem",
        onClick: open
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      (!sheetType.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "d-flex gap-4 align-center clickable",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (sheetType.value = ShareType.LOCATION))
            }, [
              _createVNode(_component_vz_icon, {
                clickable: "",
                name: "svg:location",
                size: "1.5rem"
              }),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('SHARE.LOCATION')), 1)
            ]),
            _createElementVNode("div", {
              class: "d-flex gap-4 align-center clickable",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (sheetType.value = ShareType.ATTACHMENT))
            }, [
              _createVNode(_component_vz_icon, {
                clickable: "",
                name: "svg:attachment",
                type: "regular",
                size: "1.5rem"
              }),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('SHARE.ATTACHMENT')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (sheetType.value === ShareType.LOCATION)
        ? (_openBlock(), _createBlock(MessageShareLocation, {
            key: 1,
            coordinates: __props.payload?.coordinates,
            readonly: false,
            onCancel: _cache[2] || (_cache[2] = ($event: any) => (sheetType.value = null)),
            "onUpdate:coordinates": onUpdate
          }, null, 8, ["coordinates"]))
        : _createCommentVNode("", true),
      (sheetType.value === ShareType.ATTACHMENT)
        ? (_openBlock(), _createBlock(MessageShareAttachment, {
            key: 2,
            "onUpdate:attachments": onUpdate,
            onCancel: _cache[3] || (_cache[3] = ($event: any) => (sheetType.value = null))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["size", "title"]))
}
}

})