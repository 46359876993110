import type { IconName } from '@shared/components/icon/icon.type';

export const FileIconMap = new Map<string, IconName>()
  .set('pdf', 'svg:application/pdf')
  // .set('doc', 'application/msword')
  // .set('docx', 'application/msword')
  // .set('xls', 'application/msexcel')
  // .set('xlsx', 'application/msexcel')
  .set('jpg', 'svg:image/jpeg')
  .set('jpeg', 'svg:image/jpeg')
  .set('svg', 'svg:image/jpeg')
  .set('png', 'svg:image/jpeg');
