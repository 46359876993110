import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = { class: "text-ellipsis" }
const _hoisted_3 = { class: "vz-input__container" }
const _hoisted_4 = ["value", "placeholder", "disabled", "autocomplete", "readonly", "type", "aria-label"]

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { IconName } from '@/shared/components/icon/icon.type';
import type { ColorName } from '@shared/services/css-service/types';
import type { SizeUnit } from '@shared/types';
import type { ErrorMessageRef } from '@shared/components';
import { computed, type PropType, ref, watch } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-input',
  props: {
  ltr: { type: Boolean as PropType<boolean>, default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: [Object, Number, String, Array] as PropType<Record<string, any> | number | string | Array<any> | undefined>, required: true },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  type: { type: String as PropType<'text' | 'number' | 'password' | 'email'>, default: 'text' },
  autocomplete: { type: String as PropType<'on' | 'off'>, default: 'off' },
  nullable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  debounce: { type: [Number, String], default: 0 },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  capitalized: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  hideDetails: { type: Boolean, default: false },
  isStandalone: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  appendIcon: { type: String as PropType<IconName | undefined>, default: undefined },
  appendColor: { type: String as PropType<ColorName | undefined>, default: undefined },
  appendSize: { type: String as PropType<SizeUnit | undefined>, default: '0.75rem' },
  appendLabel: { type: String, default: 'GENERAL.BUTTON' },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
},
  emits: ['update:model-value', 'click:append', 'on-timeout', 'focus', 'blur', 'enter'],
  setup(__props, { emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;

const debounceTimeout = ref<ReturnType<typeof setTimeout>>();
const inputRef = ref<HTMLInputElement | undefined>(undefined);
const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const errorMessageRef = ref<ErrorMessageRef>(undefined);

const isClearable = computed(() => !!props.modelValue);

const inputType = computed(() => {
  switch (props.type) {
    case 'number':
      return 'number';
    case 'email':
      return 'text';
    default:
      return props.type;
  }
});

const vModel = computed({
  get: (): any => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onUpdate = (event: Event): void => {
  const value = (event.target as HTMLInputElement).value;

  if (!value) {
    vModel.value = props.nullable ? null : undefined;
  }

  switch (props.type) {
    case 'text':
      vModel.value = props.capitalized ? value.charAt(0).toUpperCase() + value.slice(1) : value;
      break;
    case 'number':
      vModel.value = !value || isNaN(+value) ? undefined : +value;
      break;
    default:
      vModel.value = value;
      break;
  }
};

const onKeydown = (event: KeyboardEvent): void => {
  if (event.key === 'Enter') {
    event.stopPropagation();
    event.preventDefault();

    emit('enter');
  }
};

const onFocus = (): void => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  isFocus.value = true;
  emit('focus');
};

const onBlur = (): void => {
  blurTimeout.value = setTimeout(() => {
    isFocus.value = false;
    emit('blur');
  }, 500);
};

watch(
  () => vModel.value,
  (value) => {
    if (!props.debounce) {
      return;
    }

    clearTimeout(debounceTimeout.value);

    debounceTimeout.value = setTimeout(() => {
      emit('on-timeout', value);
    }, +props.debounce);
  }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_error_message = _resolveComponent("vz-error-message")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vz-input", { 'vz-input--loading': __props.loading, 'vz-input--disabled': __props.disabled }]),
    "data-errors": errorMessageRef.value?.errorMessage
  }, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "prefix"),
      _createElementVNode("input", {
        ref_key: "inputRef",
        ref: inputRef,
        tabindex: "0",
        class: _normalizeClass(['text-auto', { ltr: __props.ltr }]),
        value: vModel.value,
        placeholder: _ctx.$t(__props.placeholder),
        disabled: __props.disabled,
        autocomplete: __props.autocomplete,
        readonly: __props.readonly,
        type: inputType.value,
        "aria-label": _unref(t)('COMPONENT_LABELS.TEXT_FIELD', { value: __props.ariaLabel || __props.label || __props.placeholder }),
        onFocus: onFocus,
        onBlur: onBlur,
        onInput: onUpdate,
        onKeydown: onKeydown
      }, null, 42, _hoisted_4),
      (__props.clearable && isClearable.value)
        ? (_openBlock(), _createBlock(_component_vz_icon, {
            key: 0,
            class: "vz-input__icon",
            clickable: "",
            role: "button",
            name: "svg:xmark",
            size: "0.75rem",
            color: "primary-900",
            "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', null)))
          }, null, 8, ["aria-label"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append", {}, () => [
        (__props.appendIcon)
          ? (_openBlock(), _createBlock(_component_vz_icon, {
              key: 0,
              clickable: "",
              role: "button",
              size: __props.appendSize,
              name: __props.appendIcon,
              color: __props.appendColor,
              "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: __props.appendLabel }),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click:append')))
            }, null, 8, ["size", "name", "color", "aria-label"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default"),
    (!__props.hideDetails)
      ? (_openBlock(), _createBlock(_component_vz_error_message, {
          key: 0,
          ref_key: "errorMessageRef",
          ref: errorMessageRef,
          value: __props.modelValue,
          name: __props.name || __props.label,
          rules: __props.rules,
          errors: __props.errorMessage
        }, null, 8, ["value", "name", "rules", "errors"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})