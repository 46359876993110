<template>
  <div class="vz-rich-text" :data-errors="errorMessageRef?.errorMessage">
    <label v-if="label" class="text-ellipsis mb-4">{{ $t(label) }}</label>

    <div class="vz-rich-text-container">
      <rich-text
        v-if="!readonly"
        class="fill-height flex-grow-1"
        :value="vModel"
        :dynamic-fields="dynamicFields"
        :undo="$t('COMPONENT.RICH_TEXT.UNDO')"
        :redo="$t('COMPONENT.RICH_TEXT.REDO')"
        :bold="$t('COMPONENT.RICH_TEXT.BOLD')"
        :italic="$t('COMPONENT.RICH_TEXT.ITALIC')"
        :underline="$t('COMPONENT.RICH_TEXT.UNDERLINE')"
        :font-size="$t('COMPONENT.RICH_TEXT.FONT_SIZE')"
        :format-block="$t('COMPONENT.RICH_TEXT.STYLES')"
        :justify-left="$t('COMPONENT.RICH_TEXT.ALIGN_LEFT')"
        :justify-center="$t('COMPONENT.RICH_TEXT.ALIGN_CENTER')"
        :justify-right="$t('COMPONENT.RICH_TEXT.ALIGN_RIGHT')"
        :insert-ordered-list="$t('COMPONENT.RICH_TEXT.ORDERED_LIST')"
        :insert-unordered-list="$t('COMPONENT.RICH_TEXT.UNORDERED_LIST')"
        :indent="$t('COMPONENT.RICH_TEXT.INDENT')"
        :outdent="$t('COMPONENT.RICH_TEXT.OUTDENT')"
        :remove-format="$t('COMPONENT.RICH_TEXT.REMOVE_FORMAT')"
        :doc-direction="$t('COMPONENT.RICH_TEXT.DOCUMENT_DIRECTION')"
        :full-screen="$t('COMPONENT.RICH_TEXT.FULL_SCREEN')"
        :preview="$t('COMPONENT.RICH_TEXT.PREVIEW')"
        :insert-dynamic-field="$t('COMPONENT.RICH_TEXT.INSERT_DYNAMIC_FIELD')"
        :direction="direction"
        @change="onUpdate"
        @preview="onPreview"
        @insert-dynamic="$emit('insert:dynamic-field')"
      />

      <div v-else class="border-regular border-radius-regular flex-grow-1" v-html="vModel" />

      <vz-error-message ref="errorMessageRef" :value="vModel" :name="name || label" :rules="vRules" :errors="errorMessage" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CustomValidator, ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorMessageRef } from '@shared/components';
import { computed, type PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { htmlToPdf } from '@shared/helpers';
import FileService from '@shared/services/file.service';

const props = defineProps({
  readonly: { type: Boolean, default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: String as PropType<string | undefined>, required: true },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: String as PropType<string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  dynamicFields: { type: Array as PropType<Array<{ text: string; value: string }> | undefined>, default: undefined },
  direction: { type: String as PropType<'ltr' | 'rtl' | undefined>, default: undefined },
});

const { t } = useI18n();
const emit = defineEmits(['update:model-value', 'insert:dynamic-field']);

const errorMessageRef = ref<ErrorMessageRef>(undefined);

const dynamicFields = computed(() => {
  if (!props.dynamicFields) {
    return '';
  }

  const options = props.dynamicFields.map(({ text, value }) => ({ text: t(text), value }));

  return JSON.stringify(options);
});

const vModel = computed({
  get: (): string | undefined => props.modelValue || '',
  set: (value) => emit('update:model-value', value),
});

const onUpdate = (ev?: CustomEvent<{ htmlBody: string; direction?: string }>) => {
  if (ev?.detail?.htmlBody !== undefined) {
    vModel.value = ev.detail.htmlBody;
  }
};

const onPreview = async (): Promise<void> => {
  const blob = await htmlToPdf(vModel.value!);
  const url = URL.createObjectURL(blob);

  FileService.downloadLink(url, 'preview.pdf');
};

const requiredHtmlValidator: CustomValidator = (value: string) => {
  return [!!value?.replace(/<[^>]*>/g, '').length, 'VALIDATE.REQUIRED'];
};

const vRules = computed((): ValidatorFieldRules | undefined => {
  return props.rules
    ? { ...props.rules, ...(props.rules?.required?.[0] ? { custom: [requiredHtmlValidator, ...(props.rules.custom || [])] } : {}) }
    : undefined;
});
</script>

<style lang="scss">
.vz-rich-text {
  display: flex;
  flex-direction: column;
  min-height: 320px;

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;

    .rich-text__toolbar {
      padding: 0.5rem 0;
    }

    .rich-text__editor {
      outline: none !important;
      padding-inline-end: 0.5rem;
      width: 100%;

      &:has(div:focus-visible) {
        outline: var(--outline-focus);
      }
    }
  }
}
</style>
