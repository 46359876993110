import type { BaseId } from '@shared/models';
import { computed, type ComputedRef, onMounted, onUnmounted, ref, watch } from 'vue';

interface UseFirebasePush {
  id: ComputedRef<string | undefined>;
  type: ComputedRef<string | undefined>;
  data: ComputedRef<Record<string, any> | undefined>;
  state: ComputedRef<Record<string, any> | undefined>;
}

export const useFirebasePush = (onChange?: (...arg: Array<any>) => Promise<unknown> | unknown, identifyBy?: Record<string, any>): UseFirebasePush => {
  const firebaseBroadcastChannel = new BroadcastChannel('firebase-messaging-sw');
  const state = ref<Record<string, any> | undefined>(undefined);

  const storageChangeEvent = ({ key }: StorageEvent) => {
    if (key !== 'pushData') {
      return;
    }

    const storedData = localStorage.getItem('pushData');

    state.value = storedData ? JSON.parse(storedData) : null;
  };

  onMounted(() => {
    firebaseBroadcastChannel.onmessage = (event: any) => (state.value = event.data || undefined);
    window.addEventListener('storage', storageChangeEvent);
  });

  onUnmounted(() => {
    firebaseBroadcastChannel.close();
    window.removeEventListener('storage', storageChangeEvent);
  });

  watch(
    () => state.value,
    (value) => {
      if (!value || !Object.entries(identifyBy || {}).every(([idKey, idValue]) => value[idKey] === idValue)) {
        return;
      }

      onChange?.(value);
    },
    { immediate: true, deep: true }
  );

  return {
    id: computed((): BaseId | undefined => state.value?.id),
    type: computed((): string | undefined => state.value?.type),
    data: computed((): Record<string, any> | undefined => state.value?.data),
    state: computed((): Record<string, any> | undefined => state.value),
  };
};
