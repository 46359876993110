import type { IconName } from '@shared/components/icon/icon.type';
import { FileIconMap } from '@shared/constants/file-icon.map';

export const GetFileIcon = (fileName?: string): IconName => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  if (!extension) {
    return 'svg:file';
  }

  return FileIconMap.get(extension) || 'svg:file';
};
