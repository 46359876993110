import { type DirectiveBinding } from 'vue';
import { LayoutEnum } from '@shared/constants';

type Element = HTMLElement & { __layoutObserver__?: ResizeObserver; __layoutChangeHandler__: () => void };
type LayoutCallback = (layout: keyof typeof LayoutEnum) => void;

function handleLayoutChange(el: Element, callback?: LayoutCallback): void {
  const rootStyles = getComputedStyle(el);
  const variablesToCheck = Object.values(LayoutEnum).map((layout) => `--layout-${layout}`);

  const layoutSizes: { name: string; width: number }[] = variablesToCheck.map((variable) => {
    const value = rootStyles.getPropertyValue(variable).trim();

    return {
      name: variable.replace('--layout-', ''),
      width: value ? parseInt(value, 10) : Infinity,
    };
  });

  layoutSizes.sort((a, b) => a.width - b.width);

  const elementWidth = el.clientWidth;
  let selectedLayout = layoutSizes[layoutSizes.length - 1].name;

  for (let i = 0; i < layoutSizes.length; i++) {
    if (elementWidth <= layoutSizes[i].width) {
      selectedLayout = layoutSizes[i].name; // Match the first suitable layout
      break;
    }
  }

  if (el.getAttribute('data-layout') === selectedLayout) {
    return;
  }

  el.setAttribute('data-layout', selectedLayout);
  callback?.(selectedLayout as keyof typeof LayoutEnum);
}

export const vueLayout = {
  mounted(el: Element, { value }: DirectiveBinding<(layout: keyof typeof LayoutEnum) => void>) {
    el.__layoutChangeHandler__ = () => handleLayoutChange(el, value);

    const observer = new ResizeObserver(() => {
      el.__layoutChangeHandler__();
    });

    observer.observe(el);
    el.__layoutObserver__ = observer;
    el.__layoutChangeHandler__();
  },
  updated(el: Element) {
    el.__layoutChangeHandler__?.();
  },
  unmounted(el: Element) {
    el.__layoutObserver__?.disconnect();
  },
};
