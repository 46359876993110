import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

import type { SplashName } from '@shared/components/svg-href/svg-splash.type';
import { computed, type PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'svg-href',
  props: {
  name: { type: String as PropType<SplashName | string>, required: true },
},
  setup(__props) {

const props = __props;

const href = computed(() => require(`@/assets/images/${props.name}.svg`) + `#${props.name}`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", null, [
    _createElementVNode("use", { href: href.value }, null, 8, _hoisted_1)
  ]))
}
}

})