import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vz-attachment__container" }
const _hoisted_2 = { class: "vz-subtitle-3 text-ellipsis" }
const _hoisted_3 = { class: "vz-subtitle-3 text-ellipsis" }

import type { IconName } from '@shared/components/icon/icon.type';
import type { BaseFile } from '@shared/types';
import type { GetAttachmentRes } from '@/store/client/models';
import type { FileDropEvent } from '@shared/elements/file-drop';
import { computed, type PropType, ref, watch } from 'vue';
import FileService from '@shared/services/file.service';
import { useAsync } from '@shared/composables';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-attachment',
  props: {
  path: { type: String, default: '' },
  hideNote: { type: Boolean, default: false },
  value: { type: Array as PropType<Array<File | BaseFile> | undefined>, required: true },
  provider: { type: Function as PropType<({ id }: { id: string }) => Promise<GetAttachmentRes>>, default: () => {} },
  readonly: { type: Boolean, default: false },
  autoUpdate: { type: Boolean, default: false },
  maxFiles: { type: Number, default: 0 },
  maxFileSize: { type: Number, default: 0 },
  acceptType: { type: [String, Array] as PropType<string | Array<string>>, default: '' },
  hideUploadButton: { type: Boolean, default: false },
  folderDrillDownDeepLevel: { type: [Number, String] as PropType<`${string}` | number>, default: 0 },
},
  emits: ['update:value'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const t = useTranslator();

const getAttachmentRequest = useAsync<GetAttachmentRes>(props.provider as ({ id }: { id: string }) => Promise<GetAttachmentRes>);

const files = ref<Array<File>>([]);
const markWarning = ref<boolean>(false);

const typeLabel = computed(() => {
  return (Array.isArray(props.acceptType) ? props.acceptType : props.acceptType!.split(','))
    .map((type) => type.split('/')[1])
    .join(', ')
    .toUpperCase();
});

const itemContainerSize = computed(() => {
  if (props.readonly) {
    if (!props.value?.length) {
      return;
    }

    const count = Math.min(props.value.length, 3);

    return { width: `calc(${100 / count}% - 0.5rem)` };
  }

  if (!files.value?.length) {
    return { width: `100%` };
  }

  const count = Math.min(files.value.length + (!props.hideUploadButton && (!props.maxFiles || files.value.length < props.maxFiles) ? 1 : 0), 3);

  return { width: `calc(${100 / count}% - 0.5rem)` };
});

const insertFiles = (input: Array<File> | FileList) => {
  const type = (Array.isArray(props.acceptType) ? props.acceptType : props.acceptType?.split(','))?.filter((type) => !!type);

  if (
    Array.from(input).some((file) => {
      const isValidSize = !props.maxFileSize || file.size <= props.maxFileSize;
      const isValidType = !type?.length || type.includes(file.type);

      return isValidSize && isValidType;
    })
  ) {
    markWarning.value = true;

    setTimeout(() => {
      markWarning.value = false;
    }, 3000);
  }

  const insert = Array.from(input).filter((file) => {
    const isValidSize = !props.maxFileSize || file.size <= props.maxFileSize;
    const isValidType = !type?.length || type.includes(file.type);

    return isValidSize && isValidType;
  });

  const payload = (props.maxFiles ? [...(files.value || []), ...insert].slice(0, props.maxFiles) : [...(files.value || []), ...insert]).map(
    (file) => {
      const fileName = [props.path, file.name].join('/').replace('//', '/');

      return new File([file], fileName, { type: file.type });
    }
  );

  emit('update:value', payload);
};

const onFiles = ({ detail }: CustomEvent<FileDropEvent>): void => {
  insertFiles(detail.files);
};

const onRemove = (index: number) => {
  const payload = [...(files.value || [])];
  payload.splice(index, 1);

  emit('update:value', payload);
};

const onInput = async (): Promise<void> => {
  const files = await FileService.uploadFile({
    multiple: true,
    max: props.maxFiles ? props.maxFiles - (props.value || []).length : undefined,
    accept: Array.isArray(props.acceptType) ? props.acceptType : props.acceptType?.split(','),
    // TODO: Temporary disabled folder select on input
    // folder: !!props.folderDrillDownDeepLevel,
  });

  if (files) {
    insertFiles(files);
  }
};

const onPreview = async (file: File | BaseFile): Promise<void> => {
  if (!(file instanceof File)) {
    const asyncFile = await getAttachmentRequest.call(file.attachmentId);

    if (!asyncFile) {
      return;
    }

    if (navigator.share) {
      navigator.share({ files: [FileService.base64ToFile(asyncFile.data, asyncFile)], title: asyncFile.name });
    } else {
      FileService.downloadLink(asyncFile.data, asyncFile.name);
    }
  }
};

watch(
  () => [props.value, props.readonly],
  async () => {
    if (props.readonly) {
      return;
    }

    const res = await Promise.all(
      (props.value || []).map(async (file): Promise<File | null> => {
        if (file instanceof File) {
          return file;
        }

        const asyncFile = await getAttachmentRequest.call(file.attachmentId);

        return asyncFile ? FileService.base64ToFile(asyncFile.data, asyncFile) : null;
      })
    );

    files.value = res.filter((file): file is File => file !== null);
  },
  { immediate: true, deep: true }
);

__expose({ insert: onInput });

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_file_drop = _resolveComponent("file-drop")!

  return (!__props.readonly)
    ? (_openBlock(), _createBlock(_component_file_drop, {
        key: 0,
        class: "vz-attachment",
        folder: __props.folderDrillDownDeepLevel,
        "max-files": __props.maxFiles,
        onUpdate: onFiles
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(['vz-attachment__item', 'vz-attachment__item--removable']),
                  style: _normalizeStyle(itemContainerSize.value)
                }, [
                  _createVNode(_component_vz_icon, {
                    name: `svg:${file.type}` as IconName,
                    fallback: "svg:file",
                    type: "regular",
                    size: "4rem"
                  }, null, 8, ["name"]),
                  _createElementVNode("p", _hoisted_2, _toDisplayString(file.name), 1),
                  _createVNode(_component_vz_icon, {
                    name: "svg:trash",
                    type: "regular",
                    size: "1.125rem",
                    onClick: onRemove
                  })
                ], 4))
              }), 128)),
              (!__props.hideUploadButton && (__props.value || []).length < __props.maxFiles)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "vz-attachment__item",
                    style: _normalizeStyle(itemContainerSize.value)
                  }, [
                    _createVNode(_component_vz_icon, {
                      name: "svg:plus",
                      type: "regular",
                      size: "1.25rem",
                      onClick: onInput
                    })
                  ], 4))
                : _createCommentVNode("", true)
            ]),
            (!__props.hideNote)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: _normalizeClass({ 'c-red-600': markWarning.value })
                }, _toDisplayString(_unref(t)('WARNING.UPLOAD', { type: typeLabel.value, size: __props.maxFileSize / 1024 / 1024 })), 3))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 3
      }, 8, ["folder", "max-files"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(['vz-attachment', { 'vz-attachment--readonly': __props.readonly }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.value, (file, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(['vz-attachment__preview']),
            style: _normalizeStyle(itemContainerSize.value)
          }, [
            _createVNode(_component_vz_icon, {
              name: `svg:${file.type}` as IconName,
              fallback: "svg:file",
              type: "regular",
              size: "3rem",
              onClick: () => onPreview(file)
            }, null, 8, ["name", "onClick"]),
            _createElementVNode("p", _hoisted_3, _toDisplayString(file.name), 1)
          ], 4))
        }), 128))
      ], 2))
}
}

})