import type { BaseChartResponse, BaseResponse } from '@shared/models';
import type ApiService from '@/shared/services/api-service/api.service';
import type { GetCalendarEventsReq, GetCalendarEventsRes, GetUpcomingEventsRes, GetUpcomingEventsReq } from '@/views/calendar/types';
import type { GetNotificationReq, GetNotificationsRes } from '@/views/notifications/store/notification.types';
import type { SearchJobOffersReq, SearchJobOffersRes } from '@/views/job/types';
import type { GetMyFinanceSummaryRes } from '@/views/job/views/manager/modules/finance/types';
import { inject, injectable } from 'tsyringe';
import { GET_CALENDAR_EVENTS, GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import { GET_JOB_OFFERS, GET_JOB_OFFERS_STATISTICS } from '@/views/lobby/store/lobby.constants';
import { GET_MY_FINANCE_SUMMARY } from '@/views/job/views/manager/modules/finance/store/job-finance.constants';

@injectable()
export default class LobbyService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = `/lobby`;

  public [GET_UPCOMING_EVENTS](payload?: GetUpcomingEventsReq): Promise<GetUpcomingEventsRes> {
    return this.apiService.post<GetUpcomingEventsRes>(`${this.baseUrl}/${GET_UPCOMING_EVENTS}`, payload, { onceAtSameTimeFlag: true });
  }

  public [GET_CALENDAR_EVENTS](payload: GetCalendarEventsReq): Promise<GetCalendarEventsRes> {
    const { year = new Date().getFullYear(), month = new Date().getMonth() + 1 } = payload || {};

    return this.apiService.get<GetCalendarEventsRes>(`${this.baseUrl}/${GET_CALENDAR_EVENTS}?year=${year}&month=${month}`, {
      onceAtSameTimeFlag: true,
    });
  }

  public [GET_NOTIFICATIONS](payload: GetNotificationReq): Promise<GetNotificationsRes> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${GET_NOTIFICATIONS}`, payload);
  }

  public [GET_JOB_OFFERS](payload: SearchJobOffersReq): Promise<SearchJobOffersRes> {
    return this.apiService.post<SearchJobOffersRes>(`${this.baseUrl}/${GET_JOB_OFFERS}`, payload);
  }

  public [GET_JOB_OFFERS_STATISTICS](): Promise<BaseChartResponse<{ amount: number; type: string }>> {
    return this.apiService.get<BaseChartResponse<{ amount: number; type: string }>>(`${this.baseUrl}/${GET_JOB_OFFERS_STATISTICS}`);
  }

  public [GET_MY_FINANCE_SUMMARY](): Promise<GetMyFinanceSummaryRes> {
    return this.apiService.get<GetMyFinanceSummaryRes>(`${this.baseUrl}/${GET_MY_FINANCE_SUMMARY}`);
  }
}
