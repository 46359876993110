import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = {
  key: 0,
  class: "d-flex fill-width flex-wrap gap-2 mt-2"
}

import type { ErrorResponse } from '@shared/services/api-service/models';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { IconName } from '@shared/components/icon/icon.type';
import type { ColorsMap } from '@shared/services/css-service/types';
import type { SizeUnit } from '@shared/types';
import type { ErrorMessageRef } from '@shared/components';
import { computed, type PropType, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-tag',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: Array as PropType<Array<string> | undefined>, required: true },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  type: { type: String as PropType<'text' | 'number' | 'password' | 'email'>, default: 'text' },
  autocomplete: { type: String as PropType<'on' | 'off'>, default: 'off' },
  nullable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  debounce: { type: [Number, String], default: 0 },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  capitalized: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  hideDetails: { type: Boolean, default: false },
  isStandalone: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  appendIcon: { type: String as PropType<IconName | undefined>, default: undefined },
  appendLabel: { type: String, default: 'GENERAL.BUTTON' },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: 'mono-800' },
  fontSize: { type: String as PropType<SizeUnit>, default: '1rem' },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
},
  emits: ['update:model-value'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const errorMessageRef = ref<ErrorMessageRef>(undefined);
const text = ref<string>('');

const vModel = computed({
  get: () => props.modelValue || [],
  set: (value) => emit('update:model-value', value),
});

const onEnter = () => {
  if (text.value) {
    vModel.value = [...vModel.value, text.value];

    text.value = '';
  }
};

const onRemoveOption = (index: number) => {
  vModel.value.splice(index, 1);
};

return (_ctx: any,_cache: any) => {
  const _component_vz_badge = _resolveComponent("vz-badge")!
  const _component_vz_input = _resolveComponent("vz-input")!
  const _component_vz_error_message = _resolveComponent("vz-error-message")!

  return (_openBlock(), _createElementBlock("div", {
    class: "vz-tag",
    "data-errors": errorMessageRef.value?.errorMessage
  }, [
    _createVNode(_component_vz_input, {
      modelValue: text.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((text).value = $event)),
      class: "fill-width",
      "hide-details": "",
      "append-icon": "svg:plus",
      label: __props.label,
      "aria-label": __props.ariaLabel,
      placeholder: __props.placeholder,
      type: __props.type,
      autocomplete: __props.autocomplete,
      nullable: __props.nullable,
      disabled: __props.disabled,
      debounce: __props.debounce,
      loading: __props.loading,
      readonly: __props.readonly,
      clearable: __props.clearable,
      capitalized: __props.capitalized,
      "is-standalone": __props.isStandalone,
      "append-label": __props.appendLabel,
      "append-color": text.value ? 'primary-900' : 'mono-300',
      "onClick:append": onEnter,
      onEnter: onEnter
    }, {
      default: _withCtx(() => [
        (vModel.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vModel.value || [], (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  item
                    ? (_openBlock(), _createBlock(_component_vz_badge, {
                        key: 0,
                        class: "px-2",
                        clearable: "",
                        "font-size": __props.fontSize,
                        text: item,
                        color: __props.color,
                        onClear: ($event: any) => (onRemoveOption(index))
                      }, null, 8, ["font-size", "text", "color", "onClear"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "label", "aria-label", "placeholder", "type", "autocomplete", "nullable", "disabled", "debounce", "loading", "readonly", "clearable", "capitalized", "is-standalone", "append-label", "append-color"]),
    _createVNode(_component_vz_error_message, {
      ref_key: "errorMessageRef",
      ref: errorMessageRef,
      value: __props.modelValue,
      name: __props.name || __props.label,
      rules: __props.rules,
      errors: __props.errorMessage
    }, null, 8, ["value", "name", "rules", "errors"])
  ], 8, _hoisted_1))
}
}

})