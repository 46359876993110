import type { BaseDate } from '@/shared/models';
import dayjs from 'dayjs';
import { GlobalVariables } from '@/shared/constants/global-variables';

export const formattedDate = (
  time?: { dateFrom?: number; dateTo?: number } | number | string | undefined | null,
  dateFromFormat: string = GlobalVariables.FULL_DATE,
  dateToFormat: string = GlobalVariables.FULL_DATE
): string | undefined => {
  if (typeof time === 'number' || typeof time === 'string') {
    return dayjs(time).format(dateFromFormat || dateToFormat);
  }

  if (typeof time === 'object') {
    const { dateFrom, dateTo } = (time || {}) as { dateFrom?: BaseDate; dateTo?: BaseDate };

    if (!dateFrom) {
      return;
    }

    const from = dayjs(+(dateFrom! || dateTo!)).format(dateFromFormat);
    const to = dayjs(+(dateTo || dateFrom)).format(dateToFormat);

    return from === to ? from : `${from} - ${to}`;
  }
};
