import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vz-card__toolbar"
}
const _hoisted_2 = { class: "d-flex gap-2 justify-end" }

import type { ColorsMap } from '@shared/services/css-service/types';
import { computed, type PropType, ref, useSlots } from 'vue';
import { useAsync, useServerErrorsMapper } from '@/shared/composables';
import { useFormValidator } from '@/shared/components/fields/helpers';
import VzErrorAlert from '@shared/components/vz-error-alert.vue';

enum CardMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-card',
  props: {
  scrollOnChange: { type: Boolean, default: false },
  behavior: { type: String as PropType<ScrollBehavior>, default: 'smooth' },
  removable: { type: Boolean, default: false },
  editable: { type: Boolean, default: true },
  loading: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  showLabels: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  flat: { type: [Boolean, Array] as PropType<boolean | Array<keyof typeof CardMode>>, default: false },
  updateCallback: { type: Function as PropType<(...arg: any) => Promise<any> | any>, default: undefined },
  updatePayload: { type: Object as PropType<any>, default: () => ({}) },
  deleteCallback: { type: Function as PropType<() => Promise<void> | undefined>, default: undefined },
  immediateUpdate: { type: Boolean, default: false },
  backgroundColor: { type: String as PropType<ColorsMap | undefined>, default: undefined },
},
  emits: ['edit', 'remove', 'click', 'load', 'update'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const slots = useSlots();

const updateCallbackRequest = useAsync(props.updateCallback || (() => {}), {
  successCallback: () => {
    setTimeout(() => (cardMode.value = CardMode.VIEW));
    emit('edit', false);
  },
  errorsCleanTimeout: 30 * 1000,
});

const cardMode = ref<CardMode>(CardMode.VIEW);
const formRef = ref<Element | undefined>(undefined);
const errors = useServerErrorsMapper(updateCallbackRequest.error);

const showToolbar = computed(() => {
  const isDisabled = props.disabled || cardMode.value !== CardMode.VIEW;
  const isEditable = props.editable && (slots['edit'] || props.updateCallback);
  const isRemovable = props.removable || props.deleteCallback;
  const hasToolbarSlot = slots['toolbar'];

  return !isDisabled && (isEditable || isRemovable || hasToolbarSlot);
});

const isViewSlotEnabled = computed(() => cardMode.value === CardMode.VIEW && slots['view']);
const isEditSlotEnabled = computed(() => cardMode.value === CardMode.EDIT && slots['edit']);

const onEdit = (): void => {
  if (!slots['edit']) {
    updateCallbackRequest.call(props.updatePayload);

    return;
  }

  cardMode.value = cardMode.value === CardMode.EDIT ? CardMode.VIEW : CardMode.EDIT;
  emit('edit', cardMode.value === CardMode.EDIT);
};

const onRemove = (): void => {
  if (props.deleteCallback) {
    props.deleteCallback();
  } else {
    emit('remove');
  }
};

const onClick = (ev: Event): void => {
  if (!props.clickable || props.disabled) {
    return;
  }

  if (cardMode.value !== CardMode.VIEW) {
    ev.stopPropagation();
    ev.preventDefault();
  } else {
    emit('click');
  }
};

const onSubmit = async (ev?: Event): Promise<void> => {
  ev?.stopPropagation();
  ev?.preventDefault();

  const isValid = useFormValidator(formRef);

  if (!isValid()) {
    return;
  }

  await updateCallbackRequest.call(props.updatePayload);
};

const onCancel = (ev?: Event): void => {
  ev?.stopPropagation();
  ev?.preventDefault();

  if (cardMode.value === CardMode.EDIT) {
    setTimeout(() => (cardMode.value = CardMode.VIEW));
    emit('edit', false);
  }
};

const onKeyDown = (ev: KeyboardEvent): void => {
  if (ev.key === 'Escape' && cardMode.value === CardMode.EDIT) {
    onSubmit(ev);
  }
};

__expose({
  onEdit,
  onRemove,
  disabled: computed(() => cardMode.value !== CardMode.VIEW),
  isEditActive: computed(() => cardMode.value === CardMode.EDIT),
});

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!
  const _directive_key_down = _resolveDirective("key-down")!
  const _directive_scroll_to_view = _resolveDirective("scroll-to-view")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'vz-card',
      {
        'vz-card--editing': isEditSlotEnabled.value,
        'vz-card--clickable': __props.clickable && !__props.disabled && !isEditSlotEnabled.value,
        'vz-card--flat': (Array.isArray(__props.flat) && __props.flat.includes(cardMode.value)) || __props.flat === true,
        skeleton: __props.loading,
      },
    ]),
    onClick: onClick
  }, [
    (showToolbar.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (__props.removable || __props.deleteCallback)
            ? (_openBlock(), _createBlock(_component_vz_button, {
                key: 0,
                color: "red-700",
                "icon-name": "svg:trash",
                "icon-size": "1rem",
                "background-color": "mono-100",
                type: "solid",
                text: __props.showLabels ? 'GENERAL.REMOVE' : undefined,
                onClick: _withModifiers(onRemove, ["stop"])
              }, null, 8, ["text"]))
            : _createCommentVNode("", true),
          (_ctx.$slots['edit'] || __props.updateCallback)
            ? (_openBlock(), _createBlock(_component_vz_button, {
                key: 1,
                color: "primary-900",
                "icon-name": "svg:edit",
                "icon-size": "1rem",
                "background-color": "mono-100",
                type: "solid",
                text: __props.showLabels ? 'GENERAL.EDIT' : undefined,
                onClick: _withModifiers(onEdit, ["stop"])
              }, null, 8, ["text"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "toolbar")
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.$slots.static)
      ? _renderSlot(_ctx.$slots, "static", { key: 1 })
      : _createCommentVNode("", true),
    (_ctx.$slots.view || _ctx.$slots.edit || _ctx.$slots.remove)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (isViewSlotEnabled.value)
            ? _renderSlot(_ctx.$slots, "view", { key: 0 })
            : (isEditSlotEnabled.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("form", {
                    ref_key: "formRef",
                    ref: formRef,
                    class: "flex-grow-1 overflow-y-auto",
                    role: "form",
                    autocomplete: "off",
                    onDblclick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
                    onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                  }, [
                    _createVNode(VzErrorAlert, {
                      errors: _unref(updateCallbackRequest).error.value
                    }, null, 8, ["errors"]),
                    _renderSlot(_ctx.$slots, "edit", {
                      errors: _unref(errors),
                      formRef: formRef.value
                    })
                  ], 544),
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_vz_button, {
                      text: !__props.immediateUpdate ? 'GENERAL.SAVE' : 'GENERAL.CLOSE',
                      loading: _unref(updateCallbackRequest).loading.value,
                      onClick: onSubmit
                    }, null, 8, ["text", "loading"]),
                    (!__props.immediateUpdate)
                      ? (_openBlock(), _createBlock(_component_vz_button, {
                          key: 0,
                          text: "GENERAL.CANCEL",
                          disabled: _unref(updateCallbackRequest).loading.value,
                          onClick: onCancel
                        }, null, 8, ["disabled"]))
                      : _createCommentVNode("", true)
                  ])), [
                    [_directive_key_down, onKeyDown]
                  ])
                ], 64))
              : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "bottom")
  ], 2)), [
    [_directive_scroll_to_view, [__props.scrollOnChange, __props.behavior]]
  ])
}
}

})