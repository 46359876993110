<template>
  <div :class="['vz-data-table', { 'vz-data-table--loading': isLoading }]">
    <div v-if="$slots['search-panel']" class="pb-2">
      <vz-search-panel :hide-controls="hideSearchControls" @clear="onClear" @search="onSearch">
        <slot name="search-panel" :errors="serverErrors" />
      </vz-search-panel>
    </div>

    <div
      v-if="$slots['table-options'] || $slots['prepend']"
      :class="['d-flex', $slots['prepend'] ? 'justify-space-between flex-wrap' : 'justify-end', 'mb-2']"
    >
      <slot name="prepend" />

      <div class="d-flex align-end gap-2 me-2">
        <slot name="table-options" />
      </div>
    </div>

    <vz-split :class="{ 'border-top-medium': $slots['side-panel'] }" min-size="50" max-size="70" initial-size="70" breakpoint-slot="1">
      <template #1>
        <template v-if="hasItems || isLoading">
          <div ref="tableContainerRef" v-layout class="vz-data-table__table-container">
            <table>
              <thead v-if="!hideTableHeaders">
                <tr>
                  <td
                    v-for="({ _hiddenClass, ...header }, index) in tableHeaders"
                    :key="index"
                    :class="[`vz-data-table__data-${header.value}`, ..._hiddenClass]"
                  >
                    <slot :name="`header-${header.value}`" :header="header">
                      <div
                        :class="[
                          'text-ellipsis',
                          {
                            clickable: header.sortable,
                            'd-flex gap-1': header.sortable,
                            'text-center justify-center': header.center,
                            'ps-4': header.center && header.sortable,
                          },
                        ]"
                        @click="onSort(header.value, header.sortable)"
                      >
                        <slot :name="`label-${header.value}`">
                          {{ $t(header.title) }}
                        </slot>

                        <div v-if="vSort[header.value] || header.sortable" class="d-flex align-center justify-center">
                          <vz-icon v-if="vSort[header.value]" :name="`svg:sort-${vSort[header.value].type === 1 ? 'up' : 'down'}`" />
                          <vz-icon v-else-if="header.sortable" name="svg:sort" fill-opacity="0.1" />
                        </div>
                      </div>
                    </slot>
                  </td>

                  <td v-if="isItemOptionsEnabled || $slots['item-options']">
                    <slot name="item-options-header">{{ $t('GENERAL.ACTIONS') }}</slot>
                  </td>

                  <td v-if="menuActions.length" class="vz-data-table__menu-action">
                    <vz-popover-menu
                      :show-action-for-one-item="showActionForOneItem"
                      icon-type="regular"
                      icon-name="svg:vdots"
                      :items="menuActions"
                    />
                  </td>
                </tr>
              </thead>

              <tbody>
                <template v-for="(groupItems, groupTitle) in groupedItems" :key="groupTitle">
                  <tr v-if="hasGroups">
                    <td class="vz-data-table__table-group" :colspan="tableHeaders.length + 2">
                      <div>{{ groupTitle }}</div>
                    </td>
                  </tr>

                  <tr
                    v-for="({ ...item }, itemIndex) in groupItems"
                    :key="itemIndex"
                    :class="{ 'vz-data-table__table-container--row-clickable': isClickable(item) && readable }"
                    @click="readable ? onSelect(item) : undefined"
                  >
                    <td
                      v-for="({ _hiddenClass, style, ...header }, columIndex) in tableHeaders"
                      :key="columIndex"
                      :class="[`vz-data-table__data-${header.value} relative`, ..._hiddenClass]"
                      :style="style"
                    >
                      <div
                        :class="[
                          'vz-data-table__cell d-flex fill-height',
                          { 'text-center justify-center': header.center },
                          ...(Array.isArray(header.class) ? header.class : [header.class]),
                        ]"
                      >
                        <slot :item="item" :item-index="itemIndex" :col-index="columIndex" :name="header.value">
                          {{ get(item, header.value, null) }}
                        </slot>
                      </div>
                    </td>

                    <td v-if="isItemOptionsEnabled || $slots['item-options']" class="vz-data-table__item-options">
                      <div class="d-flex gap-2 align-center" @click.stop>
                        <slot name="item-options" :item="item" :item-index="itemIndex" />

                        <vz-button
                          v-if="updateCallback"
                          icon-name="svg:edit"
                          icon-type="regular"
                          aria-label="GENERAL.EDIT"
                          :disabled="disabled || !isActionEnabled(item, TableItemEnum.EDIT)"
                          @click="onEdit(item)"
                        />

                        <vz-button
                          v-if="duplicateCallback"
                          icon-name="svg:copy"
                          icon-type="regular"
                          aria-label="GENERAL.DUPLICATE"
                          :disabled="disabled || !isActionEnabled(item, TableItemEnum.DUPLICATE)"
                          @click="onDuplicate(item)"
                        />

                        <vz-button
                          v-if="deleteCallback"
                          color="red-900"
                          icon-name="svg:trash"
                          icon-type="regular"
                          aria-label="GENERAL.DELETE"
                          :disabled="disabled || !isActionEnabled(item, TableItemEnum.DELETE)"
                          @click="onDelete(item)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div v-if="!hidePagination && totalItems > 1" class="border-top-medium">
            <vz-tab-switcher v-model:tab-index="vPage" class="vz-data-table__pagination" flat center :tabs="totalItems || 0" />
          </div>
        </template>

        <div v-else-if="!isLoading && !hideEmptyState" flat class="vz-data-table vz-data-table--no-data text-title-1">
          <slot name="no-data" :errors="allErrors">
            <empty-state class="bg-light ma-0" :errors="allErrors" :no-data-text="noDataText || defaultNoDataText" :no-data-image="splashImage">
              <slot name="no-data-actions">
                <vz-button
                  v-if="createCallback"
                  class="mt-2 px-4"
                  icon-name="svg:plus"
                  icon-size="1.125rem"
                  :text="addItemLabel"
                  @click="createCallback"
                />

                <vz-popover-menu v-else-if="menuActions.length" :items="menuActions">
                  <template #activator>
                    <vz-button class="px-4" call-to-action icon-name="svg:vdots" text="GENERAL.ACTIONS" v-bind="tableActionButton" />
                  </template>
                </vz-popover-menu>
              </slot>
            </empty-state>
          </slot>
        </div>
      </template>

      <template v-if="$slots['side-panel']" #2>
        <slot name="side-panel" />
      </template>
    </vz-split>
  </div>
</template>

<script setup lang="ts">
import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';
import type { BasePagination, BaseRecords } from '@shared/models';
import type { TableGroups, TableHeader } from '@shared/components/tables/models';
import type { MenuItem } from '@shared/components/menus/models/menu-item';
import { computed, defineAsyncComponent, nextTick, onMounted, type PropType, ref, useSlots } from 'vue';
import { get } from 'lodash';
import { useAsync, useServerErrorsMapper } from '@/shared/composables';
import { LayoutEnum } from '@shared/directives/layout.enum';
import { TableItemEnum } from '@shared/components/tables/constants/data-table.enum';
import VzButton from '@shared/components/buttons/vz-button.vue';
import type { IconName } from '@shared/components/icon/icon.type';
import { ColorsMap } from '@shared/services/css-service/types';
import type { SizeUnit } from '@shared/types';

type TableSort = Record<string, { type: -1 | 1; fields: Array<string> }>;
const EmptyState = defineAsyncComponent(() => import(/* webpackChunkName: "empty-state" */ '@/components/empty-state.vue'));

const props = defineProps({
  showActionForOneItem: { type: Boolean, default: true },
  hideTableHeaders: { type: Boolean, default: false },
  hidePagination: { type: Boolean, default: false },
  autoFetch: { type: Boolean, default: true },
  flat: { type: Boolean, default: false },
  readable: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  headers: { type: Array as PropType<Array<TableHeader>>, required: true },
  items: { type: Array as PropType<Array<{ [key: string]: any }>>, required: true },
  page: { type: Number, default: 0 },
  sort: { type: Object as PropType<BasePagination['sort']>, default: () => ({}) },
  totalItems: { type: Number, default: 0 },
  loading: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  rowClickable: { type: [Boolean, Function] as PropType<boolean | ((item: any) => boolean)>, default: true },
  idKey: { type: String, default: '' },
  hideEmptyState: { type: Boolean, default: false },
  noDataText: { type: String, default: '' },
  noDataImage: { type: String as PropType<SplashName>, default: 'search-for-results' },
  addItemLabel: { type: String, default: 'GENERAL.ADD' },
  noResultsImage: { type: String as PropType<SplashName>, default: 'no-results' },
  isReadonlyCallback: { type: Function as PropType<(item: any, type?: keyof typeof TableItemEnum) => boolean>, default: () => false },
  hideSearchControls: { type: Boolean, default: false },
  // item actions
  updateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  deleteCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  duplicateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  // table actions
  tableActionButton: { type: Object as PropType<Partial<{ text: string; color: ColorsMap; iconName: IconName }> | undefined>, default: undefined },
  tableActions: { type: Array as PropType<Array<MenuItem> | undefined>, default: undefined },
  createCallback: { type: Function as PropType<(() => unknown | Promise<unknown>) | undefined>, default: undefined },
  exportCallback: {
    type: Function as PropType<((items: Array<{ [key: string]: any }>, headers?: Array<TableHeader>) => unknown | Promise<unknown>) | undefined>,
    default: undefined,
  },
  hasFilters: { type: Boolean, default: false },
  fetchCallback: { type: Function as PropType<((page?: BasePagination) => Promise<BaseRecords<any>>) | undefined>, default: undefined },
  groupBy: { type: Object as PropType<TableGroups<any>>, default: undefined },
  breakpoint: {
    type: String as PropType<LayoutEnum | null>,
    default: LayoutEnum.md,
    validator: (value: LayoutEnum | null) => value === null || Object.values(LayoutEnum).includes(value),
  },
});

const emit = defineEmits(['search', 'clear', 'update:page', 'update:sort', 'select:item', 'update:item', 'duplicate:item', 'delete:item']);
const { call: internalRequest, loading: internalLoading, error: internalErrors, results: internalResults } = useAsync(props.fetchCallback);

const slots = useSlots();
const searchTriggered = ref<boolean>(false);

const tableHeaders = computed((): Array<TableHeader & { _hiddenClass: Array<string> }> => {
  const filterStaticHidden = props.headers?.filter(({ hidden }) => hidden !== true) || [];

  return filterStaticHidden.map(({ hidden, ...header }) => {
    const _hiddenClass = Array.isArray(hidden) ? hidden.map((value) => `hidden-${value}`) : [];

    return { ...header, _hiddenClass };
  });
});

const isActionEnabled = (item: any, type: keyof typeof TableItemEnum) => {
  const map = {
    [TableItemEnum.EDIT]: !!props.updateCallback,
    [TableItemEnum.DELETE]: !!props.deleteCallback,
    [TableItemEnum.DUPLICATE]: !!props.duplicateCallback,
  };

  return map[type] && !props.isReadonlyCallback(item, type);
};

const isItemOptionsEnabled = computed(() => !!(props.updateCallback || props.deleteCallback));

const menuActions = computed((): Array<MenuItem> => {
  const actions: Array<MenuItem> = [];

  if (props.createCallback) {
    actions.push({ text: 'GENERAL.ADD', icon: { name: 'svg:plus', size: '1.25rem' }, click: props.createCallback });
  }

  if (props.exportCallback) {
    actions.push({
      text: 'GENERAL.EXPORT',
      icon: { name: 'svg:file', size: '1.25rem', type: 'solid' },
      click: () => props.exportCallback?.(allItems.value, props.headers),
    });
  }

  return [...actions, ...(props.tableActions || [])];
});

const splashImage = computed((): SplashName => {
  if (allErrors.value?.errorMessage?.length) {
    return 'server-error';
  }

  return searchTriggered.value ? props.noResultsImage : props.noDataImage;
});

const defaultNoDataText = computed(() => {
  if (!slots['search-panel']) {
    return 'DATA.NO_DATA_AVAILABLE';
  }

  return searchTriggered.value || props.hasFilters ? 'DATA.NO_SEARCH_RESULTS' : 'DATA.SEARCH_FOR_RESULTS';
});

const allItems = computed(() => [...(props.items || []), ...(internalResults.value?.data || [])]);
const groupedItems = computed((): { [key: string]: BaseRecords<any>['data'] } => {
  if (props.groupBy) {
    return props.groupBy.groups.reduce((acc: { [key: string]: BaseRecords<any>['data'] }, { value, title }) => {
      const items = allItems.value.filter((item) => item[props.groupBy!.key] === value);

      return items.length ? { ...acc, [title as string]: items } : acc;
    }, {});
  }

  return { _: allItems.value };
});
const allErrors = computed(() => props.errors || internalErrors.value);
const isLoading = computed(() => props.loading || internalLoading.value);
const hasItems = computed(() => Object.values(groupedItems.value).some((items) => items.length));
const hasGroups = computed(() => !!Object.keys(groupedItems.value).filter((key) => key !== '_').length);

const serverErrors = useServerErrorsMapper(allErrors);
const internalPage = ref<number>(0);

const vPage = computed({
  get: (): number => (props.fetchCallback ? internalPage.value : props.page),
  set: (value: number) => {
    internalPage.value = value;
    internalRequest?.({ index: value });
    emit('update:page', value);
  },
});

const vSort = computed({
  get: (): TableSort => {
    return (props.headers || []).reduce((acc: TableSort, { sortable, value }) => {
      const sort = props.sort || {};

      if (Array.isArray(sortable)) {
        const arrSort = Object.entries(sort);

        const type = arrSort.find(([key]) => {
          return sortable.includes(key);
        })?.[1];

        return type ? { ...acc, [value]: { type: type, fields: sortable } } : acc;
      } else if (sortable === true) {
        return sort[value] ? { ...acc, [value]: { type: sort[value], fields: [value] } } : acc;
      }

      return acc;
    }, {});
  },
  set: (value) => {
    const sort = Object.values(value).reduce((acc: Record<string, -1 | 1>, { type, fields }) => {
      fields
        .filter((key) => !!key)
        .forEach((key) => {
          acc = { ...acc, [key]: type };
        });

      return acc;
    }, {});

    internalRequest?.({ sort });
    emit('update:sort', sort);
  },
});

const onSort = (key: string, sortable?: boolean | Array<string>) => {
  if (!sortable) {
    return;
  }

  if (!vSort.value[key]?.type) {
    vSort.value = { [key]: { type: 1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else if (vSort.value[key]?.type === 1) {
    vSort.value = { [key]: { type: -1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else {
    vSort.value = {};
  }
};

const isClickable = (item: any) => {
  return !isLoading.value && ((props.rowClickable instanceof Function && props.rowClickable(item)) || props.rowClickable === true);
};

const onSelect = (item: any) => {
  if (isLoading.value || !isClickable(item)) {
    return;
  }

  emit('select:item', props.idKey ? item[props.idKey] : item);
};

const onSearch = async (): Promise<void> => {
  await internalRequest?.();
  searchTriggered.value = true;
  emit('search');
};

const onClear = async (): Promise<void> => {
  searchTriggered.value = false;
  emit('clear');

  nextTick(internalRequest);
};

const onEdit = async (item: any): Promise<void> => {
  await props.updateCallback?.(item);
  emit('update:item', item);
};

const onDuplicate = async (item: any): Promise<void> => {
  await props.duplicateCallback?.(item);
  emit('duplicate:item', item);
};

const onDelete = async (item: any): Promise<void> => {
  await props.deleteCallback?.(item);
  emit('delete:item', item);
};

onMounted(() => {
  if (props.autoFetch && !groupedItems.value.length) {
    onSearch();
  }
});
</script>

<style src="./vz-data-table.scss" lang="scss" />
