import type { BasePagination, BaseRecords } from '@/shared/models';
import type ServerError from '@/shared/services/api-service/server-error';
import { computed, type ComputedRef, type Ref, ref, watch } from 'vue';
import { useAsync } from '@shared/composables/use-async';

type Options<D, P> = Partial<{
  payload: Ref<P> | ComputedRef<P>;
  initial?: BaseRecords<any>;
  items?: Array<D>;
  reverse: boolean;
  disabled: ComputedRef<boolean>;
  disablePayloadWatcher: boolean;
  callbackSuccess: (results: BaseRecords<any>) => Promise<unknown> | unknown;
  isHorizontal: ComputedRef<boolean>;
}>;

type InfinityScroll<T> = {
  elementRef: Ref<HTMLElement | undefined>;
  reset: () => void;
  push: (item: Record<string, any>, config: { idKey: string; forceEndScroll?: boolean }) => void;
  update: (item: Record<string, any> | undefined, idKey: string) => void;
  remove: (id: any, idKey: string) => void;
  scrollTo: (to: 'END' | 'START') => void;
  page: Ref<BasePagination>;
  data: ComputedRef<Array<T>>;
  loading: Ref<boolean>;
  refreshing: Ref<boolean>;
  error: Ref<ServerError | null>;
  isScrollAtBottom: ComputedRef<boolean>;
};

export const useInfinityScroll = <D = any, P = any>(callback: (payload: P) => Promise<BaseRecords<D>>, options: Options<D, P>): InfinityScroll<D> => {
  const { payload, initial, items, reverse, disabled, disablePayloadWatcher, callbackSuccess, isHorizontal = computed(() => false) } = options;
  const request = useAsync<BaseRecords<any>>(callback, { errorsCleanTimeout: 10000 });
  const initState = initial;
  const initData = items?.length || initState?.data?.length ? [...(items || []), ...(initState?.data || [])] : null;
  const initPage = { index: 0, ...(initState?.page || {}), size: 10 };

  const debounceTimer = ref<ReturnType<typeof setTimeout> | undefined>();
  const data = ref<Array<any> | null>(initState?.data || null);
  const page = ref<BasePagination>(initPage);
  const hasMore = ref<boolean>(true);
  const loading = computed(() => request.loading.value);
  const refreshing = ref<boolean>(false);
  const error = ref<ServerError | null>(null);

  const elementRef = ref<HTMLElement | undefined>();
  const lastScrollTop = ref<number>(0);
  const touchStartY = ref<number>(0);

  const isScrollAtBottom = computed(() => {
    const { scrollSize = 0, scrollPosition = 0, scrollOffset = 0 } = getScrollParams(elementRef.value);

    return Math.floor(scrollPosition) === Math.floor(scrollSize - scrollOffset);
  });

  const onTouchStart = (ev: TouchEvent): void => {
    touchStartY.value = ev.touches[0].clientY;
  };

  const onTouchMove = (ev: TouchEvent): void => {
    const { scrollSize = 0, scrollPosition = 0 } = getScrollParams(elementRef.value);
    const touchDiff = ev.touches[0].clientY - touchStartY.value;

    refreshing.value = !reverse ? touchDiff > 0 && scrollPosition === 0 : touchDiff < 0 && scrollPosition === scrollSize;
  };

  const onTouchEnd = (): void => {
    if (!refreshing.value) {
      return;
    }

    page.value = {};
    data.value = null;
    error.value = null;

    (async () => {
      refreshing.value = true;
      try {
        await init(0);
      } finally {
        refreshing.value = false;
      }
    })();
  };

  const getScrollParams = (el?: HTMLElement): { scrollPosition: number; scrollSize: number; scrollOffset: number } => {
    return isHorizontal.value
      ? { scrollPosition: Math.abs(el?.scrollLeft || 0), scrollSize: el?.scrollWidth || 0, scrollOffset: el?.offsetWidth || 0 }
      : { scrollPosition: el?.scrollTop || 0, scrollSize: el?.scrollHeight || 0, scrollOffset: el?.offsetHeight || 0 };
  };

  const onScroll = async ({ target }: Event): Promise<void> => {
    if (!target || loading.value || disabled?.value) {
      return;
    }

    const { scrollSize = 0, scrollPosition = 0, scrollOffset = 0 } = getScrollParams(elementRef.value);

    lastScrollTop.value = scrollPosition;

    const triggerUp = scrollPosition < scrollOffset * 0.9;
    const triggerDown = scrollPosition > scrollSize - scrollOffset * 3;
    const trigger = reverse ? triggerUp : triggerDown;

    if (!trigger) {
      return;
    }

    if (!hasMore.value) {
      return;
    }

    page.value.index = (page.value.index || 0) + 1;
    await execute();
  };

  const execute = async (init?: boolean): Promise<void> => {
    try {
      elementRef.value?.removeEventListener('scroll', onScroll);
      const res = await request.call({ ...(payload?.value || {}), page: page.value } as P);
      const isFirstPage = !res?.page.index;
      hasMore.value = !!res?.hasMore;
      page.value = res?.page || initPage;
      error.value = request.error.value as ServerError | null;

      if (isFirstPage) {
        data.value = res?.data || items || null;
      } else if (res.data) {
        data.value = reverse ? [...(items || []), ...res.data, ...(data.value || [])] : [...(data.value || []), ...res.data];
      } else {
        data.value = items || null;
      }

      callbackSuccess?.({ data: data.value, page: page.value } as BaseRecords<any>);
    } catch (e: any) {
      error.value = e;
    } finally {
      if (hasMore.value) {
        elementRef.value?.addEventListener('scroll', onScroll);
      }

      refreshing.value = false;

      if (reverse) {
        setTimeout(() => {
          const { scrollSize = 0, scrollOffset = 0 } = getScrollParams(elementRef.value);
          elementRef.value?.scrollTo({ top: init ? scrollSize + scrollOffset : scrollOffset });
        });
      }
    }
  };

  const init = async (initIndex = 0): Promise<void> => {
    setElementStyle();

    data.value = initData;
    page.value = { index: initIndex, ...(initPage?.size ? { size: initPage?.size } : {}) };
    await execute(true);

    if (page.value.index === undefined || page.value.total === undefined) {
      return;
    }

    try {
      while (getScrollParams(elementRef.value)?.scrollSize <= getScrollParams(elementRef.value)?.scrollOffset * 3 && hasMore.value) {
        page.value.index = page.value.index + 1;

        await execute(true);
      }
    } finally {
      elementRef.value?.addEventListener('touchstart', onTouchStart);
      elementRef.value?.addEventListener('touchmove', onTouchMove);
      elementRef.value?.addEventListener('touchend', onTouchEnd);
    }
  };

  const reset = (): void => {
    elementRef.value?.removeEventListener('touchstart', onTouchStart);
    elementRef.value?.removeEventListener('touchmove', onTouchMove);
    elementRef.value?.removeEventListener('touchend', onTouchEnd);

    page.value = {};
    data.value = null;
    error.value = null;

    (async () => await init(0))();
  };

  const push = (item: Record<string, any>, config: { idKey: string; forceEndScroll?: boolean }): void => {
    const { idKey, forceEndScroll } = config;

    if (!item[idKey]) {
      return;
    }

    const exists = data.value?.find((itemState) => itemState[idKey] === item[idKey]);

    if (exists) {
      data.value = data.value?.map((value) => (value[idKey] === item[idKey] ? item : value)) || [item];

      return;
    }

    const { scrollSize = 0, scrollPosition = 0, scrollOffset = 0 } = getScrollParams(elementRef.value);
    const scrollEnd = reverse ? Math.floor(scrollPosition) === Math.floor(scrollSize - scrollOffset) : scrollPosition === 0;

    const message = reverse ? [...(data.value || []), item] : [item, ...(data.value || [])];

    if ((page.value && (page.value.index || 0) + 1 === page.value.total) || message.length <= 1) {
      data.value = message;
    } else {
      data.value = reverse ? message.slice(1) : message.slice(0, -1);
    }

    if (scrollEnd || forceEndScroll) {
      setTimeout(() => elementRef.value?.scrollTo({ top: reverse ? scrollSize + scrollOffset * 2 : 0, behavior: 'smooth' }), 0);
    }
  };

  const update = (item: Record<string, any> | undefined, idKey: string): void => {
    if (!item?.[idKey]) {
      return;
    }

    data.value = data.value?.map((state) => (state[idKey] === item[idKey] ? { ...state, ...item } : state)) || null;
  };

  const remove = (id: any, idKey: string): void => {
    data.value = data.value?.filter((state) => state[idKey] !== id) || null;
  };

  const scrollTo = (to: 'END' | 'START' = 'END'): void => {
    setTimeout(() => {
      const { scrollSize = 0 } = getScrollParams(elementRef.value);
      const scrollParam = to === 'END' ? { top: reverse ? scrollSize : 0 } : { top: reverse ? 0 : scrollSize };

      elementRef.value?.scrollTo(scrollParam);
    }, 0);
  };

  const setElementStyle = () => {
    if (!elementRef.value) {
      return;
    }

    if (isHorizontal.value) {
      elementRef.value.style.overflowX = 'auto';
    } else {
      elementRef.value.style.overflowY = 'auto';
    }
  };

  watch(
    () => elementRef.value,
    (newElement, oldElement) => {
      if (oldElement || !newElement) {
        return;
      }

      init();
    },
    { immediate: true }
  );

  watch(() => isHorizontal.value, setElementStyle, { immediate: true });

  if (!disablePayloadWatcher) {
    watch(
      () => payload?.value,
      () => {
        clearTimeout(debounceTimer.value);

        debounceTimer.value = setTimeout(async () => await init(), 500);
      },
      { deep: true }
    );
  }

  return {
    reset,
    push,
    update,
    remove,
    scrollTo,
    elementRef,
    loading,
    error,
    page,
    refreshing,
    data: computed((): Array<D> => (data.value || []) as Array<D>),
    isScrollAtBottom,
  };
};
