import { computed, type ComputedRef, type Ref } from 'vue';
import { numberWithCommas } from '@shared/helpers/number-with-commas';
import { CURRENCIES } from '@/store/client/client.constants';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { storeToRefs } from 'pinia';
import { useGeneralStore } from '@/store/client';

export const useCurrencySymbol = (
  amount: ComputedRef<number | undefined> | Ref<number | undefined> | number,
  currencyCode?: ComputedRef<string | undefined> | Ref<string | undefined> | string,
  emptyValue = '-'
): ComputedRef<string> => {
  const amountValue = typeof amount === 'number' ? computed(() => amount) : amount;
  const currencyValue = typeof currencyCode === 'string' ? computed(() => currencyCode) : currencyCode;

  const { [CURRENCIES]: currencies } = storeToRefs(useGeneralStore());

  const currency = computed((): string | undefined => currencyValue?.value || useAuthUser().activeUser.value?.currency);

  return computed(() => {
    if (!amountValue.value) {
      return emptyValue;
    }

    if (!currency.value) {
      return numberWithCommas(amountValue.value);
    }

    const { symbol } = currencies.value?.find(({ code }) => code === currency.value) || {};
    const formattedAmount = Math.floor(amountValue.value * 100) / 100;

    return [numberWithCommas(formattedAmount), symbol].filter(Boolean).join('');
  });
};
