import type { ColorName } from '@shared/services/css-service/types';
import type { IconName } from '@shared/components/icon/icon.type';
import type { SizeUnit } from '@shared/types';
import { openPopup } from '@shared/components/popup/helpers/open-popup';
import { emitter } from '@/main';
import { locale } from '@shared/locale';

type Options = {
  title?: string;
  description: string | [string, Record<string, string | number | undefined>];
  iconName?: IconName;
  iconColor?: ColorName;
  iconSize?: SizeUnit;
  yesCallback?: () => Promise<unknown> | unknown;
  yesLabel?: string;
  yesColor?: ColorName;
  noCallback?: () => Promise<unknown> | unknown;
  noLabel?: string;
  noColor?: ColorName;
  throwOnCancel?: boolean;
};

export const openWarningPopup = async (options: Options): Promise<void> => {
  const { translate } = locale;

  const {
    title = 'WARNING.TITLE',
    description,
    iconName = 'svg:warning',
    iconColor = 'yellow-700',
    iconSize,
    yesCallback,
    yesLabel = 'GENERAL.YES',
    yesColor = 'yellow-900',
    noCallback,
    noLabel = 'GENERAL.NO',
    noColor,
    throwOnCancel = !options.noCallback,
  } = options || {};

  const descriptionText = Array.isArray(description) ? description[0] : description;
  const descriptionParams = Array.isArray(description) ? description[1] : {};

  return await openPopup({
    title,
    description: translate(descriptionText, descriptionParams),
    actions: [
      {
        label: noLabel,
        color: noColor,
        callback: async () => {
          emitter.emit('CLOSE_GLOBAL_MODAL');
          await noCallback?.();

          if (throwOnCancel) {
            throw new Error();
          }
        },
      },
      {
        label: yesLabel,
        color: yesColor,
        callback: async () => {
          await yesCallback?.();
          emitter.emit('CLOSE_GLOBAL_MODAL');
        },
      },
    ],
    ...(iconName ? { icon: { name: iconName, color: iconColor, size: iconSize } } : {}),
  });
};
