<template>
  <div
    :class="['vz-toggle-switch', { 'vz-toggle-switch--checked': vModel, 'vz-toggle-switch--disabled': disabled }]"
    :style="{ '--active-checkbox-color': `var(--color-${props.color})` }"
    @click.stop
  >
    <label class="d-flex gap-1 align-center text-ellipsis">
      <input type="checkbox" :value="vModel" @change.stop="onClick" />

      <div :class="['vz-toggle-switch__switch', { skeleton: loading }]" />

      <div v-if="switchLabel || $slots['label']" class="vz-toggle-switch__label ms-1">
        <slot name="label">
          <span>{{ $t(switchLabel) }}</span>
        </slot>
      </div>
    </label>

    <div v-if="description || $slots['description']" class="vz-toggle-switch__description">
      <p v-if="description" class="vz-toggle-switch__description-text font-size-14">{{ $t(description) }}</p>

      <slot name="description" :disabled="!vModel || disabled" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ColorsMap } from '@shared/services/css-service/types';
import { computed, type PropType } from 'vue';

const props = defineProps({
  modelValue: { type: Boolean as PropType<boolean | undefined>, required: true },
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  color: { type: String as PropType<ColorsMap>, default: 'primary-900' },
  description: { type: String as PropType<string | undefined>, default: undefined },
});

const emit = defineEmits(['update:model-value']);

const switchLabel = computed(() => {
  if (props.label) {
    return props.label;
  }

  return props.modelValue ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE';
});

const vModel = computed({
  get: (): boolean => !!props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onClick = (): void => {
  if (props.disabled || props.loading) {
    return;
  }

  emit('update:model-value', !props.modelValue);
};
</script>

<style lang="scss" scoped>
.vz-toggle-switch {
  --active-checkbox-color: var(--color-primary-900);
  border-radius: var(--border-radius-medium);

  &:has(.vz-toggle-switch__description) {
    position: relative;
    border: var(--border-regular);
    padding: 1rem;

    label {
      position: relative;
      z-index: 1;
    }

    .vz-toggle-switch__description {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--active-checkbox-color);
      opacity: 0.05;
      z-index: 0;
      transition: opacity 0.3s;
    }
  }

  input {
    display: none;
  }

  &__label,
  &__description {
    transition: opacity 0.3s;
    opacity: 0.7;
  }

  &__description {
    color: var(--color-mono-800);

    &-text {
      padding-inline-start: 42px;
    }
  }

  label {
    cursor: pointer;
    color: var(--color-primary-900);
  }

  &__switch {
    width: 36px;
    height: 20px;
    background-color: var(--color-mono-400);
    border-radius: 50px;
    position: relative;
    transition: background-color 0.3s;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      background-color: var(--color-mono-100);
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: transform 0.3s;
    }
  }

  &--checked {
    .vz-toggle-switch__label,
    .vz-toggle-switch__description {
      color: var(--color-primary-900);
      opacity: 1;
    }

    .vz-toggle-switch__switch {
      background-color: var(--active-checkbox-color);

      &::before {
        transform: translateX(15px);
      }
    }

    &:has(.vz-toggle-switch__description):after {
      opacity: 0.1;
    }
  }

  &--disabled {
    .vz-toggle-switch__label,
    .vz-toggle-switch__description {
      color: var(--color-disabled);
      opacity: 1;
    }

    cursor: not-allowed;
  }
}
</style>
