import type { GetNotificationReq, GetNotificationsRes } from '@/views/notifications/store/notification.types';
import type { GetCalendarEventsReq, GetCalendarEventsRes, GetUpcomingEventsReq, GetUpcomingEventsRes } from '@/views/calendar/types';
import type { SearchJobOffersReq, SearchJobOffersRes } from '@/views/job/types';
import type { BaseChartResponse } from '@shared/models';
import type { GetMyFinanceSummaryRes } from '@/views/job/views/manager/modules/finance/types';
import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import LobbyService from '@/views/lobby/service/lobby.service';
import { GET_CALENDAR_EVENTS, GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import { GET_JOB_OFFERS, GET_JOB_OFFERS_STATISTICS } from '@/views/lobby/store/lobby.constants';
import { watch } from 'vue';
import { GET_MY_FINANCE_SUMMARY } from '@/views/job/views/manager/modules/finance/store/job-finance.constants';
import { useRoute } from 'vue-router';
import { useAsync } from '@shared/composables';
import Modules from '@/router/constants/modules';

const lobbyService = container.resolve(LobbyService);

export const useLobbyStore = defineStore(StoreNamespace.LOBBY_MODULE, () => {
  const route = useRoute();
  const getMyFinanceSummary = useAsync(async (): Promise<GetMyFinanceSummaryRes> => await lobbyService[GET_MY_FINANCE_SUMMARY]());

  watch(
    () => route.name,
    () => {
      if (route.name !== Modules.HOME) {
        return;
      }

      getMyFinanceSummary.call();
    },
    { immediate: true }
  );

  return {
    // actions
    [GET_UPCOMING_EVENTS]: async (payload?: GetUpcomingEventsReq): Promise<GetUpcomingEventsRes> => await lobbyService[GET_UPCOMING_EVENTS](payload),
    [GET_CALENDAR_EVENTS]: async (payload: GetCalendarEventsReq): Promise<GetCalendarEventsRes> => await lobbyService[GET_CALENDAR_EVENTS](payload),
    [GET_NOTIFICATIONS]: async (payload: GetNotificationReq): Promise<GetNotificationsRes> => await lobbyService[GET_NOTIFICATIONS](payload),
    [GET_JOB_OFFERS]: async (payload: SearchJobOffersReq): Promise<SearchJobOffersRes> => await lobbyService[GET_JOB_OFFERS](payload),
    [GET_JOB_OFFERS_STATISTICS]: async (): Promise<BaseChartResponse<{ amount: number; type: string }>> =>
      await lobbyService[GET_JOB_OFFERS_STATISTICS](),
    [GET_MY_FINANCE_SUMMARY]: getMyFinanceSummary,
  };
});
