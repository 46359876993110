<template>
  <audio-player ref="audioPlayerRef" :src="audioSource" :loading="getAttachmentRequest.loading.value" @end="$emit('end')" />
</template>

<script setup lang="ts">
import type { GetAttachmentRes } from '@/store/client/models';
import { GET_ATTACHMENT } from '@/store/client/client.constants';
import { computed, onMounted, type PropType, ref } from 'vue';
import { useAsync } from '@shared/composables';
import { useGeneralStore } from '@/store/client';

const props = defineProps({
  hid: { type: String, required: true },
  provider: { type: Function as PropType<((id: string) => Promise<any>) | undefined>, default: undefined },
});

defineEmits(['end']);

const { [GET_ATTACHMENT]: getAttachment } = useGeneralStore();
const getAttachmentRequest = useAsync<GetAttachmentRes>((props.provider || getAttachment) as ({ id }: { id: string }) => Promise<GetAttachmentRes>);

const audioPlayerRef = ref();
const audioSource = computed(() => getAttachmentRequest.results.value?.data);

onMounted(() => {
  getAttachmentRequest.call({ id: props.hid });
});

defineExpose({ play: () => audioPlayerRef.value?.play() });
</script>
