<template>
  <button
    v-tooltip="tooltip"
    :class="[
      'vz-button',
      `vz-button--${type}`,
      { [`vz-button--${type}--background`]: backgroundColor, 'vz-button--action': callToAction, skeleton: loading },
    ]"
    role="button"
    :style="style"
    :disabled="disabled || loading"
    :aria-label="t('COMPONENT_LABELS.BUTTON', { value: ariaLabel || text || '' })"
    @click="onClick"
  >
    <div class="d-flex align-center justify-center">
      <vz-spinner v-if="loading" class="vz-button__loader" :aria-label="ariaLabel || text" />

      <div :class="['vz-button__content', { 'vz-button__content--uppercase': uppercase, 'vz-button__content--loading': loading }]">
        <vz-icon v-if="iconName" :clickable="!disabled" :name="iconName" :color="iconColor" :size="iconSize" :type="iconType" />

        <slot>
          <span
            v-if="text"
            :class="[
              'vz-button__content-text text-ellipsis',
              { 'vz-button__content-text--minimizable': minimizable, 'hide-on-mobile': hideTextOnMobile },
            ]"
          >
            {{ $t(text) }}
          </span>
        </slot>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import type { IconName, IconType } from '@/shared/components/icon/icon.type';
import type { SizeUnit } from '@shared/types';
import type { ColorsMap } from '@/shared/services/css-service/types/colors';
import { computed, type PropType } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';

const props = defineProps({
  tooltip: { type: String, default: '' },
  hideTextOnMobile: { type: Boolean, default: false },
  callToAction: { type: Boolean, default: false },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: 'currentColor' },
  backgroundColor: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  type: { type: String as PropType<'flat' | 'rounded' | 'solid'>, default: 'solid' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  minimizable: { type: Boolean, default: false },
  ariaLabel: { type: String, default: '' },
  text: { type: String, default: '' },
  iconColor: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  iconName: { type: String as PropType<IconName | undefined>, default: undefined },
  iconSize: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  iconType: { type: String as PropType<IconType>, default: 'solid' },
  uppercase: { type: Boolean, default: true },
  width: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  showTooltip: { type: Boolean, default: true },
});

const emit = defineEmits(['click']);

const t = useTranslator();

const tooltip = computed(() => {
  return !props.disabled && props.showTooltip && !props.text ? t(props.tooltip || props.ariaLabel) : null;
});

const style = computed(() => ({
  ...(props.backgroundColor ? { backgroundColor: getThemeColor(props.backgroundColor) } : {}),
  ...(props.color ? { color: getThemeColor(props.color) } : {}),
  ...(props.width ? { minWidth: props.width } : {}),
}));

const onClick = (ev: Event): void => {
  if (!props.disabled && !props.loading) {
    emit('click', ev);
  }
};
</script>

<style lang="scss">
.vz-button {
  @keyframes backgroundFlicker {
    0% {
      opacity: 0.05;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.05;
    }
  }

  @keyframes borderFlicker {
    0% {
      opacity: 1;
      border-width: 1px;
    }
    50% {
      opacity: 0.7;
      border-width: 3px;
    }
    100% {
      opacity: 1;
      border-width: 1px;
    }
  }

  position: relative;
  border-radius: var(--border-radius-regular);
  width: fit-content;
  height: fit-content;
  font-weight: 600;
  cursor: pointer;

  &--action {
    @include after {
      animation: borderFlicker 1.5s infinite;
    }

    @include before {
      animation: backgroundFlicker 1.5s infinite;
    }
  }

  &__loader {
    left: 50%;
    transform: translateX(-50%);
    position: absolute !important;
    height: 100% !important;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    &--loading {
      opacity: 0;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &-text {
      &--minimizable {
        @include max-sm-layout {
          display: none;
        }
      }
    }

    @include sm-layout {
      overflow: hidden;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span.vz-button__content-text--minimizable {
        display: none;
      }
    }
  }

  &--solid {
    position: relative;
    transition: background-color 0.3s;
    padding: 0.25rem 0.5rem;
    box-shadow: var(--shadow-light);

    &:not(&--background) {
      @include before {
        border-radius: var(--border-radius-regular);
        background-color: currentColor;
        opacity: 0.1;
        transition: opacity 0.3s;
      }

      @include after {
        transition:
          opacity 0.3s,
          border-width 0.3s;

        border-radius: var(--border-radius-regular);
        border: 1px solid currentColor;
      }
    }

    &:hover:not(:disabled) {
      background-color: transparent;

      &:before {
        opacity: 0.2;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &--rounded {
    border-radius: 50%;
    transition: background-color 0.3s;
    overflow: hidden;
    padding: 0.5rem;
    background-color: var(--color-background-regular);
    box-shadow: var(--shadow-light);

    * {
      margin: 0;
    }
  }

  &--flat {
    padding: 0.25rem 0.5rem;
    height: max-content !important;
    background-color: transparent !important;

    &:hover:not(:disabled) {
      text-decoration: underline;
    }
  }

  &:disabled {
    cursor: initial;
    opacity: 0.5;

    .vz-button__content {
      &:not(&--loading) {
        opacity: 0.75 !important;
      }
    }
  }
}
</style>
