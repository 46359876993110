import type { AvailableLanguages } from '@/plugins/i18n/available-languages.enum';
import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';
import { isRtl } from '@/plugins/i18n/helpers/is-rtl';
import { locale } from '@shared/locale';

const { DEFAULT_LANGUAGE } = locale;

export const handleLanguageChange = (language: string = DEFAULT_LANGUAGE): void => {
  const defaultLanguage = (
    i18n.global.availableLocales.includes(language as AvailableLanguages) ? language : i18n.global.availableLocales?.[0]
  ) as AvailableLanguages;

  const code = defaultLanguage || DEFAULT_LANGUAGE;

  dayjs.locale(code);
  i18n.global.locale = code;

  document.documentElement.setAttribute('lang', code);
  document.documentElement.setAttribute('dir', isRtl(code) ? 'rtl' : 'ltr');
};
