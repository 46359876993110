import type {
  ProfileFullDetails,
  SearchEmployeesRes,
  GetReviewsReq,
  GetReviewRes,
  UpdateProfileReq,
  AddReviewReq,
  SearchEmployeesReq,
} from 'src/views/employee/types';
import type { BaseResponse, BaseRecords, BaseId, BaseImageResponse } from '@/shared/models';
import type ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';
import {
  ADD_EMPLOYEE_REVIEW,
  GET_EMPLOYEE_AVATAR,
  GET_EMPLOYEE_COVER,
  GET_EMPLOYEE_DETAILS,
  GET_EMPLOYEE_REVIEWS,
  SEARCH_EMPLOYEES,
  UPDATE_EMPLOYEE_PROFILE,
} from '@/views/employee/store/employee.constants';
import { ProfileTypeEnum } from '@/views/employee/constants';

@injectable()
export default class EmployeeService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = '/employee';

  public [GET_EMPLOYEE_AVATAR](hid: string, noCache?: boolean): Promise<BaseImageResponse> {
    return this.apiService.get<BaseImageResponse>(`${this.baseUrl}/${GET_EMPLOYEE_AVATAR}?hid=${hid}`, noCache ? { noCache: true } : undefined);
  }

  public [GET_EMPLOYEE_COVER](hid: string, noCache?: boolean): Promise<BaseImageResponse> {
    return this.apiService.get<BaseImageResponse>(`${this.baseUrl}/${GET_EMPLOYEE_COVER}?hid=${hid}`, noCache ? { noCache: true } : undefined);
  }

  public [SEARCH_EMPLOYEES](payload?: SearchEmployeesReq): Promise<SearchEmployeesRes> {
    return this.apiService.post<SearchEmployeesRes>(`${this.baseUrl}/${SEARCH_EMPLOYEES}`, payload, { onceAtSameTimeFlag: true });
  }

  public [GET_EMPLOYEE_DETAILS](userId: BaseId, type?: keyof typeof ProfileTypeEnum): Promise<ProfileFullDetails> {
    return this.apiService.get<ProfileFullDetails>(`${this.baseUrl}/${GET_EMPLOYEE_DETAILS}/${userId}?type=${type || ProfileTypeEnum.FULL}`);
  }

  public [GET_EMPLOYEE_REVIEWS](payload: GetReviewsReq): Promise<BaseRecords<GetReviewRes>> {
    return this.apiService.post<BaseRecords<GetReviewRes>>(`${this.baseUrl}/${GET_EMPLOYEE_REVIEWS}`, payload);
  }

  public [UPDATE_EMPLOYEE_PROFILE](payload: UpdateProfileReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${UPDATE_EMPLOYEE_PROFILE}`, payload);
  }

  public [ADD_EMPLOYEE_REVIEW](payload: AddReviewReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${ADD_EMPLOYEE_REVIEW}`, payload);
  }
}
