import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-flex gap-1 align-center text-ellipsis" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "vz-toggle-switch__label ms-1"
}
const _hoisted_4 = {
  key: 0,
  class: "vz-toggle-switch__description"
}
const _hoisted_5 = {
  key: 0,
  class: "vz-toggle-switch__description-text font-size-14"
}

import type { ColorsMap } from '@shared/services/css-service/types';
import { computed, type PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-toggle-switch',
  props: {
  modelValue: { type: Boolean as PropType<boolean | undefined>, required: true },
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  color: { type: String as PropType<ColorsMap>, default: 'primary-900' },
  description: { type: String as PropType<string | undefined>, default: undefined },
},
  emits: ['update:model-value'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const switchLabel = computed(() => {
  if (props.label) {
    return props.label;
  }

  return props.modelValue ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE';
});

const vModel = computed({
  get: (): boolean => !!props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onClick = (): void => {
  if (props.disabled || props.loading) {
    return;
  }

  emit('update:model-value', !props.modelValue);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-toggle-switch', { 'vz-toggle-switch--checked': vModel.value, 'vz-toggle-switch--disabled': __props.disabled }]),
    style: _normalizeStyle({ '--active-checkbox-color': `var(--color-${props.color})` }),
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("input", {
        type: "checkbox",
        value: vModel.value,
        onChange: _withModifiers(onClick, ["stop"])
      }, null, 40, _hoisted_2),
      _createElementVNode("div", {
        class: _normalizeClass(['vz-toggle-switch__switch', { skeleton: __props.loading }])
      }, null, 2),
      (switchLabel.value || _ctx.$slots['label'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(switchLabel.value)), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (__props.description || _ctx.$slots['description'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (__props.description)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t(__props.description)), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "description", {
            disabled: !vModel.value || __props.disabled
          })
        ]))
      : _createCommentVNode("", true)
  ], 6))
}
}

})