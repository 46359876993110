import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pb-2"
}
const _hoisted_2 = { class: "d-flex align-end gap-2 me-2" }
const _hoisted_3 = {
  ref: "tableContainerRef",
  class: "vz-data-table__table-container"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "d-flex align-center justify-center"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "vz-data-table__menu-action"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["colspan"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "vz-data-table__item-options"
}
const _hoisted_13 = {
  key: 0,
  class: "border-top-medium"
}
const _hoisted_14 = {
  key: 1,
  flat: "",
  class: "vz-data-table vz-data-table--no-data text-title-1"
}

import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';
import type { BasePagination, BaseRecords } from '@shared/models';
import type { TableGroups, TableHeader } from '@shared/components/tables/models';
import type { MenuItem } from '@shared/components/menus/models/menu-item';
import { computed, defineAsyncComponent, nextTick, onMounted, type PropType, ref, useSlots } from 'vue';
import { get } from 'lodash';
import { useAsync, useServerErrorsMapper } from '@/shared/composables';
import { LayoutEnum } from '@shared/directives/layout.enum';
import { TableItemEnum } from '@shared/components/tables/constants/data-table.enum';
import VzButton from '@shared/components/buttons/vz-button.vue';
import type { IconName } from '@shared/components/icon/icon.type';
import { ColorsMap } from '@shared/services/css-service/types';
import type { SizeUnit } from '@shared/types';

type TableSort = Record<string, { type: -1 | 1; fields: Array<string> }>;

export default /*@__PURE__*/_defineComponent({
  __name: 'vz-data-table',
  props: {
  showActionForOneItem: { type: Boolean, default: true },
  hideTableHeaders: { type: Boolean, default: false },
  hidePagination: { type: Boolean, default: false },
  autoFetch: { type: Boolean, default: true },
  flat: { type: Boolean, default: false },
  readable: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  headers: { type: Array as PropType<Array<TableHeader>>, required: true },
  items: { type: Array as PropType<Array<{ [key: string]: any }>>, required: true },
  page: { type: Number, default: 0 },
  sort: { type: Object as PropType<BasePagination['sort']>, default: () => ({}) },
  totalItems: { type: Number, default: 0 },
  loading: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  rowClickable: { type: [Boolean, Function] as PropType<boolean | ((item: any) => boolean)>, default: true },
  idKey: { type: String, default: '' },
  hideEmptyState: { type: Boolean, default: false },
  noDataText: { type: String, default: '' },
  noDataImage: { type: String as PropType<SplashName>, default: 'search-for-results' },
  addItemLabel: { type: String, default: 'GENERAL.ADD' },
  noResultsImage: { type: String as PropType<SplashName>, default: 'no-results' },
  isReadonlyCallback: { type: Function as PropType<(item: any, type?: keyof typeof TableItemEnum) => boolean>, default: () => false },
  hideSearchControls: { type: Boolean, default: false },
  // item actions
  updateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  deleteCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  duplicateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  // table actions
  tableActionButton: { type: Object as PropType<Partial<{ text: string; color: ColorsMap; iconName: IconName }> | undefined>, default: undefined },
  tableActions: { type: Array as PropType<Array<MenuItem> | undefined>, default: undefined },
  createCallback: { type: Function as PropType<(() => unknown | Promise<unknown>) | undefined>, default: undefined },
  exportCallback: {
    type: Function as PropType<((items: Array<{ [key: string]: any }>, headers?: Array<TableHeader>) => unknown | Promise<unknown>) | undefined>,
    default: undefined,
  },
  hasFilters: { type: Boolean, default: false },
  fetchCallback: { type: Function as PropType<((page?: BasePagination) => Promise<BaseRecords<any>>) | undefined>, default: undefined },
  groupBy: { type: Object as PropType<TableGroups<any>>, default: undefined },
  breakpoint: {
    type: String as PropType<LayoutEnum | null>,
    default: LayoutEnum.md,
    validator: (value: LayoutEnum | null) => value === null || Object.values(LayoutEnum).includes(value),
  },
},
  emits: ['search', 'clear', 'update:page', 'update:sort', 'select:item', 'update:item', 'duplicate:item', 'delete:item'],
  setup(__props, { emit: __emit }) {

const EmptyState = defineAsyncComponent(() => import(/* webpackChunkName: "empty-state" */ '@/components/empty-state.vue'));

const props = __props;

const emit = __emit;
const { call: internalRequest, loading: internalLoading, error: internalErrors, results: internalResults } = useAsync(props.fetchCallback);

const slots = useSlots();
const searchTriggered = ref<boolean>(false);

const tableHeaders = computed((): Array<TableHeader & { _hiddenClass: Array<string> }> => {
  const filterStaticHidden = props.headers?.filter(({ hidden }) => hidden !== true) || [];

  return filterStaticHidden.map(({ hidden, ...header }) => {
    const _hiddenClass = Array.isArray(hidden) ? hidden.map((value) => `hidden-${value}`) : [];

    return { ...header, _hiddenClass };
  });
});

const isActionEnabled = (item: any, type: keyof typeof TableItemEnum) => {
  const map = {
    [TableItemEnum.EDIT]: !!props.updateCallback,
    [TableItemEnum.DELETE]: !!props.deleteCallback,
    [TableItemEnum.DUPLICATE]: !!props.duplicateCallback,
  };

  return map[type] && !props.isReadonlyCallback(item, type);
};

const isItemOptionsEnabled = computed(() => !!(props.updateCallback || props.deleteCallback));

const menuActions = computed((): Array<MenuItem> => {
  const actions: Array<MenuItem> = [];

  if (props.createCallback) {
    actions.push({ text: 'GENERAL.ADD', icon: { name: 'svg:plus', size: '1.25rem' }, click: props.createCallback });
  }

  if (props.exportCallback) {
    actions.push({
      text: 'GENERAL.EXPORT',
      icon: { name: 'svg:file', size: '1.25rem', type: 'solid' },
      click: () => props.exportCallback?.(allItems.value, props.headers),
    });
  }

  return [...actions, ...(props.tableActions || [])];
});

const splashImage = computed((): SplashName => {
  if (allErrors.value?.errorMessage?.length) {
    return 'server-error';
  }

  return searchTriggered.value ? props.noResultsImage : props.noDataImage;
});

const defaultNoDataText = computed(() => {
  if (!slots['search-panel']) {
    return 'DATA.NO_DATA_AVAILABLE';
  }

  return searchTriggered.value || props.hasFilters ? 'DATA.NO_SEARCH_RESULTS' : 'DATA.SEARCH_FOR_RESULTS';
});

const allItems = computed(() => [...(props.items || []), ...(internalResults.value?.data || [])]);
const groupedItems = computed((): { [key: string]: BaseRecords<any>['data'] } => {
  if (props.groupBy) {
    return props.groupBy.groups.reduce((acc: { [key: string]: BaseRecords<any>['data'] }, { value, title }) => {
      const items = allItems.value.filter((item) => item[props.groupBy!.key] === value);

      return items.length ? { ...acc, [title as string]: items } : acc;
    }, {});
  }

  return { _: allItems.value };
});
const allErrors = computed(() => props.errors || internalErrors.value);
const isLoading = computed(() => props.loading || internalLoading.value);
const hasItems = computed(() => Object.values(groupedItems.value).some((items) => items.length));
const hasGroups = computed(() => !!Object.keys(groupedItems.value).filter((key) => key !== '_').length);

const serverErrors = useServerErrorsMapper(allErrors);
const internalPage = ref<number>(0);

const vPage = computed({
  get: (): number => (props.fetchCallback ? internalPage.value : props.page),
  set: (value: number) => {
    internalPage.value = value;
    internalRequest?.({ index: value });
    emit('update:page', value);
  },
});

const vSort = computed({
  get: (): TableSort => {
    return (props.headers || []).reduce((acc: TableSort, { sortable, value }) => {
      const sort = props.sort || {};

      if (Array.isArray(sortable)) {
        const arrSort = Object.entries(sort);

        const type = arrSort.find(([key]) => {
          return sortable.includes(key);
        })?.[1];

        return type ? { ...acc, [value]: { type: type, fields: sortable } } : acc;
      } else if (sortable === true) {
        return sort[value] ? { ...acc, [value]: { type: sort[value], fields: [value] } } : acc;
      }

      return acc;
    }, {});
  },
  set: (value) => {
    const sort = Object.values(value).reduce((acc: Record<string, -1 | 1>, { type, fields }) => {
      fields
        .filter((key) => !!key)
        .forEach((key) => {
          acc = { ...acc, [key]: type };
        });

      return acc;
    }, {});

    internalRequest?.({ sort });
    emit('update:sort', sort);
  },
});

const onSort = (key: string, sortable?: boolean | Array<string>) => {
  if (!sortable) {
    return;
  }

  if (!vSort.value[key]?.type) {
    vSort.value = { [key]: { type: 1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else if (vSort.value[key]?.type === 1) {
    vSort.value = { [key]: { type: -1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else {
    vSort.value = {};
  }
};

const isClickable = (item: any) => {
  return !isLoading.value && ((props.rowClickable instanceof Function && props.rowClickable(item)) || props.rowClickable === true);
};

const onSelect = (item: any) => {
  if (isLoading.value || !isClickable(item)) {
    return;
  }

  emit('select:item', props.idKey ? item[props.idKey] : item);
};

const onSearch = async (): Promise<void> => {
  await internalRequest?.();
  searchTriggered.value = true;
  emit('search');
};

const onClear = async (): Promise<void> => {
  searchTriggered.value = false;
  emit('clear');

  nextTick(internalRequest);
};

const onEdit = async (item: any): Promise<void> => {
  await props.updateCallback?.(item);
  emit('update:item', item);
};

const onDuplicate = async (item: any): Promise<void> => {
  await props.duplicateCallback?.(item);
  emit('duplicate:item', item);
};

const onDelete = async (item: any): Promise<void> => {
  await props.deleteCallback?.(item);
  emit('delete:item', item);
};

onMounted(() => {
  if (props.autoFetch && !groupedItems.value.length) {
    onSearch();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_vz_search_panel = _resolveComponent("vz-search-panel")!
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_popover_menu = _resolveComponent("vz-popover-menu")!
  const _component_vz_tab_switcher = _resolveComponent("vz-tab-switcher")!
  const _component_vz_split = _resolveComponent("vz-split")!
  const _directive_layout = _resolveDirective("layout")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-data-table', { 'vz-data-table--loading': isLoading.value }])
  }, [
    (_ctx.$slots['search-panel'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_vz_search_panel, {
            "hide-controls": __props.hideSearchControls,
            onClear: onClear,
            onSearch: onSearch
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "search-panel", { errors: _unref(serverErrors) })
            ]),
            _: 3
          }, 8, ["hide-controls"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots['table-options'] || _ctx.$slots['prepend'])
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['d-flex', _ctx.$slots['prepend'] ? 'justify-space-between flex-wrap' : 'justify-end', 'mb-2'])
        }, [
          _renderSlot(_ctx.$slots, "prepend"),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "table-options")
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_vz_split, {
      class: _normalizeClass({ 'border-top-medium': _ctx.$slots['side-panel'] }),
      "min-size": "50",
      "max-size": "70",
      "initial-size": "70",
      "breakpoint-slot": "1"
    }, _createSlots({
      "1": _withCtx(() => [
        (hasItems.value || isLoading.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("table", null, [
                  (!__props.hideTableHeaders)
                    ? (_openBlock(), _createElementBlock("thead", _hoisted_4, [
                        _createElementVNode("tr", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableHeaders.value, ({ _hiddenClass, ...header }, index) => {
                            return (_openBlock(), _createElementBlock("td", {
                              key: index,
                              class: _normalizeClass([`vz-data-table__data-${header.value}`, ..._hiddenClass])
                            }, [
                              _renderSlot(_ctx.$slots, `header-${header.value}`, { header: header }, () => [
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                          'text-ellipsis',
                          {
                            clickable: header.sortable,
                            'd-flex gap-1': header.sortable,
                            'text-center justify-center': header.center,
                            'ps-4': header.center && header.sortable,
                          },
                        ]),
                                  onClick: ($event: any) => (onSort(header.value, header.sortable))
                                }, [
                                  _renderSlot(_ctx.$slots, `label-${header.value}`, {}, () => [
                                    _createTextVNode(_toDisplayString(_ctx.$t(header.title)), 1)
                                  ]),
                                  (vSort.value[header.value] || header.sortable)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                        (vSort.value[header.value])
                                          ? (_openBlock(), _createBlock(_component_vz_icon, {
                                              key: 0,
                                              name: `svg:sort-${vSort.value[header.value].type === 1 ? 'up' : 'down'}`
                                            }, null, 8, ["name"]))
                                          : (header.sortable)
                                            ? (_openBlock(), _createBlock(_component_vz_icon, {
                                                key: 1,
                                                name: "svg:sort",
                                                "fill-opacity": "0.1"
                                              }))
                                            : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 10, _hoisted_5)
                              ])
                            ], 2))
                          }), 128)),
                          (isItemOptionsEnabled.value || _ctx.$slots['item-options'])
                            ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                                _renderSlot(_ctx.$slots, "item-options-header", {}, () => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('GENERAL.ACTIONS')), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          (menuActions.value.length)
                            ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                                _createVNode(_component_vz_popover_menu, {
                                  "show-action-for-one-item": __props.showActionForOneItem,
                                  "icon-type": "regular",
                                  "icon-name": "svg:vdots",
                                  items: menuActions.value
                                }, null, 8, ["show-action-for-one-item", "items"])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupedItems.value, (groupItems, groupTitle) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: groupTitle }, [
                        (hasGroups.value)
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                              _createElementVNode("td", {
                                class: "vz-data-table__table-group",
                                colspan: tableHeaders.value.length + 2
                              }, [
                                _createElementVNode("div", null, _toDisplayString(groupTitle), 1)
                              ], 8, _hoisted_10)
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupItems, ({ ...item }, itemIndex) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: itemIndex,
                            class: _normalizeClass({ 'vz-data-table__table-container--row-clickable': isClickable(item) && __props.readable }),
                            onClick: ($event: any) => (__props.readable ? onSelect(item) : undefined)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableHeaders.value, ({ _hiddenClass, style, ...header }, columIndex) => {
                              return (_openBlock(), _createElementBlock("td", {
                                key: columIndex,
                                class: _normalizeClass([`vz-data-table__data-${header.value} relative`, ..._hiddenClass]),
                                style: _normalizeStyle(style)
                              }, [
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                          'vz-data-table__cell d-flex fill-height',
                          { 'text-center justify-center': header.center },
                          ...(Array.isArray(header.class) ? header.class : [header.class]),
                        ])
                                }, [
                                  _renderSlot(_ctx.$slots, header.value, {
                                    item: item,
                                    itemIndex: itemIndex,
                                    colIndex: columIndex
                                  }, () => [
                                    _createTextVNode(_toDisplayString(_unref(get)(item, header.value, null)), 1)
                                  ])
                                ], 2)
                              ], 6))
                            }), 128)),
                            (isItemOptionsEnabled.value || _ctx.$slots['item-options'])
                              ? (_openBlock(), _createElementBlock("td", _hoisted_12, [
                                  _createElementVNode("div", {
                                    class: "d-flex gap-2 align-center",
                                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                                  }, [
                                    _renderSlot(_ctx.$slots, "item-options", {
                                      item: item,
                                      itemIndex: itemIndex
                                    }),
                                    (__props.updateCallback)
                                      ? (_openBlock(), _createBlock(VzButton, {
                                          key: 0,
                                          "icon-name": "svg:edit",
                                          "icon-type": "regular",
                                          "aria-label": "GENERAL.EDIT",
                                          disabled: __props.disabled || !isActionEnabled(item, _unref(TableItemEnum).EDIT),
                                          onClick: ($event: any) => (onEdit(item))
                                        }, null, 8, ["disabled", "onClick"]))
                                      : _createCommentVNode("", true),
                                    (__props.duplicateCallback)
                                      ? (_openBlock(), _createBlock(VzButton, {
                                          key: 1,
                                          "icon-name": "svg:copy",
                                          "icon-type": "regular",
                                          "aria-label": "GENERAL.DUPLICATE",
                                          disabled: __props.disabled || !isActionEnabled(item, _unref(TableItemEnum).DUPLICATE),
                                          onClick: ($event: any) => (onDuplicate(item))
                                        }, null, 8, ["disabled", "onClick"]))
                                      : _createCommentVNode("", true),
                                    (__props.deleteCallback)
                                      ? (_openBlock(), _createBlock(VzButton, {
                                          key: 2,
                                          color: "red-900",
                                          "icon-name": "svg:trash",
                                          "icon-type": "regular",
                                          "aria-label": "GENERAL.DELETE",
                                          disabled: __props.disabled || !isActionEnabled(item, _unref(TableItemEnum).DELETE),
                                          onClick: ($event: any) => (onDelete(item))
                                        }, null, 8, ["disabled", "onClick"]))
                                      : _createCommentVNode("", true)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ], 10, _hoisted_11))
                        }), 128))
                      ], 64))
                    }), 128))
                  ])
                ])
              ])), [
                [_directive_layout]
              ]),
              (!__props.hidePagination && __props.totalItems > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_vz_tab_switcher, {
                      "tab-index": vPage.value,
                      "onUpdate:tabIndex": _cache[1] || (_cache[1] = ($event: any) => ((vPage).value = $event)),
                      class: "vz-data-table__pagination",
                      flat: "",
                      center: "",
                      tabs: __props.totalItems || 0
                    }, null, 8, ["tab-index", "tabs"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (!isLoading.value && !__props.hideEmptyState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _renderSlot(_ctx.$slots, "no-data", { errors: allErrors.value }, () => [
                  _createVNode(_unref(EmptyState), {
                    class: "bg-light ma-0",
                    errors: allErrors.value,
                    "no-data-text": __props.noDataText || defaultNoDataText.value,
                    "no-data-image": splashImage.value
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "no-data-actions", {}, () => [
                        (__props.createCallback)
                          ? (_openBlock(), _createBlock(VzButton, {
                              key: 0,
                              class: "mt-2 px-4",
                              "icon-name": "svg:plus",
                              "icon-size": "1.125rem",
                              text: __props.addItemLabel,
                              onClick: __props.createCallback
                            }, null, 8, ["text", "onClick"]))
                          : (menuActions.value.length)
                            ? (_openBlock(), _createBlock(_component_vz_popover_menu, {
                                key: 1,
                                items: menuActions.value
                              }, {
                                activator: _withCtx(() => [
                                  _createVNode(VzButton, _mergeProps({
                                    class: "px-4",
                                    "call-to-action": "",
                                    "icon-name": "svg:vdots",
                                    text: "GENERAL.ACTIONS"
                                  }, __props.tableActionButton), null, 16)
                                ]),
                                _: 1
                              }, 8, ["items"]))
                            : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 3
                  }, 8, ["errors", "no-data-text", "no-data-image"])
                ])
              ]))
            : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.$slots['side-panel'])
        ? {
            name: "2",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "side-panel")
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["class"])
  ], 2))
}
}

})