<template>
  <div class="vz-json-viewer">
    <label v-if="label" class="text-ellipsis">{{ $t(label) }}</label>

    <vz-tab-switcher fit flat :tabs="tabs" @update:name="activeKey = $event">
      <template #append>
        <vz-button v-tooltip="$t('GENERAL.COPY')" type="flat" icon-name="svg:copy" @click="copyToClipboard" />
      </template>
    </vz-tab-switcher>

    <json-viewer :json-data="json" :dark="false" />
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from 'vue';

const props = defineProps({
  autoTab: { type: Boolean, default: false },
  label: { type: String, default: '' },
  value: { type: Object as PropType<{ [key: string]: any } | undefined>, default: undefined },
});

const activeKey = ref<string | null>(props.autoTab ? Object.keys(props.value || {})[0] : null);

const json = computed(() => {
  const content = activeKey.value ? props.value?.[activeKey.value] : props.value;

  return JSON.stringify(content, null, 2);
});

const tabs = computed(() => {
  if (!props.autoTab) {
    return [];
  }

  return Object.keys(props.value || {}).map((key) => ({
    name: key,
    label: key,
  }));
});

const copyToClipboard = (): void => {
  navigator.clipboard.writeText(json.value);
};
</script>

<style lang="scss">
.vz-json-viewer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  > :last-child {
    margin-bottom: 36px;
  }

  pre {
    margin: 0;
  }

  .tab-switcher-navigation {
    direction: ltr !important;
  }
}
</style>
