import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { type PropType } from 'vue';
import '@shared/elements/pdf-viewer/pdf-viewer';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-pdf-viewer',
  props: {
  src: { type: String as PropType<string | null | undefined>, required: true },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_pdf_viewer = _resolveComponent("pdf-viewer")!

  return (__props.src)
    ? (_openBlock(), _createBlock(_component_pdf_viewer, {
        key: 0,
        class: "fill-width fill-height flex-grow-1",
        src: __props.src
      }, null, 8, ["src"]))
    : _createCommentVNode("", true)
}
}

})