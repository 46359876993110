import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = { class: "vz-textarea__container" }
const _hoisted_3 = ["placeholder", "disabled", "readonly", "rows", "aria-label"]

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorMessageRef } from '@shared/components';
import { computed, nextTick, type PropType, ref } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import { scrollToView } from '@shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-textarea',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: String as PropType<string | null | undefined>, required: true },
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  resizable: { type: Boolean, default: false },
  rows: { type: [Number, String], default: 5 },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: String as PropType<string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
  classLabel: { type: String, default: '' },
},
  emits: ['update:model-value', 'input'],
  setup(__props, { emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;

const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const inputRef = ref();
const errorMessageRef = ref<ErrorMessageRef>(undefined);

const vModel = computed({
  get: (): any => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onUpdate = (ev: Event): void => {
  const { value } = ev.target as HTMLInputElement;
  emit('update:model-value', value);
};

const onFocus = () => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  isFocus.value = true;
  nextTick(() => scrollToView(inputRef.value, { behavior: 'smooth', block: 'center' }));
};

const onBlur = () => (blurTimeout.value = setTimeout(() => (isFocus.value = false), 500));

return (_ctx: any,_cache: any) => {
  const _component_vz_error_message = _resolveComponent("vz-error-message")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "inputRef",
    ref: inputRef,
    class: _normalizeClass(["vz-textarea", { 'vz-textarea--loading': __props.loading, 'vz-textarea--disabled': __props.disabled }]),
    "data-errors": errorMessageRef.value?.errorMessage
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(['text-ellipsis', __props.classLabel])
    }, _toDisplayString(_ctx.$t(__props.label)), 3),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vModel).value = $event)),
        placeholder: _ctx.$t(__props.placeholder),
        disabled: __props.disabled,
        readonly: __props.readonly,
        rows: __props.rows,
        style: _normalizeStyle({ resize: !__props.resizable ? 'none' : 'vertical' }),
        "aria-label": _unref(t)('COMPONENT_LABELS.TEXTAREA', { value: __props.ariaLabel || __props.label || __props.placeholder }),
        onInput: onUpdate,
        onFocus: onFocus,
        onBlur: onBlur
      }, null, 44, _hoisted_3), [
        [_vModelText, vModel.value]
      ])
    ]),
    _createVNode(_component_vz_error_message, {
      ref_key: "errorMessageRef",
      ref: errorMessageRef,
      value: __props.modelValue,
      name: __props.name || __props.label,
      rules: __props.rules,
      errors: __props.errorMessage
    }, null, 8, ["value", "name", "rules", "errors"])
  ], 10, _hoisted_1))
}
}

})