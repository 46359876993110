import { type CSSProperties, type DirectiveBinding } from 'vue';

type Element = HTMLElement & { createTooltip: (event: MouseEvent) => void; removeTooltip: () => void };
type TooltipProps = { text: string; color: string; isAlwaysOn: boolean; style?: CSSProperties };
type TooltipBinding = DirectiveBinding<null | string | boolean | TooltipProps>;

const getFormattedText = (element: HTMLElement): string => {
  let text = '';

  const traverse = (node: Node): void => {
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.nodeValue?.trim() || '';
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const computedStyle = window.getComputedStyle(node as HTMLElement);
      const isBlock = computedStyle.display === 'block';

      if (isBlock && text.trim()) {
        text += '\n';
      } else {
        text += ' ';
      }

      node.childNodes.forEach(traverse);

      if (isBlock) {
        text += '\n';
      }
    }
  };

  traverse(element);

  return text.trim().replace(/\n\s*\n/g, '\n');
};

const applyTooltipStyle = (el: HTMLElement): void => {
  el.style.cursor = 'default';
};

const setTooltipProps = (event: MouseEvent, tooltipEl: HTMLElement, color: string, text?: string, style?: CSSProperties): void => {
  const targetEl = event.target as HTMLElement;

  const computedStyle = window.getComputedStyle(targetEl);
  const direction = computedStyle.getPropertyValue('direction');
  const isRtl = direction === 'rtl';

  // Set tooltip content
  tooltipEl.innerHTML = text || getFormattedText(targetEl);

  // Append tooltip first but keep it hidden to measure correctly
  tooltipEl.style.visibility = 'hidden';
  tooltipEl.style.position = 'absolute'; // Prevent flickering before positioning
  tooltipEl.style.top = '0px';
  tooltipEl.style.left = '0px';
  document.body.appendChild(tooltipEl);

  // Use requestAnimationFrame to delay positioning to the next frame
  requestAnimationFrame(() => {
    // Get bounding rectangles after rendering
    const targetRect = targetEl.getBoundingClientRect();
    const tooltipRect = tooltipEl.getBoundingClientRect();

    let top: number;
    let left: number;

    // Default positioning (Center tooltip below target)
    top = targetRect.bottom + 10;

    // left = targetRect.left + targetRect.width / 2 - tooltipRect.width / 2; // Center horizontally based on target
    left = event.clientX + (isRtl ? -(tooltipRect.width - 16) : 16); // Center horizontally based on cursor

    // Ensure tooltip does not overflow viewport
    left = Math.max(10, Math.min(left, window.innerWidth - tooltipRect.width - 10));
    top = Math.max(10, Math.min(top, window.innerHeight - tooltipRect.height - 10));

    // Apply final positioning
    tooltipEl.style.top = `${top}px`;
    tooltipEl.style.left = `${left}px`;
    tooltipEl.style.position = 'fixed';
    tooltipEl.style.visibility = 'visible'; // Now show the tooltip

    // Apply styles
    tooltipEl.style.zIndex = '1000000000';
    tooltipEl.style.backgroundColor = 'white';
    tooltipEl.style.boxShadow = '0 0 10px 0 rgba(186, 199, 220, 0.71)';
    tooltipEl.style.padding = '10px 15px';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.color = color;
    tooltipEl.style.whiteSpace = 'pre-wrap';
    tooltipEl.style.maxWidth = '300px';
    tooltipEl.style.wordWrap = 'break-word';
    tooltipEl.setAttribute('tooltip', tooltipEl.innerText);

    Object.entries(style || {}).forEach(([key, value]) => {
      tooltipEl.style[key as any] = value;
    });
  });
};

export const vueTooltip = {
  mounted: (el: Element, binding: TooltipBinding) => {
    const debounce = (func: (...args: any[]) => void, wait: number) => {
      let timeout: ReturnType<typeof setTimeout> | null;

      return function (...args: any[]) {
        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
          timeout = null;
          func(...args);
        }, wait);
      };
    };

    const hasHoverSupport = window.matchMedia('(hover: hover)').matches;

    if (!hasHoverSupport || binding.value === null) {
      return;
    }

    const { text, color, isAlwaysOn, style } = (() => {
      if (typeof binding.value === 'string') {
        return { text: binding.value, color: 'var(--color-primary-900)', isAlwaysOn: false, style: {} };
      } else if (typeof binding.value === 'boolean') {
        return { text: undefined, color: 'var(--color-primary-900)', isAlwaysOn: binding.value, style: {} };
      }

      return { ...binding.value };
    })();

    applyTooltipStyle(el);

    if (el?.offsetWidth < el?.scrollWidth || text || isAlwaysOn) {
      const tooltipEl = document.createElement('div');
      const body = document.querySelector('body');

      el.createTooltip = (event: MouseEvent): void => {
        document.querySelectorAll('[tooltip]').forEach((el) => el.remove());

        setTooltipProps(event, tooltipEl, color, text, style);

        body?.appendChild(tooltipEl);
      };

      el.removeTooltip = debounce((): void => {
        if (tooltipEl && body?.contains(tooltipEl)) {
          body.removeChild(tooltipEl);
        }
      }, 300);

      el.addEventListener('mouseenter', el.createTooltip);
      el.addEventListener('mouseleave', el.removeTooltip);
    }
  },
  unmounted: (el: Element) => {
    el.removeTooltip?.();
    el.removeEventListener('mouseenter', el.createTooltip);
    el.removeEventListener('mouseleave', el.removeTooltip);

    delete (el as Partial<Element>).createTooltip;
    delete (el as Partial<Element>).removeTooltip;
  },
};
