import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import type { GetNotificationReq } from '@/views/notifications/store/notification.types';
import { type PropType, ref } from 'vue';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import NotificationCard from '@/views/notifications/components/notification-card.vue';
import { useNotificationStore } from '@/views/notifications/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'notifications-grid',
  props: {
  disabled: { type: Boolean as PropType<boolean>, default: false },
  callback: { type: Function as PropType<((payload?: GetNotificationReq) => Promise<GetNotificationReq>) | undefined>, default: undefined },
  isHorizontal: { type: Boolean as PropType<boolean>, default: false },
},
  setup(__props) {

const props = __props;

const { [GET_NOTIFICATIONS]: listNotificationsAction } = useNotificationStore();

const listNotificationsRequest = (props.callback || listNotificationsAction) as (payload?: GetNotificationReq) => Promise<GetNotificationReq>;

const notificationPayload = ref<Omit<GetNotificationReq, 'page'>>({});

return (_ctx: any,_cache: any) => {
  const _component_vz_infinity_scroll = _resolveComponent("vz-infinity-scroll")!

  return (_openBlock(), _createBlock(_component_vz_infinity_scroll, {
    ref: "infinityScrollRef",
    "hide-first-load": "",
    "is-horizontal": __props.isHorizontal,
    disabled: __props.disabled,
    payload: notificationPayload.value,
    callback: _unref(listNotificationsRequest)
  }, {
    default: _withCtx(({ data }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (item, index) => {
        return (_openBlock(), _createBlock(NotificationCard, {
          key: index,
          item: item
        }, null, 8, ["item"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["is-horizontal", "disabled", "payload", "callback"]))
}
}

})