<template>
  <div v-if="!hasEmptyData">
    <canvas :id="chartId" ref="chartCanvas"></canvas>
  </div>

  <empty-state v-else />
</template>

<script setup lang="ts">
import type { ChartData, ChartDataset, ChartOptions } from 'chart.js/dist/types';
import { computed, onMounted, onUnmounted, type PropType, ref, watch } from 'vue';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Title,
  PieController,
  BarController,
  LineController,
  type ChartType,
} from 'chart.js';
import { uniqueKey } from '@/shared/helpers';
import EmptyState from '@/components/empty-state.vue';

type Data = Omit<ChartData, 'datasets'> & { datasets: Array<ChartDataset & { callback?: Array<(() => void) | undefined> }> };

ChartJS.register(
  PieController, // Pie chart support
  BarController, // Bar chart support
  LineController, // Line chart support
  ArcElement, // Needed for Pie Chart
  BarElement, // Needed for Bar Chart
  LineElement, // Needed for Line Chart
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Title
);

const props = defineProps({
  type: { type: String as PropType<ChartType>, default: 'bar' },
  chartId: { type: String, default: uniqueKey() },
  chartData: { type: Object as PropType<Data>, required: true },
  chartOptions: { type: Object as PropType<ChartOptions | undefined>, default: undefined },
  hideLegend: { type: Boolean, default: false },
});

const emit = defineEmits(['click:chart']);

const chartCanvas = ref<HTMLCanvasElement | null>(null);
let chartInstance: ChartJS | null = null;

const hasEmptyData = computed(() => {
  return props.chartData.datasets.every((dataset) => dataset.data.length === 0);
});

const executeCallback = (datasetIndex: number, elementIndex: number): boolean => {
  const callback = props.chartData.datasets[datasetIndex]?.callback?.[elementIndex];
  callback?.();

  return !!callback;
};

const createChart = () => {
  if (chartCanvas.value) {
    chartInstance = new ChartJS(chartCanvas.value, {
      type: props.type,
      data: props.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: props.hideLegend ? { display: false } : { display: true }, // Hide legend
        },
        ...(props.chartOptions || {}),
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const elementIndex = elements[0].index;
            const datasetIndex = elements[0].datasetIndex;
            const clickedData = props.chartData.datasets[datasetIndex].data[elementIndex];
            const clickedLabel = props.chartData.labels?.[elementIndex];

            if (!executeCallback(datasetIndex, elementIndex)) {
              emit('click:chart', { label: clickedLabel, value: clickedData, index: elementIndex });
            }
          }
        },
      },
    });
  }
};

onMounted(() => createChart());

watch(
  () => [props.chartData, props.hideLegend], // Watch both chartData and hideLegend
  () => {
    if (chartInstance) {
      chartInstance.destroy();
      createChart();
    }
  },
  { deep: true }
);

onUnmounted(() => {
  if (chartInstance) {
    chartInstance.destroy();
  }
});
</script>
