import type {
  AxiosGetRequestConfig,
  AxiosPostRequestConfig,
  AxiosRequestInterceptor,
  AxiosResponseInterceptor,
} from '@/shared/services/api-service/models';
import axios, { type AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios';
import { axiosConfig } from '@/shared/services/api-service/axios-config';

type Interceptors = Partial<{
  request: Array<AxiosRequestInterceptor>;
  response: Array<AxiosResponseInterceptor>;
}>;

export default class ApiService {
  public constructor(config: AxiosRequestConfig = axiosConfig, interceptors?: Interceptors) {
    this.axiosInstance = axios.create(config);

    if (interceptors?.request?.length) {
      this.addRequestInterceptor(interceptors.request);
    }

    if (interceptors?.response?.length) {
      this.addResponseInterceptor(interceptors.response);
    }
  }

  private requestControllers: Map<string, AbortController> = new Map();
  private readonly axiosInstance: AxiosInstance;

  public async get<Response>(url: string, config?: AxiosGetRequestConfig): Promise<Response> {
    const { noCache, onceAtSameTimeFlag, uniqueUrlFlag = true, ...restConfig } = config || {};

    const axiosConfig = {
      signal: this.getAbortSignal(uniqueUrlFlag ? url : url.split('?')[0], onceAtSameTimeFlag),
      ...restConfig,
      ...(noCache ? { headers: { 'Cache-Control': 'no-cache', ...(restConfig?.headers || {}) } } : {}),
    };

    return await this.makeRequest<Response>(() => this.axiosInstance.get<Response>(url, axiosConfig));
  }

  public async put<Response, Payload = any>(url: string, data: Payload, config?: AxiosRequestConfig): Promise<Response> {
    return await this.makeRequest<Response>(() => this.axiosInstance.put<Response>(url, data, config));
  }

  public async post<Response, Payload = any>(url: string, data?: Payload, config?: AxiosPostRequestConfig): Promise<Response> {
    const { onceAtSameTimeFlag, uniqueUrlFlag = false, ...restConfig } = config || {};

    const axiosConfig = {
      signal: this.getAbortSignal(uniqueUrlFlag ? [url, data] : url, onceAtSameTimeFlag),
      ...(data instanceof FormData ? { headers: { 'Content-Type': 'multipart/form-data' } } : {}),
      ...(restConfig || {}),
    };

    return await this.makeRequest<Response>(() => this.axiosInstance.post<Response>(url, data, axiosConfig));
  }

  public async patch<Response, Payload = any>(url: string, data: Payload, config?: AxiosRequestConfig): Promise<Response> {
    return await this.makeRequest<Response>(() => this.axiosInstance.patch<Response>(url, data, config));
  }

  public async delete<Response>(url: string, config?: AxiosRequestConfig): Promise<Response> {
    return await this.makeRequest<Response>(() => this.axiosInstance.delete<Response>(url, config));
  }

  public addRequestInterceptor(interceptor: AxiosRequestInterceptor | Array<AxiosRequestInterceptor>): void {
    (Array.isArray(interceptor) ? interceptor : [interceptor]).forEach((apiInterceptor) =>
      this.axiosInstance.interceptors.request.use(
        (value: InternalAxiosRequestConfig) => apiInterceptor.onFulfilled(value),
        (error: AxiosError) => apiInterceptor.onRejected(error)
      )
    );
  }

  public addResponseInterceptor(interceptor: AxiosResponseInterceptor | Array<AxiosResponseInterceptor>): void {
    (Array.isArray(interceptor) ? interceptor : [interceptor]).forEach((apiInterceptor) =>
      this.axiosInstance.interceptors.response.use(
        (value: AxiosResponse) => apiInterceptor.onFulfilled(value),
        (error: AxiosError) => apiInterceptor.onRejected(error)
      )
    );
  }

  private signalKey(...args: Array<any>): string {
    const jsonString = JSON.stringify(args);
    let hash = 0;

    for (let i = 0; i < jsonString.length; i++) {
      const charCode = jsonString.charCodeAt(i);
      hash = (hash << 5) - hash + charCode;
    }

    return (hash >>> 0).toString(16);
  }

  private signalCancel(key: string): void {
    const controller = this.requestControllers.get(key);

    if (controller) {
      controller.abort();
      this.requestControllers.delete(key);
    }
  }

  private getAbortSignal(value: any, allowOnceAtTime?: boolean): AbortSignal {
    const key = this.signalKey(value);

    if (allowOnceAtTime) {
      this.signalCancel(key);
    }

    const abortController = new AbortController();
    this.requestControllers.set(key, abortController);

    return abortController.signal;
  }

  private async makeRequest<T>(request: () => Promise<AxiosResponse<T>>, key?: string): Promise<T> {
    try {
      const response = await request();

      return response?.data;
    } finally {
      if (key) {
        this.requestControllers.delete(key);
      }
    }
  }
}
