<template>
  <div class="vz-popover">
    <div
      ref="popoverRef"
      :class="['vz-popover__activator', { 'vz-popover__activator--disabled': disabled }]"
      role="button"
      @mousedown="onPressStart"
      @mouseup="onPressEnd"
      @mouseleave="onPressCancel"
      @touchstart="onPressStart"
      @touchend="onPressEnd"
      @touchcancel="onPressCancel"
      @mouseover="onHover(true)"
      @mouseout="onHover(false)"
      @dblclick="$emit('dblclick')"
      @click="onOpen"
    >
      <slot name="activator" :open="onOpen">
        <vz-button
          class="py-0"
          type="flat"
          icon-size="1.5rem"
          aria-label="GENERAL.ACTIONS"
          :icon-name="iconName"
          :color="isActive ? 'primary-700' : 'primary-900'"
        />
      </slot>
    </div>

    <Teleport to="body">
      <div
        v-if="isActive && !disabled && ($slots['top'] || $slots['default'] || $slots['bottom'])"
        ref="popoverContent"
        class="vz-popover__content"
        :style="popoverStyle"
        @mouseover="onHover(true)"
        @mouseout="onHover(false)"
      >
        <slot :close="onClose" />
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type PropType, ref, onMounted, onBeforeUnmount, type StyleValue } from 'vue';
import { isRtl } from '@/plugins/i18n/helpers';
import { getLastZIndex } from '@shared/helpers';
import VzButton from '@shared/components/buttons/vz-button.vue';

const props = defineProps({
  hover: { type: Boolean, default: false },
  hold: { type: [Boolean, Number] as PropType<number | boolean>, default: false },
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
  closeTimeout: { type: Number, default: 0 },
  iconName: { type: String as PropType<IconName>, default: 'svg:hdots' },
});

const emit = defineEmits(['click', 'dblclick']);

const isActive = ref<boolean>(false);
const zIndex = ref<number>(getLastZIndex() + 100);
const popoverRef = ref<HTMLDivElement>();
const popoverContent = ref<HTMLDivElement>();
const pressTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const closeTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const lastTap = ref(0);
const debounceId = ref<number | undefined>(undefined);

const popoverStyle = computed((): StyleValue => {
  if (!popoverRef.value || !popoverContent.value) {
    return {};
  }

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const { left: mainLeft, top: mainTop, height: mainHeight, width: mainWidth } = popoverRef.value.getBoundingClientRect();
  const { width: contentWidth, height: contentHeight } = popoverContent.value.getBoundingClientRect();

  const isLtr = !isRtl();
  let targetLeft = isLtr ? mainLeft + +props.offsetX : mainLeft + (mainWidth - contentWidth) - +props.offsetX;
  let targetTop = mainTop + mainHeight + 4 + +props.offsetY;

  if (targetLeft + contentWidth > windowWidth) {
    targetLeft = windowWidth - contentWidth - 8;
  }
  if (targetLeft < 0) {
    targetLeft = 8;
  }
  if (targetTop + contentHeight > windowHeight) {
    targetTop = mainTop - contentHeight - 8;
  }
  if (targetTop < 0) {
    targetTop = 8;
  }

  return {
    top: `${targetTop}px`,
    left: `${targetLeft}px`,
    zIndex: zIndex.value,
    maxHeight: `${windowHeight - targetTop - 16}px`,
    position: 'fixed',
  };
});

const onHover = (isHovered: boolean) => {
  if (props.hover) {
    clearTimeout(debounceId.value);
    debounceId.value = setTimeout(() => {
      isActive.value = isHovered;
    }, 500);
  }
};

const close = () => {
  isActive.value = false;
  window.removeEventListener('click', onClose);
  window.removeEventListener('touchstart', onClose);
};

const onClose = (ev: Event) => {
  const target = ev?.target as Node | null | undefined;

  if (isActive.value && target instanceof Node && (popoverRef.value?.contains(target) || popoverContent.value?.contains(target))) {
    return;
  }

  close();
};

const onOpen = (ev?: Event): void => {
  if (props.disabled) {
    emit('click');
    return;
  }

  if (isActive.value) {
    close();

    return;
  }

  ev?.preventDefault();
  isActive.value = true;
  zIndex.value = getLastZIndex() + 100;

  window.addEventListener('click', onClose);
  window.addEventListener('touchstart', onClose);

  if (props.closeTimeout) {
    clearTimeout(closeTimer.value);
    closeTimer.value = setTimeout(close, props.closeTimeout);
  }
};

const onPressStart = (ev: Event) => {
  emit('click');

  const currentTime = new Date().getTime();
  const tapLength = currentTime - lastTap.value;

  if (tapLength < 300 && tapLength > 0) {
    emit('dblclick');
  }

  lastTap.value = currentTime;

  if (props.hold) {
    ev.preventDefault();
  }

  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }

  pressTimer.value = setTimeout(onOpen, typeof props.hold === 'number' ? props.hold : 500);
};

const onPressEnd = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

const onPressCancel = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

const onEscape = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    close();
  }
};

onMounted(() => {
  window.addEventListener('resize', onClose);
  window.addEventListener('keydown', onEscape);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onClose);
  window.removeEventListener('keydown', onEscape);
});

defineExpose({ close: onClose, open: onOpen });
</script>

<style scoped lang="scss">
.vz-popover {
  &__activator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(&--disabled) {
      cursor: pointer;
    }
  }

  &__content {
    border-radius: var(--border-radius-regular);
    padding: 0.25rem 0.5rem;
    top: 0;
    left: 0;
    position: fixed;
    background-color: var(--color-background-light);
    box-shadow: var(--shadow-light);
    overflow: hidden;
    min-width: 180px;
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
  }
}
</style>
