<template>
  <vz-infinity-scroll
    ref="infinityScrollRef"
    hide-first-load
    :is-horizontal="isHorizontal"
    :disabled="disabled"
    :payload="notificationPayload"
    :callback="listNotificationsRequest"
  >
    <template #default="{ data }">
      <notification-card v-for="(item, index) in data" :key="index" :item="item" />
    </template>
  </vz-infinity-scroll>
</template>

<script setup lang="ts">
import type { GetNotificationReq } from '@/views/notifications/store/notification.types';
import { type PropType, ref } from 'vue';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import NotificationCard from '@/views/notifications/components/notification-card.vue';
import { useNotificationStore } from '@/views/notifications/store';

const props = defineProps({
  disabled: { type: Boolean as PropType<boolean>, default: false },
  callback: { type: Function as PropType<((payload?: GetNotificationReq) => Promise<GetNotificationReq>) | undefined>, default: undefined },
  isHorizontal: { type: Boolean as PropType<boolean>, default: false },
});

const { [GET_NOTIFICATIONS]: listNotificationsAction } = useNotificationStore();

const listNotificationsRequest = (props.callback || listNotificationsAction) as (payload?: GetNotificationReq) => Promise<GetNotificationReq>;

const notificationPayload = ref<Omit<GetNotificationReq, 'page'>>({});
</script>

<style lang="scss">
.notifications-grid {
  @include min-md-layout {
    padding-inline-end: 16px;
  }

  overflow-y: auto;
}
</style>
