import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = {
  key: 0,
  class: "text-ellipsis mb-4"
}
const _hoisted_3 = { class: "vz-rich-text-container" }
const _hoisted_4 = ["innerHTML"]

import type { CustomValidator, ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorMessageRef } from '@shared/components';
import { computed, type PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { htmlToPdf } from '@shared/helpers';
import FileService from '@shared/services/file.service';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-rich-text',
  props: {
  readonly: { type: Boolean, default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: String as PropType<string | undefined>, required: true },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: String as PropType<string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  dynamicFields: { type: Array as PropType<Array<{ text: string; value: string }> | undefined>, default: undefined },
  direction: { type: String as PropType<'ltr' | 'rtl' | undefined>, default: undefined },
},
  emits: ['update:model-value', 'insert:dynamic-field'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { t } = useI18n();
const emit = __emit;

const errorMessageRef = ref<ErrorMessageRef>(undefined);

const dynamicFields = computed(() => {
  if (!props.dynamicFields) {
    return '';
  }

  const options = props.dynamicFields.map(({ text, value }) => ({ text: t(text), value }));

  return JSON.stringify(options);
});

const vModel = computed({
  get: (): string | undefined => props.modelValue || '',
  set: (value) => emit('update:model-value', value),
});

const onUpdate = (ev?: CustomEvent<{ htmlBody: string; direction?: string }>) => {
  if (ev?.detail?.htmlBody !== undefined) {
    vModel.value = ev.detail.htmlBody;
  }
};

const onPreview = async (): Promise<void> => {
  const blob = await htmlToPdf(vModel.value!);
  const url = URL.createObjectURL(blob);

  FileService.downloadLink(url, 'preview.pdf');
};

const requiredHtmlValidator: CustomValidator = (value: string) => {
  return [!!value?.replace(/<[^>]*>/g, '').length, 'VALIDATE.REQUIRED'];
};

const vRules = computed((): ValidatorFieldRules | undefined => {
  return props.rules
    ? { ...props.rules, ...(props.rules?.required?.[0] ? { custom: [requiredHtmlValidator, ...(props.rules.custom || [])] } : {}) }
    : undefined;
});

return (_ctx: any,_cache: any) => {
  const _component_rich_text = _resolveComponent("rich-text")!
  const _component_vz_error_message = _resolveComponent("vz-error-message")!

  return (_openBlock(), _createElementBlock("div", {
    class: "vz-rich-text",
    "data-errors": errorMessageRef.value?.errorMessage
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!__props.readonly)
        ? (_openBlock(), _createBlock(_component_rich_text, {
            key: 0,
            class: "fill-height flex-grow-1",
            value: vModel.value,
            "dynamic-fields": dynamicFields.value,
            undo: _ctx.$t('COMPONENT.RICH_TEXT.UNDO'),
            redo: _ctx.$t('COMPONENT.RICH_TEXT.REDO'),
            bold: _ctx.$t('COMPONENT.RICH_TEXT.BOLD'),
            italic: _ctx.$t('COMPONENT.RICH_TEXT.ITALIC'),
            underline: _ctx.$t('COMPONENT.RICH_TEXT.UNDERLINE'),
            "font-size": _ctx.$t('COMPONENT.RICH_TEXT.FONT_SIZE'),
            "format-block": _ctx.$t('COMPONENT.RICH_TEXT.STYLES'),
            "justify-left": _ctx.$t('COMPONENT.RICH_TEXT.ALIGN_LEFT'),
            "justify-center": _ctx.$t('COMPONENT.RICH_TEXT.ALIGN_CENTER'),
            "justify-right": _ctx.$t('COMPONENT.RICH_TEXT.ALIGN_RIGHT'),
            "insert-ordered-list": _ctx.$t('COMPONENT.RICH_TEXT.ORDERED_LIST'),
            "insert-unordered-list": _ctx.$t('COMPONENT.RICH_TEXT.UNORDERED_LIST'),
            indent: _ctx.$t('COMPONENT.RICH_TEXT.INDENT'),
            outdent: _ctx.$t('COMPONENT.RICH_TEXT.OUTDENT'),
            "remove-format": _ctx.$t('COMPONENT.RICH_TEXT.REMOVE_FORMAT'),
            "doc-direction": _ctx.$t('COMPONENT.RICH_TEXT.DOCUMENT_DIRECTION'),
            "full-screen": _ctx.$t('COMPONENT.RICH_TEXT.FULL_SCREEN'),
            preview: _ctx.$t('COMPONENT.RICH_TEXT.PREVIEW'),
            "insert-dynamic-field": _ctx.$t('COMPONENT.RICH_TEXT.INSERT_DYNAMIC_FIELD'),
            direction: __props.direction,
            onChange: onUpdate,
            onPreview: onPreview,
            onInsertDynamic: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('insert:dynamic-field')))
          }, null, 8, ["value", "dynamic-fields", "undo", "redo", "bold", "italic", "underline", "font-size", "format-block", "justify-left", "justify-center", "justify-right", "insert-ordered-list", "insert-unordered-list", "indent", "outdent", "remove-format", "doc-direction", "full-screen", "preview", "insert-dynamic-field", "direction"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "border-regular border-radius-regular flex-grow-1",
            innerHTML: vModel.value
          }, null, 8, _hoisted_4)),
      _createVNode(_component_vz_error_message, {
        ref_key: "errorMessageRef",
        ref: errorMessageRef,
        value: vModel.value,
        name: __props.name || __props.label,
        rules: vRules.value,
        errors: __props.errorMessage
      }, null, 8, ["value", "name", "rules", "errors"])
    ])
  ], 8, _hoisted_1))
}
}

})