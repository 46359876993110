import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex flex-column fill-height overflow-hidden" }
const _hoisted_2 = {
  key: 1,
  class: "split-screen__single-slot"
}

import { ref, onBeforeUnmount, computed, watch, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { LayoutEnum } from '@shared/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-split',
  props: {
  reverse: { type: Boolean, default: false },
  minSize: {
    type: [Number, String] as PropType<number | `${number}`>,
    default: 50,
    validator: (value: number | string) => +value >= 0 && +value <= 100,
  },
  maxSize: {
    type: [Number, String] as PropType<number | `${number}`>,
    default: 70,
    validator: (value: number | string) => +value >= 0 && +value <= 100,
  },
  initialSize: {
    type: [Number, String] as PropType<number | `${number}`>,
    default: 60,
    validator: (value: number | string) => +value >= 0 && +value <= 100,
  },
  breakpoint: {
    type: String as PropType<LayoutEnum | null>,
    default: LayoutEnum.md,
    validator: (value: LayoutEnum | null) => value === null || Object.values(LayoutEnum).includes(value),
  },
  breakpointSlot: { type: [Number, String] as PropType<1 | 2 | '1' | '2' | null>, default: null },
},
  setup(__props, { expose: __expose }) {

const { locale } = useI18n();

const props = __props;

const pane1 = ref<HTMLElement | null>(null);
const pane2 = ref<HTMLElement | null>(null);
const isResizing = ref(false);
const layout = ref<LayoutEnum>(LayoutEnum.xl);

const isRtl = computed(() => document.dir === 'rtl' || ['ar', 'he', 'fa', 'ur'].includes(locale.value));

const breakpointArray = computed(() => {
  const values = Object.values(LayoutEnum);

  return props.breakpoint ? values.slice(values.indexOf(LayoutEnum.xs), values.indexOf(props.breakpoint) + 1) : [];
});

const hasBreakpoint = computed(() => {
  if (props.breakpoint === null) {
    return false;
  }

  return breakpointArray.value.includes(layout.value);
});

const isShowSingleSlot = computed(() => props.breakpointSlot && hasBreakpoint.value);

let startPos = 0;
let startPane1Size = 0;

const initializePaneSizes = (initialSize: number): void => {
  if (!pane1.value || !pane2.value) {
    return;
  }

  const size = Math.min(Math.max(initialSize, +props.minSize), +props.maxSize);

  pane1.value.style.flexBasis = `${size}%`;
  pane2.value.style.flexBasis = `${100 - size}%`;
};

const getEventPosition = (event: MouseEvent | TouchEvent): { x: number; y: number } => {
  if (event instanceof MouseEvent) {
    return { x: event.clientX, y: event.clientY };
  } else if (event instanceof TouchEvent) {
    const touch = event.touches[0];
    return { x: touch.clientX, y: touch.clientY };
  }
  return { x: 0, y: 0 };
};

const onMove = (event: MouseEvent | TouchEvent): void => {
  if (!isResizing.value || !pane1.value || !pane2.value) {
    return;
  }

  const { x, y } = getEventPosition(event);
  const delta = !hasBreakpoint.value ? (isRtl.value ? startPos - x : x - startPos) : y - startPos;

  const newPane1Size = startPane1Size + (props.reverse ? -delta : delta);
  const containerSize = !hasBreakpoint.value ? pane1.value.parentElement!.offsetWidth : pane1.value.parentElement!.offsetHeight;

  const newPane1Percentage = (newPane1Size / containerSize) * 100;
  const restrictedSize = Math.min(Math.max(newPane1Percentage, +props.minSize), +props.maxSize);

  pane1.value.style.flexBasis = `${restrictedSize}%`;
  pane2.value.style.flexBasis = `${100 - restrictedSize}%`;
};

const onEnd = (): void => {
  isResizing.value = false;
  window.removeEventListener('mousemove', onMove);
  window.removeEventListener('mouseup', onEnd);
  window.removeEventListener('touchmove', onMove);
  window.removeEventListener('touchend', onEnd);
};

const onStart = (event: MouseEvent | TouchEvent): void => {
  isResizing.value = true;
  const { x, y } = getEventPosition(event);
  startPos = !hasBreakpoint.value ? x : y;
  startPane1Size = !hasBreakpoint.value ? pane1.value!.offsetWidth : pane1.value!.offsetHeight;

  window.addEventListener('mousemove', onMove);
  window.addEventListener('mouseup', onEnd);
  window.addEventListener('touchmove', onMove);
  window.addEventListener('touchend', onEnd);
};

watch(
  () => [pane1, pane2],
  () => {
    const init = Math.min(Math.max(+props.initialSize, +props.minSize), +props.maxSize);

    initializePaneSizes(init);
  },
  { immediate: true, deep: true }
);

onBeforeUnmount(() => {
  window.removeEventListener('mousemove', onMove);
  window.removeEventListener('mouseup', onEnd);
  window.removeEventListener('touchmove', onMove);
  window.removeEventListener('touchend', onEnd);
});

__expose({ layout: computed(() => layout.value), hasBreakpoint });

return (_ctx: any,_cache: any) => {
  const _directive_layout = _resolveDirective("layout")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header"),
    _createElementVNode("div", {
      class: _normalizeClass([
        'split-screen flex-grow-1',
        {
          'split-screen--horizontal': !hasBreakpoint.value,
          'flex-row-reverse': __props.reverse && !hasBreakpoint.value,
          'split-screen--vertical': hasBreakpoint.value,
          'flex-column-reverse': __props.reverse && hasBreakpoint.value,
          'split-screen--resizing': isResizing.value,
        },
      ])
    }, [
      (_ctx.$slots['1'] && _ctx.$slots['2'] && !isShowSingleSlot.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              ref_key: "pane1",
              ref: pane1,
              class: "split-screen__pane"
            }, [
              _renderSlot(_ctx.$slots, "1", { hasBreakpoint: hasBreakpoint.value })
            ], 512),
            _createElementVNode("hr", {
              class: "split-screen__resizer",
              onMousedown: onStart,
              onTouchstart: _withModifiers(onStart, ["prevent"])
            }, null, 32),
            _createElementVNode("div", {
              ref_key: "pane2",
              ref: pane2,
              class: "split-screen__pane"
            }, [
              _renderSlot(_ctx.$slots, "2", { hasBreakpoint: hasBreakpoint.value })
            ], 512)
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (__props.breakpointSlot && _ctx.$slots[__props.breakpointSlot])
              ? _renderSlot(_ctx.$slots, __props.breakpointSlot, {
                  key: 0,
                  hasBreakpoint: hasBreakpoint.value
                })
              : (_ctx.$slots['1'])
                ? _renderSlot(_ctx.$slots, "1", {
                    key: 1,
                    hasBreakpoint: hasBreakpoint.value
                  })
                : (_ctx.$slots['2'])
                  ? _renderSlot(_ctx.$slots, "2", {
                      key: 2,
                      hasBreakpoint: hasBreakpoint.value
                    })
                  : _createCommentVNode("", true)
          ]))
    ], 2)
  ])), [
    [_directive_layout, (value) => (layout.value = value)]
  ])
}
}

})