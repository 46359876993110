<template>
  <template v-if="!multiple">
    <vz-canvas-board-cropper
      :ref="(el: any) => (vzCanvasBoardCropperRefs[0] = el)"
      v-model="vModel"
      :rules="rules"
      :label="label"
      :name="name"
      :hide-details="hideDetails"
      :error-message="errorMessage"
      v-bind="$attrs"
    />
  </template>

  <div v-else :data-errors="errorMessageRef?.errorMessage">
    <p v-if="label" class="mb-2">{{ $t(label) }}</p>

    <div class="row-flex-4">
      <vz-canvas-board-cropper
        v-for="(_, index) in vMultipleModel"
        :key="index"
        :ref="(el: any) => (vzCanvasBoardCropperRefs[index] = el)"
        v-model="vMultipleModel[index]"
        v-bind="$attrs"
        :disabled="disabled"
        @update:model-value="onUpdateMultipleModel(index, $event)"
      />
    </div>

    <vz-error-message ref="errorMessageRef" :value="modelValue" :name="name || label" :rules="rules" :errors="errorMessage" />
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { CanvasBoardCropperRef, ErrorMessageRef } from '@shared/components';
import { computed, type PropType, ref } from 'vue';

const props = defineProps({
  modelValue: { type: [String, Array] as PropType<string | Array<string> | undefined>, required: true },
  multiple: { type: [Boolean, Number], default: false },
  disabled: { type: Boolean, default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
});

const emit = defineEmits(['update:model-value']);
const vzCanvasBoardCropperRefs = ref<Array<CanvasBoardCropperRef>>([]);
const errorMessageRef = ref<ErrorMessageRef>(undefined);

const vModel = computed({
  get: (): string => (Array.isArray(props.modelValue) ? props.modelValue[0] : props.modelValue || ''),
  set: (value: string) => {
    emit('update:model-value', value);
  },
});

const vMultipleModel = computed({
  get: (): Array<string> => {
    const currentValue = [...(Array.isArray(props.modelValue) ? props.modelValue : []).filter((value) => !!value)];

    return typeof props.multiple === 'boolean' || currentValue.length < props.multiple ? [...currentValue, ''] : currentValue;
  },
  set: (value: Array<string>) => {
    emit('update:model-value', value?.filter((value) => !!value));
  },
});

const onUpdateMultipleModel = (index: number, value?: string): void => {
  vMultipleModel.value = vMultipleModel.value.map((item, i) => (i === index ? value || '' : item));
};

const saveAll = (): void => {
  vzCanvasBoardCropperRefs.value.forEach((ref) => ref?.save());
};

defineExpose({ save: saveAll });
</script>
