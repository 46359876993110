import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-center justify-center fill-height fill-width py-4" }
const _hoisted_2 = { class: "mb-6" }

import { computed, type PropType } from 'vue';
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';
import type { ColorsMap } from '@shared/services/css-service/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'empty-state',
  props: {
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  noDataText: { type: String, default: 'DATA.NO_DATA_AVAILABLE' },
  noDataImage: { type: String as PropType<SplashName>, default: 'no-data' },
  color: { type: String as PropType<ColorsMap>, default: 'mono-700' },
  fontSize: { type: Number as PropType<10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36 | 48>, default: 18 },
  fontWeight: { type: Number as PropType<400 | 500 | 600 | 700>, default: 400 },
},
  setup(__props) {

const props = __props;

const splashImage = computed((): SplashName => {
  if (props.errors?.errorMessage?.length) {
    return 'server-error';
  }

  return props.noDataImage;
});

return (_ctx: any,_cache: any) => {
  const _component_vz_svg_href = _resolveComponent("vz-svg-href")!
  const _component_vz_error_alert = _resolveComponent("vz-error-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vz_svg_href, {
      class: "fill-width height-p50 px-16",
      name: splashImage.value
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_2, [
      (__props.errors?.errorMessage?.length)
        ? (_openBlock(), _createBlock(_component_vz_error_alert, {
            key: 0,
            errors: __props.errors
          }, null, 8, ["errors"]))
        : (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass(`mt-1 font-size-${__props.fontSize} font-weight-${__props.fontWeight} c-${__props.color}`)
          }, _toDisplayString(_ctx.$t(__props.noDataText)), 3))
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})