import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, unref as _unref, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-mono-600"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "d-flex gap-1" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }

import type { RouteLocation } from 'vue-router';
import type { BasePhone } from '@shared/models';
import type { IconName } from '@shared/components/icon/icon.type';
import type { SizeUnit } from '@shared/types';
import { computed, type PropType } from 'vue';
import { routeTo, useCurrencySymbol } from '@shared/composables';
import { numberWithCommas } from '@shared/helpers';
import { phonePrettier } from '@shared/helpers/phone-prettier';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-field',
  props: {
  hideEmpty: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  ltr: { type: Boolean as PropType<boolean | undefined>, default: undefined },
  textEllipsis: { type: Boolean, default: false },
  textPre: { type: Boolean, default: false },
  hideTooltip: { type: Boolean, default: false },
  value: {
    type: [String, Array, Object] as PropType<BasePhone | string | number | boolean | Array<string | number | boolean> | null | undefined>,
    default: undefined,
  },
  iconName: { type: String as PropType<IconName>, default: undefined },
  iconSize: { type: String as PropType<SizeUnit>, default: '1.25rem' },
  label: { type: String as PropType<string | undefined>, default: undefined },
  type: { type: String as PropType<'phone' | 'email' | 'currency'>, default: undefined },
  refer: { type: Object as PropType<Partial<Pick<RouteLocation, 'name' | 'path' | 'params' | 'query'>> | undefined>, default: undefined },
},
  setup(__props) {

const props = __props;

const isNumber = computed(() => typeof props.value === 'number');

const link = computed(() => {
  if (!props.value) {
    return null;
  }

  switch (props.type) {
    case 'phone':
      return `tel:${props.value}`;
    case 'email':
      return `mailto:${props.value}`;
    default:
      return null;
  }
});

const valueDisplay = computed((): string => {
  if (props.value === undefined || props.value === null) {
    return '-';
  }

  const valueToString = (value: string | number | boolean | BasePhone) => {
    switch (props.type) {
      case 'currency':
        return useCurrencySymbol(computed(() => value as number)).value;
      case 'phone':
        return phonePrettier(props.value as BasePhone);
    }

    switch (typeof value) {
      case 'boolean':
        return value ? 'GENERAL.YES' : 'GENERAL.NO';
      case 'number':
        return numberWithCommas(value);
      default:
        return value.toString();
    }
  };

  return Array.isArray(props.value) ? props.value.map(valueToString).join(', ') : valueToString(props.value);
});

const ltr = computed((): boolean => {
  if (props.ltr !== undefined) {
    return props.ltr;
  }

  return props.type === 'phone' || props.type === 'email';
});

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_button = _resolveComponent("vz-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (__props.value || _ctx.$slots['default'] || !__props.hideEmpty)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['d-flex gap-1', { 'flex-column': !__props.flat }])
      }, [
        (__props.label || _ctx.$slots['label'])
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "label", {}, () => [
                (__props.label)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (__props.iconName)
            ? (_openBlock(), _createBlock(_component_vz_icon, {
                key: 0,
                name: __props.iconName,
                size: __props.iconSize
              }, null, 8, ["name", "size"]))
            : _createCommentVNode("", true),
          (!__props.value && !_ctx.$slots['default'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
            : (link.value && __props.value)
              ? _withDirectives((_openBlock(), _createElementBlock("a", {
                  key: 2,
                  class: _normalizeClass(['text-body-1 text-auto text-ellipsis clickable-link', { ltr: ltr.value }]),
                  href: link.value,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, [
                  (__props.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(valueDisplay.value), 1))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "default")
                ], 10, _hoisted_5)), [
                  [_directive_tooltip, !__props.hideTooltip]
                ])
              : (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: _normalizeClass(['text-body-1 d-flex gap-1 flex-wrap text-auto fill-width', { 'text-ellipsis': __props.textEllipsis, ltr: ltr.value }])
                }, [
                  (__props.value && !__props.refer)
                    ? _withDirectives((_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass({ 'text-ellipsis': __props.textEllipsis, 'text-pre': __props.textPre, ltr: isNumber.value })
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.$t(valueDisplay.value)), 1)
                      ], 2)), [
                        [_directive_tooltip, __props.textEllipsis && !__props.hideTooltip]
                      ])
                    : (__props.value && __props.refer)
                      ? (_openBlock(), _createBlock(_component_vz_button, {
                          key: 1,
                          class: "text-ellipsis pa-0 font-weight-400",
                          type: "flat",
                          text: valueDisplay.value,
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(routeTo)(__props.refer)), ["stop"]))
                        }, null, 8, ["text"]))
                      : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "default")
                ], 2))
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})