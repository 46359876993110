import type { BaseId, BaseImageResponse, BasePagination, BaseRecords } from '@shared/models';
import type {
  AddReviewReq,
  GetReviewRes,
  GetReviewsReq,
  GetReviewsRes,
  ProfileFullDetails,
  SearchEmployeesReq,
  SearchEmployeesRes,
  UpdateProfileReq,
} from '@/views/employee/types';
import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import {
  ADD_EMPLOYEE_REVIEW,
  EMPLOYEE_DETAILS,
  EMPLOYEE_REVIEWS,
  GET_EMPLOYEE_AVATAR,
  GET_EMPLOYEE_COVER,
  GET_EMPLOYEE,
  GET_EMPLOYEE_DETAILS,
  GET_EMPLOYEE_REVIEWS,
  SEARCH_EMPLOYEES,
  UPDATE_EMPLOYEE_PROFILE,
  EMPLOYEES,
  EMPLOYEES_PAGINATION,
  EMPLOYEES_TOTAL,
  RESET_STATE,
} from '@/views/employee/store/employee.constants';
import EmployeeService from '@/views/employee/service/employee.service';
import { computed, ref } from 'vue';
import { GET_AUTH_SESSION } from '@/store/auth/auth.constants';
import { useAsync } from '@shared/composables';
import { useAuthStore } from '@/store/auth';
import { ProfileTypeEnum } from '@/views/employee/constants';

const employeeService = container.resolve(EmployeeService);

export const useEmployeeStore = defineStore(StoreNamespace.EMPLOYEES_MODULE, () => {
  const { [GET_AUTH_SESSION]: getAuthSession } = useAuthStore();

  const employees = ref<SearchEmployeesRes['data']>([]);
  const employeeDetails = ref<ProfileFullDetails | null>(null);
  const employeeReviews = ref<GetReviewsRes | null>(null);

  const getAvatar = (payload: [string, boolean?]): Promise<BaseImageResponse> => {
    const [hid, noCache] = payload;

    return employeeService[GET_EMPLOYEE_AVATAR](hid, noCache);
  };

  const getCover = (payload: [string, boolean?]): Promise<BaseImageResponse> => {
    const [hid, noCache] = payload;

    return employeeService[GET_EMPLOYEE_COVER](hid, noCache);
  };

  const searchEmployees = async (payload?: SearchEmployeesReq): Promise<SearchEmployeesRes> => {
    const { page, ...filters } = payload || {};
    const res = await employeeService[SEARCH_EMPLOYEES]({ page, ...filters });

    employees.value = !res.page.index ? res.data : [...employees.value, ...res.data];

    return res;
  };

  const getEmployee = async (userId: BaseId): Promise<ProfileFullDetails> => {
    return await employeeService[GET_EMPLOYEE_DETAILS](userId, ProfileTypeEnum.BASE);
  };

  const getEmployeeDetails = async (options?: { userId?: string; ignoreCache?: boolean }): Promise<void> => {
    const id = options?.userId || employeeDetails.value?._id;

    if (!id) {
      employeeDetails.value = null;
      return;
    }

    if (options?.userId && employeeDetails.value?._id !== options?.userId) {
      employeeDetails.value = null;
    }

    employeeDetails.value = await employeeService[GET_EMPLOYEE_DETAILS](id);
  };

  const getEmployeeReviews = async (payload: GetReviewsReq): Promise<BaseRecords<GetReviewRes>> => {
    const res = await employeeService[GET_EMPLOYEE_REVIEWS](payload || { userId: employeeDetails.value!._id });
    employeeReviews.value = !res.page.index ? res : { ...res, data: [...(employeeReviews.value?.data || []), ...res.data] };

    return res;
  };

  const updateEmployeeProfile = async (payload: UpdateProfileReq): Promise<void> => {
    if (!employeeDetails.value?._id) {
      return;
    }

    await employeeService[UPDATE_EMPLOYEE_PROFILE](payload);
    await getEmployeeDetails({ ignoreCache: true });
    await getAuthSession();

    if (payload.avatar !== undefined || payload.cover !== undefined) {
      await getAvatar([employeeDetails.value?._id, true]);
      await getCover([employeeDetails.value?._id, true]);
    }
  };

  const addEmployeeReview = async (payload: AddReviewReq): Promise<void> => {
    const { userId = employeeDetails.value?._id, ...rest } = payload;

    if (!userId) {
      return;
    }

    await employeeService[ADD_EMPLOYEE_REVIEW]({ userId, ...rest });

    if (userId === employeeDetails.value?._id) {
      const { rating } = await employeeService[GET_EMPLOYEE_DETAILS](userId);
      employeeDetails.value = { ...employeeDetails.value, rating };
      await getEmployeeReviews({ userId, page: employeeReviews.value?.page });
    }
  };

  const resetState = (): void => {
    employees.value = [];
    employeeDetails.value = null;
    employeeReviews.value = null;
  };

  const searchEmployeeRequest = useAsync(searchEmployees);

  return {
    // getters
    [EMPLOYEES]: computed(() => searchEmployeeRequest.results.value?.data || employees.value),
    [EMPLOYEES_TOTAL]: computed(() => searchEmployeeRequest.results.value?.total || null),
    [EMPLOYEES_PAGINATION]: computed(() => searchEmployeeRequest.results.value?.page || ({} as BasePagination)),
    [EMPLOYEE_DETAILS]: computed(() => employeeDetails.value),
    [EMPLOYEE_REVIEWS]: computed(() => employeeReviews.value),

    // actions
    [GET_EMPLOYEE_AVATAR]: getAvatar,
    [GET_EMPLOYEE_COVER]: getCover,
    [SEARCH_EMPLOYEES]: searchEmployees,
    [GET_EMPLOYEE]: getEmployee,
    [GET_EMPLOYEE_DETAILS]: getEmployeeDetails,
    [GET_EMPLOYEE_REVIEWS]: getEmployeeReviews,
    [UPDATE_EMPLOYEE_PROFILE]: updateEmployeeProfile,
    [ADD_EMPLOYEE_REVIEW]: addEmployeeReview,

    // mutations
    [RESET_STATE]: resetState,
  };
});
