import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { TableHeader } from '@/shared/components/tables/models';
import type { BasePagination } from '@shared/models';
import type { SearchUserConfigurationRes } from '@/views/backoffice/user-management/store/user-management.types';
import { computed } from 'vue';
import { useAsync } from '@/shared/composables';
import { storeToRefs } from 'pinia';
import { LENGTH } from '@shared/constants';
import { useUserManagementStore } from '@/views/backoffice/user-management/store';
import {
  GET_USER_CONFIGURATION,
  SEARCH_USERS_CONFIGURATION,
  UPDATE_USER_CONFIGURATION,
} from '@/views/backoffice/user-management/store/user-management.constants';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'user-management-table',
  setup(__props) {

const tableHeaders: Array<TableHeader> = [
  { title: 'GENERAL.USER', value: 'user', sortable: ['firstName', 'lastName'], style: { width: '200px' } },
  { title: 'GENERAL.EMAIL', value: 'email', sortable: true, style: { maxWidth: '260px', width: '200px' } },
  { title: 'GENERAL.PHONE', value: 'phone', sortable: true, style: { minWidth: '120px', width: '120px' } },
];

const userManagementStore = useUserManagementStore();

const { data, pagination, filter } = storeToRefs(userManagementStore);
const {
  resetUsersConfiguration,
  [SEARCH_USERS_CONFIGURATION]: searchUsersConfiguration,
  [GET_USER_CONFIGURATION]: getUserConfiguration,
  [UPDATE_USER_CONFIGURATION]: updateUserConfiguration,
} = userManagementStore;

const searchRequest = useAsync<SearchUserConfigurationRes>(
  searchUsersConfiguration as (page?: BasePagination) => Promise<SearchUserConfigurationRes>,
  {
    errorsCleanTimeout: 30 * 1000,
  }
);

const getRequest = useAsync<void>(getUserConfiguration as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
});

const updateRequest = useAsync<void>(updateUserConfiguration as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
  successCallback: searchRequest.call,
});

const currentPage = computed(() => pagination.value?.index || 0);
const totalItems = computed(() => pagination.value?.total || 0);
const items = computed(() => data.value);

const onSearch = (page?: BasePagination): void => {
  searchRequest.call(page);
};

const onClear = (): void => {
  resetUsersConfiguration();
  onSearch();
};

const onPageChange = (index: number): void => {
  onSearch({ index });
};

const onSortChange = (sort: BasePagination['sort']): void => {
  onSearch({ sort });
};

const onSelect = ({ _id }: { _id: string }): void => {
  getRequest.call(_id);
};

return (_ctx: any,_cache: any) => {
  const _component_vz_input = _resolveComponent("vz-input")!
  const _component_vz_data_table = _resolveComponent("vz-data-table")!

  return (_openBlock(), _createBlock(_component_vz_data_table, {
    class: "system-log-table__container",
    "auto-fetch": "",
    headers: tableHeaders,
    items: items.value,
    page: currentPage.value,
    sort: _unref(pagination).sort,
    "total-items": totalItems.value,
    loading: _unref(searchRequest).loading.value,
    errors: _unref(searchRequest).error.value || {},
    onClear: onClear,
    onSearch: onSearch,
    "onSelect:item": onSelect,
    "onUpdate:page": onPageChange,
    "onUpdate:sort": onSortChange
  }, {
    "search-panel": _withCtx(({ errors }) => [
      _createVNode(_component_vz_input, {
        modelValue: _unref(filter).value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(filter).value) = $event)),
        clearable: "",
        label: "GENERAL.SEARCH",
        rules: { maxLength: [_unref(LENGTH).TITLE] },
        "error-message": errors?.value
      }, null, 8, ["modelValue", "rules", "error-message"])
    ]),
    user: _withCtx(({ item }) => [
      _createVNode(EmployeeStrip, {
        class: "pa-0",
        horizontal: "",
        "show-company": "",
        item: item,
        "avatar-size": "48"
      }, null, 8, ["item"])
    ]),
    _: 1
  }, 8, ["items", "page", "sort", "total-items", "loading", "errors"]))
}
}

})