import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';
import jobOfferRoutes from 'src/views/job/views/offer';
import jobManagerRoutes from 'src/views/job/views/manager';
import Job from '@/views/job/job.view.vue';

const jobRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.JOBS],
  name: Modules.JOBS,
  component: Job,
  redirect: { name: Modules.FREELANCE.JOB_OFFER },
  children: [jobOfferRoutes, jobManagerRoutes],
};

export default jobRoutes;
